<template>
<div class="notdatatable form-inline mbottom-15">
   <div class="table-controls table-columns">
      <div class="btn-group"><button class="btn btn-default" type="button"><span>Excel</span></button><button class="btn btn-default button-columns" type="button"><span>Column visibility</span></button></div>
   </div>
   <div class="table-controls table-pagesize table-columns">
      <label>
         Show 
         <select class="form-control input-xs">
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>250</option>
            <option>500</option>
            <option>1000</option>
         </select>
         entries 
      </label>
   </div>
   <div class="table-controls table-filter">Filter Messages <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
</div>
<table class="table table-striped">
   <thead>
      <tr role="row">
        <th>
         <input type="checkbox" class="form-check-input">
        </th>
        <th class="sorting sorting_asc">
            Subject 
        </th>
        <th class="sorting">
            Contact Name
        </th>
        <th class="sorting">
          Contact Email
        </th>
         <th class="sorting">
            Date
        </th>
        <th class="sorting">
            Assigned To
        </th>
       
        <th class="sorting">
            Topic
        </th>
      
      </tr>
   </thead>
    
   <tbody>
     <tr role="row" class="color-normal">
        <td name="name" class="col-icon-check sorting_1">
            <input type="checkbox" class="form-check-input">
            <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded1 = !showexpanded1">
                <i class="fa fa-plus-circle" v-if="!showexpanded1" v-show="!showexpanded1"></i>
                <i class="fa fa-minus-circle" v-if="showexpanded1" v-show="showexpanded1"></i>
            </button>
        </td>
        <td>
             <span>
                [ref#: 71-71] Called with question about TVs
            </span>
        </td>
        <td>
            <a href="">Jacob Smith</a>
        </td>
        <td>
            <span class="fa-stack new-account-icon" v-tooltip="{text: 'Bounced Email',theme: {placement: 'bottom', }}">
              <i class="fa fa-envelope-o"></i>
              <i class="fa  fa-exclamation-triangle  icon-plus"></i>
            </span>
           
            <a href="">Jacob.smith@<br>example.com</a>
            
        </td>
        <td class="column-date">
           <p v-tooltip="{text: '2018-08-03 11:59:44 AM',theme: {placement: 'bottom', }}">5 min ago</p>
        </td>
      
        <td>
            <i class="fa icon-c icon-external-agent external-agent" ></i> <span v-tooltip="{text: 'This interaction is assigned to an external agent',theme: {placement: 'bottom'}}">Justin Time</span>
        </td>
        <td>
           _Feedback
        </td>
        
      </tr>
        <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded1">
            <td class="white-bg">&nbsp;</td>
            <td colspan="6">
                <ViewModeContent v-if="viewmodecontent" showGetThis = "1"></ViewModeContent>

                <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                <GetThisContent v-if="getthiscontent"></GetThisContent> 

                <PaymentContent v-if="paymentcontent"></PaymentContent>
            </td> 
        </tr>
        <tr role="row" class="color-warning">
                <td name="name" class="col-icon-check sorting_1">
                 <input type="checkbox" class="form-check-input">
                    <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded2 = !showexpanded2">
                        <i class="fa fa-plus-circle" v-if="!showexpanded2" v-show="!showexpanded2"></i>
                        <i class="fa fa-minus-circle" v-if="showexpanded2" v-show="showexpanded2"></i>
                    </button>
                </td>
                <td>
             <span>
                [ref#: 71-71] Called with question about TVs
            </span>
        </td>
         <td>
            <a href="">Jacob Smith</a>
        </td>
        <td>
           <a href=""> Jacob.Smith@example.com</a>
        </td>
        <td class="column-date">
           <p v-tooltip="{text: '2018-08-03 11:59:44 AM',theme: {placement: 'bottom', }}">5 min ago</p>
        </td>
      
        <td>
            Jonie Hewlett
        </td>
         
        <td>
           _Feedback
        </td>
        
               
        </tr>
        <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded2">
            <td class="white-bg">&nbsp;</td>
            <td colspan="5">
                <ViewModeContent v-if="viewmodecontent" showGetThis = "1"></ViewModeContent>

                <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                <GetThisContent v-if="getthiscontent"></GetThisContent> 
                <PaymentContent v-if="paymentcontent"></PaymentContent>
            </td> 
        </tr>
        <tr role="row" class="color-urgent">
                <td name="name" class="col-icon-check sorting_1">
                    <input type="checkbox" class="form-check-input">
                    <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded3 = !showexpanded3">
                        <i class="fa fa-plus-circle" v-if="!showexpanded3" v-show="!showexpanded3"></i>
                        <i class="fa fa-minus-circle" v-if="showexpanded3" v-show="showexpanded3"></i>
                    </button>
                </td>
                <td>
                <span>
                    [ref#: 71-71] Called with question about TVs
                </span>
                </td>
                <td>
                    <a href="">James Williams</a>
                </td>
                 <td>
                    <a href="">James.Williams@example.com</a>
                </td>
                <td class="column-date">
                  <p v-tooltip="{text: '2018-08-03 11:59:44 AM',theme: {placement: 'bottom', }}">5 min ago</p>
                </td>
            
                <td>
                   Jonie Hewlett
                </td>
                
                <td>
                _Feedback
                </td>
        
               
        </tr>
        <tr class="expandedrowcontent  bg-agent-reply" v-bind:class="replyType" v-if="showexpanded3">
            <td class="white-bg">&nbsp;</td>
            <td colspan="5">
                <ViewModeContent v-if="viewmodecontent" showGetThis = "1" showAnswer = '1'></ViewModeContent>

                <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                <GetThisContent v-if="getthiscontent"></GetThisContent>
                
                <PaymentContent v-if="paymentcontent"></PaymentContent>

            </td> 
        </tr>
   </tbody>
</table>
<div id="Main_MyDashboard_Recent_TableInfo">
   <div class="notdatatable table-info">Showing 1 to 5 of 29 entries</div>
   <div class="dataTables_paginate notdatatable table-pagination text-end">
      <ul class="pagination">
         <li><a href="">Previous</a></li>
         <li class="active"><a href="">1</a></li>

         <li><a href="">Next</a></li>
      </ul>
   </div>
</div>
</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';

import ViewModeContent from '../components/ViewModeContent.vue';
import CreateNoteContent from '../components/CreateNoteContent.vue';
import AgentEmailContent from '../components/AgentEmailContent.vue';
import CreateTicketContent from '../components/CreateTicketContent.vue';
import ForwardContent from '../components/ForwardContent.vue';
import GetThisContent from '../components/GetThisContent.vue';
import PaymentContent from '../components/PaymentContent.vue';
import CaseProperty from '../components/CaseProperty.vue';
import StatusButton from '../components/StatusButton.vue';


import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Chronological View',
      props: {replyType:String,showGetThis: Boolean, statusBtnText:String, statusBtnClass:String, showstatusBtnIcon:Boolean,statusBtnIconClass:String, typeBtnText:String, typeBtnClass:String, showtypeBtnIcon:Boolean, typeBtnIconClass:String,typeBtnIconClassGroup:String,typeGroupIcon:Boolean},
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent,
        ViewModeContent,
        CreateNoteContent,
        AgentEmailContent,
        CreateTicketContent,
        ForwardContent,
        GetThisContent,
        CaseProperty,
        StatusButton,
        GetThisContent,
        PaymentContent
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            showexpanded1: false,
            viewmodecontent : true,
            createnotecontent : false,
            agentemailcontent : false,
            createticketcontent : false,
            forwardcontent  : false,
            getthiscontent : false,
            paymentcontent : false,
            replyType : this.replyType,
            showGetThis : this.showGetThis,
            statusbtntext:this.statusBtnText, 
            statusbtnclass:this.statusBtnClass, 
            showstatusbtnicon:this.showstatusBtnIcon,
            statusbtniconclass:this.statusBtnIconClass, 
            typebtntext:this.typeBtnText, 
            typebtnclass:this.typeBtnClass,
            showtypebtnicon:this.showtypeBtnIcon, 
            typebtniconclass:this.typeBtnIconClass,
            typebtniconclassgroup: this.typeBtnIconClassGroup,
            typegroupicon : this.typeGroupIcon,
            showexpanded2 : false,
            showexpanded3 : false
          }
      },
      mounted(){
           
           this.emitter.on("show-view-details", Data => {
              this[Data.displaySegment] = true;
              this.viewmodecontent = false;
            });
            this.emitter.on("hide-view-details", Data => {
              this[Data.displaySegment] = false;
              this.viewmodecontent = true;
            });
      },
        methods: {
            toggleSegmentCollapsedState(i) {
            this['segment'+i] = !this['segment'+i] 
            }
        }
    }
</script>