<template>
 <div class="row">
    <div class="col-md-12">    
        <div class="nav-tabs-custom mtop-15">
            <ul class="nav nav-tabs" id="myTab" role="tablist">

                <li class="nav-item" role="presentation">
                    <a class="nav-link active" v-bind:id="'tab2'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_2_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'This interaction has file attachments.',theme: {placement: 'bottom', }}"><i class="fa fa-paperclip"></i> <span>Details & Attachments</span><span class="new-inside"></span></a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'There are property value set for this interaction.',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Article Properties</span><span class="new-inside"></span></a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
            
                <div class="tab-pane show active" v-bind:id="'tab_2_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                  <div class="row">
                    <div class="message-box-header">
                        <div class="col-md-12">
                            <div class="det-box-bg">
                               <div class="row">
                                    <div class="col-md-6"> 
                                            <div><b>Article ID : </b>4645646</div>
                                            <div><b> Topic : </b>All Topic</div>	
                                            <div><b> Date Updated : </b>1/19/2019 11:58:45 PM</div>
                                    </div>
                                    <div class="col-md-6"> 
                                        <div><b> # Views : </b>11</div>
                                        <div><b> Article Creator : </b>Alek Packard</div>									
                                    </div>
                                </div>
                            </div>	
                        </div>	
                        <div class="col-md-12 mtop-15">
                          <b><i class="fa fa-paperclip attachment-icon"></i></b> :
                               <span class="attachment-box">
                                    <a href="" class="attachments">
                                        <i class="fa fa-file-image-o"></i> 
                                        <span class="filename">Image2.jpg (30kb)</span>
                                    </a> 
                                   <button type="button" class="btn attachment-delete-btn"><span class="fa fa-times-circle button-delete"></span></button>
                               </span>
                               <span class="attachment-box">
                                    <a href="" class="attachments">
                                        <i class="fa fa-file-image-o"></i> 
                                        <span class="filename">CTX_Edwards.Software.License.Agreement_Executed (1).pdf</span>
                                    </a> 
                                    <button type="button" class="btn attachment-delete-btn"><span class="fa fa-times-circle button-delete"></span></button>
                               </span>
                               <span class="attachment-box">
                                    <a href="" class="attachments">
                                        <i class="fa fa-file-image-o"></i> 
                                        <span class="filename">CTX_Edwards.Software.License.Agreement_Executed (2).pdf</span>
                                    </a> 
                                    <button type="button" class="btn attachment-delete-btn"><span class="fa fa-times-circle button-delete"></span></button>
                               </span>
                        </div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane fade" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                  <div class="row form-vertical">
                     <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-md-12 control-label text-left">Product Type</label>
                                <div class="col-12">
                                   Electronics
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 control-label text-left">Product Preferences</label>
                                <div class="col-12">
                                   High
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 control-label text-left">Details</label>
                                <div class="col-12">
                                   Powerful 4K upscaling ensures you get up to 4K resolution for the content you love. You'll also experience more lifelike color expressions due to its sophisticated color mapping technology.
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 control-label text-left">Date & Time</label>
                                <div class="col-12">
                                   10-10-22 10:45 AM
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 control-label text-left">Team</label>
                                <div class="col-12">
                                   <div class="row">
                                     <span class="col-md-12">Team 1 </span>
                                    </div>
                                   <div class="row">
                                     <span class="col-md-12">Team 2 </span>
                                    </div>
                                   <div class="row">
                                     <span class="col-md-12">Team 3 </span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-md-12 control-label text-left">Item Features</label>
                                <div class="col-12">
                                   <div class="row"><span class="col-md-3"><strong>Feature 1 -</strong></span> <span class="col-md-9">Feature Description</span></div>
                                   <div class="row"><span class="col-md-3"><strong>Feature 2 - </strong></span><span class="col-md-9">Feature Description</span></div>
                                   <div class="row"><span class="col-md-3"><strong>Feature 3 - </strong></span><span class="col-md-9">Feature Description</span></div>
                                </div>
                            </div>
                     </div>
                  </div>
                </div>
            
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="message-box">
           
            <div class="message-box-body"> 
                <div class="clear"></div>
                    <div>										   
                    <p><b>Question:</b><br>
                        How do i get started with Customer Portal?
                    </p>
                    <p><b>Answer:</b><br>
                        To use Customer Portal, you'll first need to register using Sign Up link. 
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
import EditKbArticle from '../components/EditKbArticle.vue';

import StatusAudit from '../components/StatusAudit.vue';
import FullCaseHistory from '../components/FullCaseHistory.vue';
import Toggle from '@vueform/toggle'
import RelatedArticleTab from '../components/RelatedArticleTab.vue';
import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Expanded Row',
      props: {showGetThis:Boolean,showAnswer:Boolean},
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent,
        StatusAudit,
        FullCaseHistory,
        Toggle,
        EditKbArticle,
        RelatedArticleTab
        
      },
      mounted(){
           if(this.showGetThis == 0){
               this.showget = false;
           }else{
              this.showget = true;
           }

            if(this.showAnswer == 0){
               this.showanswer = false;
           }else{
              this.showanswer = true;
           }
        },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            showexpanded1: false,
            toggleMore : true,
            statusaudit : false,
            fullcasehistory : false,
            palintext: true,
            showget : Boolean(this.showGetThis),
            showanswer : Boolean(this.showGetThis),
            editquestion: false
          }
      },
       methods: {
            show(segment){
              this.emitter.emit("show-view-details",{displaySegment:segment});
            }
            
        }
    }
</script>

