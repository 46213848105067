<template>
<div class="form-action">
  <div class="form-group">
  
    <div class="btn-group dropdown margin-r-5">
        <button type="button" class="btn btn-primary"> <i class="fa fa-send"></i>
        Send</button>										 
        <div class="btn-group">
            <button type="button" class="btn btn-primary dropdown-toggle" v-on:click=" sendBtn = !sendBtn " >
                <span class="caret"></span>
            </button>
            <ul class="dropdown-menu dropdown-menu-btn-option" id="btnSend" v-bind:class = "sendBtn?'show':''" v-if="sendBtn" v-click-outside="closeEvent">
                <li><input type="checkbox" class="form-check-input margin-r-5"><i class="fa icon-c icon-send-leave-open"></i> Leave Open</li>
                <li><input type="checkbox" class="form-check-input margin-r-5"><i class="fa icon-c icon-expect-customer-reply"></i> Expect Customer Reply</li>
                <li><input type="checkbox" class="form-check-input margin-r-5"><i class="fa icon-c icon-add-reference-number"></i> Add Reference Number</li>
                <li>
                    <a href="javascript:void(0)" class="text-primary" v-on:click="shownotificationdropdown = !shownotificationdropdown" ><i class="fa icon-c icon-send-secure"></i> Send Secure (Notification)..</a>
                    <div v-if="shownotificationdropdown">
                        <select class="form-control">
                        <option>Select a notification</option>
                        </select>
                    </div>
                    <div class="alert alert-info alert-dismissible mtop-5">
                        <button type="button" class="close">×</button>
                        <div class="message-container">
                            <div class="icon">
                                <i class="icon fa fa-info-circle"></i>
                            </div>
                            <div class="message"><strong>This Segment does not have a Secure Notification configured.</strong><div>
                                You must create a Secure Notification before using the Send Secure action.
                            </div>
                        </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <button class="btn btn-primary" title="Save Private Note">
        <i class="fa fa-lock"></i>
        Private Note
    </button>
    <button class="btn btn-primary" title="Save Public Note">
        <i class="fa fa-unlock"></i>
        Public Note
    </button>
    <button class="btn btn-primary pull-right" title="Save Draft">
        <i class="fa fa-save"></i>
        Save Draft
    </button>
  </div>
</div>
<div class="nav-tabs-custom">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" v-bind:id="'tab1'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_1_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-fw fa-envelope-o"></i> <span>Email Addresses & Subject for Response</span></a>
        </li>
        <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'There are property values set for this interactions.',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Interaction Properties</span><span class="new-inside"></span></a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane show active" v-bind:id="'tab_1_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
            <!--Standard Response start-->
            <div class="row">
                <div class="col-md-8 form-horizontal">
                    <div class="form-group">
                    <div class="col-2">
                    <label class="control-label text-left">From</label>
                    </div>
                    <div class="col-10"> 
                        <Multiselect
                            v-model="value"
                            :closeOnSelect="false"
                            placeholder="--Select Mailbox--"
                            :options="{
                                customersupport: 'customer support (support@example.com)',
                                sales: 'Sales (sales@example.com)',
                                technical: 'Technical Support (technical@example.com)'
                            }"
                            />
                    </div>
                    </div>
                    <div class="form-group">
                    <label class="col-2 control-label text-left">To</label>
                    <div class="col-10">
                        <select class="form-control select2">
                        <option selected="selected">Jacob.Smith@1to1Service.Com</option>
                        </select>
                    </div>
                    </div>
                
                    <div class="form-group gx-5">
                    <label class="col-2 control-label text-left">CC</label>
                    <div class="col-8 replyall">
                        <span v-tooltip="{text: 'This question has addresses in the CC line. Click to add them to your reply.',theme: {placement: 'bottom', }}">
                        <i class="fa  fa-mail-reply-all  mright-5"></i>
                        </span>
                        <input class="form-control" type="text">
                    </div>
                    <div class="col-2 pos-rel">
                        <a class="btn-link bcc-link cp" v-tooltip="{text: 'Click to add a BCC line to your reply.',theme: {placement: 'bottom', }}" v-if="showBCC" v-on:click="showBCC = !showBCC">Add BCC</a>
                    </div>  
                    </div>
                
                    <div class="form-group" v-if="!showBCC">
                    <label class="col-2 control-label text-left">BCC</label>
                    <div class="col-10">
                        <input class="form-control" type="text">
                    </div>
                    </div>
                    <div class="form-group">
                    <label class="col-2 control-label text-left">Subject</label>
                    <div class="col-4 d-none d-md-block">
                        <input class="form-control " type="text" value="Ref#: 123456789-123456789" disabled>
                    </div>
                    <div class="col-10 col-md-6">
                        <input class="form-control" type="text" value="looking for low cost tv">
                    </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <iservice-files-upload ng-init="uploads = []" files="uploads"></iservice-files-upload>
                    <div class="form-group">
                        <div class="col-md-12">
                            <label class="drop-box">
                            Drop files here or click to upload
                              <input type="file" class="ng-hide" style="display:none;" multiple="">
                            </label>
                            <div>(Maximum 10 MB upload at one time.)</div>
                            <div class="imageuploadify">
                            <!-- ngRepeat: file in files -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <!--Standard Response End-->
        </div>
        <div class="tab-pane fade" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
           <div class="row form-verticle">
               <div class="col-md-12">
                    <div class="form-group">
                        <label class="control-label">
                        <input type="checkbox" class="form-check-input mright-5">
                        Add properties to all answers regardless of topic
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="col-md-12 control-label text-left">Priority</label>
                        <div class="col-12">
                            <select class="form-control">
                            <option value="High">High</option>
                            <option value="Low">Low</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="col-md-12 control-label text-left">Owner</label>
                        <div class="col-12">
                            <select class="form-control">
                            <option value="Owner List">Owner List</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="col-md-12 control-label text-left">isOpen</label>
                        <div class="col-12">
                            <select class="form-control">
                            <option value="True">True</option>
                            <option value="False">False</option>
                            </select>
                        </div>
                    </div>
                </div>
                </div>
        </div>

    </div>
</div>

 <div class="row">
    <div class="col-md-12" > 
        <DraftBodyTabs></DraftBodyTabs>
    </div>
</div>
<div class="row">
    <div class="col-md-12">
        <div class="panel panel-default form-group">
            <div class="panel-heading">
            <i class="fa fa-fw fa-pencil-square-o"></i>
                Private Comments
            </div>
            <div class="panel-body">
                <textarea class="full-width full-height"></textarea>
            </div>
        </div>	
    </div>
</div>



</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
import DraftBodyTabs from '../components/DraftBodyTabs.vue';


import Multiselect from '@vueform/multiselect'

    export default {
      name: 'EmailAddressSubjectResponseComponents',
      props: ['tabOption'],
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent,
        DraftBodyTabs,
      },
      mounted(){
           
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            sendBtn: false,
          }
      }
    }
</script>