<template>
  <Breadcrumb page-title="My Messages To Approve" page-section="My Work Queues"> </Breadcrumb>
  <section class="content">
     <PendingMessageList></PendingMessageList>
     <PendingMessageDetail></PendingMessageDetail>
  </section>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import PendingMessageList from '../components/PendingMessageList.vue';
import PendingMessageDetail from '../components/PendingMessageDetail.vue';
export default {
  name: 'MyMessagesToApproveComponenets',
   components: {
    Breadcrumb,
    PendingMessageList,
    PendingMessageDetail
  }
}
</script>