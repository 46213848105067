<template>
<div class="container pt-3">
 <div class="login-logo text-left">
    <div class="logo-container">
        <a class="logo" href="/">
            <svg>
            <use xlink:href="/img/logo.svg#iservice-logo" />
            </svg>
             <b class="text-logo-gray">Service 	<span class="register-trade-mark">&reg;</span></b>
        </a>
    </div>
</div>
<div class="login-page login-bg mt-5">
   <div class="text-center">
          <img src="/img/usernotfound.png">
          <div class="clearfix"></div>
          <div class="content-header">
            <h1 class="">We’r sorry, but this page is only for iService customer service agents.</h1>
            <h4 class="mtop-15">You are logged in as: customer1@1to1service.com</h4>
            <div class="clearfix"></div>
            <button class="btn btn-primary mtop-15">Log me out</button>
          </div>
   </div>
</div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Google Connect',
  components: {
    
  },
  ready () {
    $(document).ready(function ($) {
    
    })
  }
}
</script>
