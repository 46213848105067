<template>
 <Breadcrumb page-title="New Account" page-section="New Account"> </Breadcrumb>
  <section class="content">
    <NewAccountInformation></NewAccountInformation>
  </section>
</template>

<script>
// @ is an alias to /src
import Breadcrumb from '../components/Breadcrumb.vue';
import NewAccountInformation from '../components/NewAccountInformation.vue';

export default {
  name: 'New Account',
  components: {
    Breadcrumb,
    NewAccountInformation
  },
  ready () {
    $(document).ready(function ($) {
    })
  }
}
</script>
