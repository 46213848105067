<template>
 <Breadcrumb page-title="View Mode" page-section="View Mode"> </Breadcrumb>
  <section class="content">
     <div class="row">
         <div class="col-md-12">
            <div class="box-container">
              <div class="box-body">
                <div class="row">
                    <div class="col-md-12">
                    <table class="table table-striped">
                        <tbody>
                            <ExpandedRowViewMode></ExpandedRowViewMode>
                         </tbody>
                      </table>
                    </div>
                </div>
              </div>
            </div>
         </div>
     </div>
  </section>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import ContactInformation from '../components/ContactInformation.vue';
import ExpandedRowViewMode from '../components/ExpandedRowViewMode.vue';
export default {
  name: 'ViewMode',
  components: {
    Breadcrumb,
    ContactInformation,
    ExpandedRowViewMode
  },
 data() {
      return{
          row1 : false
      }
  },
  methods: {
    toggleRow(i) {
        this['row'+i] = !this['row'+i] 
    }
  }
  
}
</script>
