<template>
  <Breadcrumb page-title="My Draft Messages " page-section="My Work Queues"> </Breadcrumb>
  <section class="content">
     <DraftMessageList></DraftMessageList>
     <DraftMessageDetail></DraftMessageDetail>
  </section>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import DraftMessageList from '../components/DraftMessageList.vue';
import DraftMessageDetail from '../components/DraftMessageDetail.vue';
export default {
  name: 'MyDraftMessagesComponenets',
   components: {
    Breadcrumb,
    DraftMessageList,
    DraftMessageDetail
  }
}
</script>