<template>
 <Breadcrumb page-title="Manage KB Article" page-section="Manage Work Queue"> </Breadcrumb>
  <section class="content">
   
    <div class="row">
        <div class="col-md-4" v-bind:class = "menuCollapse ?'treeview-expand':'treeview-collapse'">
          <div class="box box-primary">
            <div class="box-header with-border heading-with-toggle" >
                <i class="fa fa-list-ul"></i>
                <h3 class="box-title text-right">Topic List</h3>
                <span aria-hidden="true" class="pull-right  mleft-15 menu-collapse" v-on:click="menuCollapse = !menuCollapse;"><i class="fa fa-reorder treeview-collapse-icon"></i></span>
                <span v-on:click="treeView()" aria-hidden="true" class="menu-collapse glyphicon  pull-right glyphicon-chevron-right mleft-15 treeview-expand-icon" ng-class="{'glyphicon-chevron-right__expanded': !isCollapsedTreeView}" ng-init="isCollapsedTreeView = false;"></span>
            </div>
            <div class="box-body search-criteria topic-list-container">
            
                <div class="row">
                    <div class="col-md-12 message-head">
                        <label class="pull-left select-all-topic">
                        <input type="checkbox" class="form-check-input mright-5">Select All</label> 
                        
                        <div class="pull-right count-block" v-tooltip="{text: 'Number of Articles',theme: {placement: 'bottom', }}">
                            #
                        </div>
                    </div> 
                </div>
                <div class="treeview topic-list  active-chat" v-if="!showmytopic">
                    <div class="treeview-segment-name">   
                        E-shop
                    </div>		
                  <ul id="tree1" class="topictree tree">
											<li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i>
												
												<input type="checkbox" class="form-check-input mright-5">
												<div class="treeviewitem">
													<div class="treeviewrow">
														<div class="div1"> All Topic</div> 
													</div>	
												</div>
												<div class="count-columns">
													<div class="pull-right count-block text-aqua">1</div>
												</div>
												
												<ul>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">  _Feedback</div> <div class="div2">&nbsp;</div></div></div> <div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
													<li><input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Newsletter Subscription</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Spam</div><div class="div2">&nbsp;</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"> <div class="treeviewrow"><div class="div1 text-red">_Undeliverable</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
													<li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Account Questions</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div>
														<ul>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Orders </div> </div> </div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Refunds </div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Returns </div> </div> </div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
														</ul>
													</li>
													<li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Products</div><div class="div2">&nbsp;</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div>
														<ul>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Audio-Video</div></div> </div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Cameras </div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Cell Phones</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Computers</div> </div> </div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Televisions</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
														</ul>
													</li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Shipping</div></div></div> <div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Technical Support</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
												
												</ul>
											</li>
										</ul>
                </div>

                 <div class="treeview topic-list  active-chat" v-if="showmytopic">
                    <div class="treeview-segment-name">   
                       Financial Services
                    </div>		
                  <ul id="tree1" class="topictree tree">
                    <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i>
                        
                        <input type="checkbox" class="form-check-input mright-5">
                        <div class="treeviewitem">
                            <div class="treeviewrow">
                                <div class="div1"> All Topic</div> 
                            </div>	
                        </div>
                        <div class="count-columns">
                            <div class="pull-right count-block text-aqua">1</div>
                        </div>
                        
                        <ul>
                            <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">  _Feedback</div> <div class="div2">&nbsp;</div></div></div> <div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
                            <li><input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Newsletter Subscription</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
                            <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Spam</div><div class="div2">&nbsp;</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
                            <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"> <div class="treeviewrow"><div class="div1 text-red">_Undeliverable</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
                            <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Account Questions</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div>
                               
                            </li>
                          
                        </ul>
                    </li>
                </ul>
                </div>
            </div>
          </div>
        </div>
        <div class="p-l-0"  v-bind:class= "menuCollapse ?'col-md-8':'col-md-11'">
            <div class="row mbottom-15">
                <div class="col-md-8" v-show="!showadvancedsearch">
                    <div class="input-group">
                        <input type="text" class="form-control ">
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-primary btn-flat" v-tooltip="{text: 'Search Selected Topic',theme: {placement: 'bottom', }}"><i class="fa fa-search"></i></button>
                        </span>
                    </div>
                </div>
                <span class="col-md-4 pt-2 text-primary" v-show="!showadvancedsearch" v-on:click="showadvancedsearch = !showadvancedsearch ">Advanced Search</span>
            </div>
            <div class="row" v-show="showadvancedsearch">
                <div class="col-md-12">
                    <div class="box box-info">
                        <div class="box-header with-border">
                            <i class="fa fa-filter"></i>
                            <h3 class="box-title">Advanced Search</h3>
                        </div>
                        <div class="box-body">
                            <div class="row">
                               <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label control-label text-left">Search For</label>
                                    <div class=""><input type="text" class="form-control"></div>
                                </div>
                               </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label control-label text-left">Select Topic</label>
                                    <div class="">
                                       <Multiselect
                                    v-model="value"
                                    mode="tags"
                                    placeholder="Select topic"
                                    label="name"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: 'Customer Success',
                                        options:  [
                                            { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                            { value: '_Feedback', name: '_Feedback',level:'2'},
                                            { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                            { value: '_Spam', name: '_Spam',level:'2'},
                                            { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                            { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Cancel', name: 'Cancel',level:'2'},
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                                    </div>
                                </div>
                               </div>
                              
                               <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label control-label text-left">Select Access Level</label>
                                    <div class="">
                                      <select class="form-control">
                                         <option>Select Acces Type</option>
                                         <option>Public</option>
                                         <option>Private</option>
                                      </select>
                                    </div>
                                </div>
                               </div>
                               <div class="col-md-6">
                                <div class="form-group">
                                    <label class="control-label control-label text-left">Select Ratings</label>
                                    <div class="">
                                      <select class="form-control">
                                         <option>Select Rating</option>
                                         <option>Rating 4 & Up</option>
                                         <option>Rating 3 & Up</option>
                                         <option>Rating 2 & Up</option>
                                         <option>Rating 1 & Up</option>
                                      </select>
                                    </div>
                                </div>
                               </div>
                            
                               <div class="col-md-12"> 
                                 <button type="submit" class="btn btn-primary">Search</button>
                                 <button type="submit" class="btn btn-primary mleft-5">Reset</button>
                                 <button type="submit" class="btn btn-default mleft-5"  v-on:click="showadvancedsearch = false;">Cancel</button>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
           <div class="row">
           
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <i class="fa fa-list"></i>
                        <h3 class="box-title">Article List</h3>
                    </div>
                    <div class="box-body">
                        <ManageKbArticleTableList></ManageKbArticleTableList>
                    </div>
                </div>
            </div>
          </div>
        </div>			
    </div>
  </section>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
// @ is an alias to /src
import Breadcrumb from '../components/Breadcrumb.vue';
import ChatButtons from '../components/ChatButtons.vue';
import MassUpdateBody from '../components/MassUpdateBody.vue';
import ManageKbArticleTableList from '../components/ManageKbArticleTableList.vue';
import Toggle from '@vueform/toggle'
import Multiselect from '@vueform/multiselect'
export default {
  name: 'Manage KB Articles',
  data() {
      return{
          showmore : false,
          segment1 : true,
          menuCollapse : true,
          showmytopic : false,
          showadvancedsearch : false
      }
  },
  components: {
    Breadcrumb,
    ChatButtons,
    MassUpdateBody,
    ManageKbArticleTableList,
    Multiselect
  },
  ready () {
    $(document).ready(function ($) {
    })
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
    
  }
}
</script>
