<template>
 <Breadcrumb page-title="New User Authorization Form" page-section="Custom Forms"> </Breadcrumb>
  <section class="content">
    <div class="row">
      <div class="col-md-12">
         <div class="box">
            
            <div class="box-body">
                <div class="box box-info">
                   <div class="box-header with-border">
                        <h3 class="box-title head-title-padding">New User Information</h3>
                    </div>
                    <div class="box-body">
                        <div class="row form-horizontal">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">First Name</label>
                                    <div class="col-6">
                                    <input class="form-control">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Last Name</label>
                                    <div class="col-6">
                                    <input class="form-control">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Job Title</label>
                                    <div class="col-6">
                                    <input class="form-control">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Role</label>
                                    <div class="col-6">
                                    <input class="form-control">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Full Address</label>
                                    <div class="col-6">
                                      <textarea class="form-control"> </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div class="box box-info">
                   <div class="box-header with-border">
                        <h3 class="box-title head-title-padding">Access Requested</h3>
                    </div>
                    <div class="box-body">
                        <div class="row form-horizontal">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Select a role + extra privileges</label>
                                    <div class="col-6">
                                      <select class="form-control">
                                        <option>Developer</option>
                                        <option>QA</option>
                                      </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Dropbox folders</label>
                                    <div class="col-6">
                                        <select class="form-control">
                                            <option></option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">VPN </label>
                                    <div class="col-6">
                                      <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">GitHub </label>
                                    <div class="col-6">
                                        <select class="form-control">
                                            <option>--Select Repository List--</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Perforce </label>
                                    <div class="col-6">
                                       <select class="form-control">
                                            <option>--Select Role--</option>
                                        </select>
                                    </div>
                                </div>

                                
                                 <div class="form-group">
                                    <label class="col-2 control-label text-left">1to1 Domain Admin</label>
                                    <div class="col-6">
                                      <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                                 <div class="form-group">
                                    <label class="col-2 control-label text-left">PROD Windows Servers </label>
                                    <div class="col-6">
                                       <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                                 <div class="form-group">
                                    <label class="col-2 control-label text-left">SonicWall</label>
                                    <div class="col-6">
                                      <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                                 <div class="form-group">
                                    <label class="col-2 control-label text-left">AWS </label>
                                    <div class="col-6">
                                       <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-2 control-label text-left">KeePass Network </label>
                                    <div class="col-6">
                                       <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-2 control-label text-left">iService - eShop</label>
                                    <div class="col-6">
                                       <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-2 control-label text-left">iService - 1to1service</label>
                                    <div class="col-6">
                                       <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Wordpress- iservice.us</label>
                                    <div class="col-6">
                                       <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Wordpress - itcomplianceexperts.com </label>
                                    <div class="col-6">
                                       <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Wordpress - 1to1service.com</label>
                                    <div class="col-6">
                                       <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box box-info">
                   <div class="box-header with-border">
                        <h3 class="box-title head-title-padding">Onboarding Tasks Request</h3>
                    </div>
                    <div class="box-body">
                        <div class="row form-horizontal">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Complete a background check</label>
                                    <div class="col-6">
                                      <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Complete system training(include role in ticket) </label>
                                    <div class="col-6">
                                      <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Complete Security Awareness training (everyone must do this)</label>
                                    <div class="col-6">
                                       <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Provide Telephone Number</label>
                                    <div class="col-6">
                                       <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-2 control-label text-left">Provide Email Address</label>
                                    <div class="col-6">
                                      <input type="checkbox" class="form-check-input">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button class="btn btn-primary" type="button"> Submit Ticket </button>
            </div>
         </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Breadcrumb from '../components/Breadcrumb.vue';
import ContactInformation from '../components/ContactInformation.vue';

export default {
  name: 'Contact',
  components: {
    Breadcrumb,
    ContactInformation
  },
  ready () {
    $(document).ready(function ($) {
    })
  }
}
</script>
