<template>
    <Breadcrumb page-title="Configure Chat Panel" page-section="Customize Portals"> </Breadcrumb>
    <section class="content">
        <ChatPanelSettings></ChatPanelSettings>
    </section>
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
 // @ is an alias to /src
 import Breadcrumb from '../components/Breadcrumb.vue';
 import NewAccountInformation from '../components/NewAccountInformation.vue';
 import CustomerPortalSettings from '../components/CustomerPortalSettings.vue';
 import CustomerPortalSettingsTv from '../components/CustomerPortalSettingsTv.vue';
 import SegmentLevelSettings from '../components/SegmentLevelSettings.vue';
 import MessagePortalSettings from '../components/MessagePortalSettings.vue';
 import ChatPanelSettings from '../components/ChatPanelSettings.vue';
 

 
 import DashbordConfig from '../components/DashbordConfig.vue';
 import ColorCodesConfig from '../components/ColorCodesConfig.vue';
 
 import Toggle from '@vueform/toggle'
 export default {
   name: 'New Account',
   components: {
     Breadcrumb,
     NewAccountInformation,
     Toggle,
     CustomerPortalSettings,
     CustomerPortalSettingsTv,
     DashbordConfig,
     ColorCodesConfig,
     SegmentLevelSettings,
     MessagePortalSettings,
     ChatPanelSettings
   },
   data: function(){
       return{
         segment1 : true,
         segment2 : false,
         segment3 : false,
         segment4 : false,
         segment7: true,
         segment8:true,
         segment10:true,
         segment11:true,
         choosedWebsite : false
       }
          
   },
   ready () {
     $(document).ready(function ($) {
     })
   },
    methods: {
     toggleSegmentCollapsedState(i) {
       this['segment'+i] = !this['segment'+i] 
     }
   }
 }
 </script>
 