<template>
 <Breadcrumb page-title="Manage KB Article" page-section="Manage Work Queue"> </Breadcrumb>
  <section class="content">
    <div class="row"> 
        <div class="col-md-12">
            <div class="btn-group pull-right mbottom-15" id="realtime" data-toggle="btn-toggle">
                <a class="btn btn-primary active"  href="/pages/manage-kb-article-listview.html">List View</a>
                <a class="btn btn-default"  href="/pages/manage-kb-article.html">Tree View</a>
            </div>
        </div>
    </div>
    <div class="row">
     
        <div class="p-l-0 col-md-12">
            <div class="row mbottom-15">
                <div class="col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control ">
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-primary btn-flat">Search Selected Topics</button>
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="pull-right text-end">
                      
                        <a class="btn btn-primary" href="/pages/new-kb-article.html">
                            Add Article
                        </a>
                    </div>	
                </div>
            </div>
            <div class="row mbottom-15">
                    <label for="inputPassword3" class="col-2 control-label text-left">Topic Selection</label>
                    <div class="col-4">
                       <Multiselect
                                    v-model="value"
                                    mode="tags"
                                    placeholder="Select topic"
                                    label="name"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: 'Customer Success',
                                        options:  [
                                            { value: 'eshop all topics', name: 'e shop -- All Topics',level:'1'},
                                            { value: '_Feedback', name: '_Feedback',level:'2'},
                                            { value: '_Newsletter Subscription', name: '_Newsletter Subscription',level:'2'},
                                            { value: '_Spam', name: '_Spam',level:'2'},
                                            { value: 'Undeliverd', name: 'Undeliverd',level:'2'},
                                            { value: 'Account Questions', name: 'Account Questions',level:'1'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Return', name: 'Return',level:'2'},
                                            { value: 'Cancel', name: 'Cancel',level:'2'},
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                    </div>
            </div>
           <div class="row">
           
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <i class="fa fa-list"></i>
                        <h3 class="box-title">Article List</h3>
                    </div>
                    <div class="box-body">
                        <ManageKbArticleTableList></ManageKbArticleTableList>
                    </div>
                </div>
            </div>
          </div>
        </div>			
    </div>
  </section>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
// @ is an alias to /src
import Breadcrumb from '../components/Breadcrumb.vue';
import ChatButtons from '../components/ChatButtons.vue';
import MassUpdateBody from '../components/MassUpdateBody.vue';
import ManageKbArticleTableList from '../components/ManageKbArticleTableList.vue';
import Multiselect from '@vueform/multiselect'

import Toggle from '@vueform/toggle'
export default {
  name: 'Manage KB Articles',
  data() {
      return{
          showmore : false,
          segment1 : true,
          menuCollapse : true,
          showmytopic : false
      }
  },
  components: {
    Breadcrumb,
    ChatButtons,
    MassUpdateBody,
    ManageKbArticleTableList,
    Toggle,
    Multiselect
  },
  ready () {
    $(document).ready(function ($) {
    })
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  }
}
</script>
