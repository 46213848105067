<template>
 <div class="row">
    <div class="col-md-6">
        <div class="form-group">
            <label class="control-label text-left">Alert Name</label>
            <input iservice-id="Name" type="text" class="form-control has-error">
        </div>
    </div>
    <div class="col-md-4">
        <div class="form-group">
            <label class="control-label text-left">Alert Status</label>
            <iServiceSwitch></iServiceSwitch>
        </div>
    </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="box box-primary">
                <div class="box-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="control-label text-left">Frequency (How often this should run)</label>
                                <div class="d-flex">
                                    <input iservice-id="Name" type="text" class="form-control mright-15 has-error">
                                    <select class="form-control">
                                        <option>Minutes</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class=" control-label text-left">Question Age (How old before questions are included?)</label>
                                <div class="d-flex">
                                    <input iservice-id="Name" type="text" class="form-control mright-15 has-error">
                                    <select class="form-control">
                                        <option>Minutes</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class=" control-label text-left">Topics to monitor (Select that all apply)</label>
                                <div class="">
                                    <div class="treeview topic-list">
                                       <div class="treeview topic-list">
                    
                                            <ul id="tree1" class="topictree tree">
                                                <li class="branch">
                                                    <i class="indicator fa fa-minus-circle mright-5"></i>
                                                    <input type="checkbox" class="form-check-input mright-5">
                                                    <div class="treeviewitem">
                                                        <div class="treeviewrow">
                                                            <div class="div1"> All Topic</div>
                                                        </div>
                                                    </div>
                                                
                                                    <ul>
                                                        <li>
                                                            <input type="checkbox" class="form-check-input mright-5">
                                                            <div class="treeviewitem">
                                                            <div class="treeviewrow">
                                                                <div class="div1">  _Feedback</div>
                                                                <div class="div2">&nbsp;</div>
                                                            </div>
                                                            </div>
                                                        
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" class="form-check-input mright-5">
                                                            <div class="treeviewitem">
                                                            <div class="treeviewrow">
                                                                <div class="div1">_Newsletter Subscription</div>
                                                            </div>
                                                            </div>
                                                        
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" class="form-check-input mright-5">
                                                            <div class="treeviewitem">
                                                            <div class="treeviewrow">
                                                                <div class="div1">_Spam</div>
                                                                <div class="div2">&nbsp;</div>
                                                            </div>
                                                            </div>
                                                        
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" class="form-check-input mright-5">
                                                            <div class="treeviewitem">
                                                            <div class="treeviewrow">
                                                                <div class="div1">_Undeliverable</div>
                                                            </div>
                                                            </div>
                                                        
                                                        </li>
                                                        <li class="branch">
                                                            <i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5">
                                                            <div class="treeviewitem">
                                                            <div class="treeviewrow">
                                                                <div class="div1">Account Questions</div>
                                                            </div>
                                                            </div>
                                                        
                                                            <ul>
                                                            <li>
                                                                <input type="checkbox" class="form-check-input mright-5">
                                                                <div class="treeviewitem">
                                                                    <div class="treeviewrow">
                                                                        <div class="div1">Orders </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </li>
                                                            <li>
                                                                <input type="checkbox" class="form-check-input mright-5">
                                                                <div class="treeviewitem">
                                                                    <div class="treeviewrow">
                                                                        <div class="div1">Refunds </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </li>
                                                            <li>
                                                                <input type="checkbox" class="form-check-input mright-5">
                                                                <div class="treeviewitem">
                                                                    <div class="treeviewrow">
                                                                        <div class="div1">Returns </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </li>
                                                            </ul>
                                                        </li>
                                                        <li class="branch">
                                                            <i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5">
                                                            <div class="treeviewitem">
                                                            <div class="treeviewrow">
                                                                <div class="div1">Products</div>
                                                                <div class="div2">&nbsp;</div>
                                                            </div>
                                                            </div>
                                                        
                                                            <ul>
                                                            <li>
                                                                <input type="checkbox" class="form-check-input mright-5">
                                                                <div class="treeviewitem">
                                                                    <div class="treeviewrow">
                                                                        <div class="div1">Audio-Video</div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </li>
                                                            <li>
                                                                <input type="checkbox" class="form-check-input mright-5">
                                                                <div class="treeviewitem">
                                                                    <div class="treeviewrow">
                                                                        <div class="div1">Cameras </div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </li>
                                                            <li>
                                                                <input type="checkbox" class="form-check-input mright-5">
                                                                <div class="treeviewitem">
                                                                    <div class="treeviewrow">
                                                                        <div class="div1">Cell Phones</div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </li>
                                                            <li>
                                                                <input type="checkbox" class="form-check-input mright-5">
                                                                <div class="treeviewitem">
                                                                    <div class="treeviewrow">
                                                                        <div class="div1">Computers</div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </li>
                                                            <li>
                                                                <input type="checkbox" class="form-check-input mright-5">
                                                                <div class="treeviewitem">
                                                                    <div class="treeviewrow">
                                                                        <div class="div1">Televisions</div>
                                                                    </div>
                                                                </div>
                                                            
                                                            </li>
                                                            </ul>
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" class="form-check-input mright-5">
                                                            <div class="treeviewitem">
                                                            <div class="treeviewrow">
                                                                <div class="div1">Shipping</div>
                                                            </div>
                                                            </div>
                                                        
                                                        </li>
                                                        <li>
                                                            <input type="checkbox" class="form-check-input mright-5">
                                                            <div class="treeviewitem">
                                                            <div class="treeviewrow">
                                                                <div class="div1">Technical Support</div>
                                                            </div>
                                                            </div>
                                                        
                                                        </li>
                                                    </ul>
                                                </li>
                                                </ul>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="box box-primary">
                <div class="box-header with-border"><h3 class="box-title ng-binding">Actions</h3></div>
                <div class="box-body">
                <section class="container-fluid search-parameters">
                    <fieldset class="row action">
                        <div class="panel panel-default action-panel col-12 bg-gray-light">
                        <div>
                        
                            <div class="row formstep">
                                <div class="col-md-10 box-bg-white ptop-15 pbottom-15 white-container">
                                    <div class="row">
                                        <label class="col-sm-12 control-label text-left">Pick a step type</label>
                                        <div class="col-sm-12">
                                            <Multiselect
                                                v-model="value"
                                                placeholder="Select or Search for a Type"
                                                label="name"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: 'Form actions',
                                                    options:  [
                                                        { value: 'Admin forms', name: 'Admin Forms',level:'2'},
                                                        { value: 'Admin stock responses', name: 'Admin Stock Responses',level:'2'},
                                                        { value: 'Admin User Type', name: 'Admin User Type',level:'2'},
                                                        
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template  v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                                            </Multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-container">
                                    <button type="button" class="btn btn-link btn-lg btn-sort" v-tooltip="{text: 'Up',theme: {placement: 'bottom', }}">
                                        <i class="fa fa-chevron-circle-up text-primary"></i>
                                    </button>
                                    <button type="button" class="btn btn-link btn-lg btn-sort" v-tooltip="{text: 'Down',theme: {placement: 'bottom', }}">
                                    <i class="fa fa-chevron-circle-down text-primary"></i>
                                    </button>
                                    <button type="button" class="btn btn-link btn-lg btn-sort" v-tooltip="{text: 'remove',theme: {placement: 'bottom', }}">
                                        <i class="fa fa-times-circle text-red"></i>
                                    </button>
                                </div>
                            </div>
                                <div class="row formstep">
                                <div class="col-md-10 box-bg-white ptop-15 pbottom-15 white-container">
                                    <div class="row">
                                        <label class="col-sm-12 control-label text-left">Pick a step type</label>
                                        <div class="col-sm-12">
                                                <Multiselect
                                                v-model="value"
                                                placeholder="Select or Search for a Type"
                                                label="name"
                                                :groups="true"
                                                :searchable="true"
                                                :options="[
                                                    {
                                                    label: 'Form actions',
                                                    options:  [
                                                        { value: 'Admin forms', name: 'Admin Forms',level:'2'},
                                                        { value: 'Admin stock responses', name: 'Admin Stock Responses',level:'2'},
                                                        { value: 'Admin User Type', name: 'Admin User Type',level:'2'},
                                                        
                                                    ],
                                                    }
                                                ]"
                                                >
                                                <template  v-slot:option="{ option }">
                                                <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                                </template>
                    </Multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-container">
                                    <button type="button" class="btn btn-link btn-lg btn-sort" v-tooltip="{text: 'up',theme: {placement: 'bottom', }}">
                                        <i class="fa fa-chevron-circle-up text-primary"></i>
                                    </button>
                                    <button type="button" class="btn btn-link btn-lg btn-sort" v-tooltip="{text: 'down',theme: {placement: 'bottom', }}">
                                    <i class="fa fa-chevron-circle-down text-primary"></i>
                                    </button>
                                    <button type="button" class="btn btn-link btn-lg btn-sort" v-tooltip="{text: 'remove',theme: {placement: 'bottom', }}">
                                        <i class="fa fa-times-circle text-red"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <button class="btn btn-lg btn-link pull-left" type="button">
                                    <i class="fa fa-plus-circle text-light-blue"></i> Add Steps 
                                </button>
                            </div>
                        </div>
                        </div>
                    </fieldset>
                </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect'
    import iServiceSwitch from '../components/iServiceSwitch.vue';

    export default {
      name: 'Alert Form',
      props: ['formTitle'],
       components: {
         Multiselect,
         iServiceSwitch
      },
      data: function () {
        return {
         
        }
      },
      events: {
       
      },
      methods: {
      
      }

    }
</script>