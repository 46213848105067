<template>
 <Breadcrumb page-title="Mail Popper" page-section="Mail Popper"> </Breadcrumb>
  <section class="content">
     <div class="row">
              <div class="col-md-12">
                <div class="box box-info" id="inbox-list">
                  <div class="box-header with-border">
                    <h3 class="box-title head-title-padding">iService Mailpopper Monitor - qadesign</h3>
                  </div>
                  <!-- /.box-header -->
                  <!-- form start -->
                  
                  <div class="box-body table-responsive">
                    
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr id="row1">
                         
                          <th class="text-capitalize">
                            Thred Name
                          </th>
                          <th class="text-capitalize">
                            Status
                          </th>
                          <th class="text-capitalize">
                            History
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr id="row2">
                        
                          <td>
                            qa80.qadesign:Processor
                          </td>
                          <td>
                            Process new messages in 15 secs.
                          </td>
                          <td>
                            Processed:0, success, 0 failed
                          </td>
                        </tr>

                        <tr>
                          <td>
                          qa80.qadesign:smtpout
                          </td>
                          <td>
                           
                           Send outgoing messages 0 secs.  
                          </td>
                          <td>
                            Sent:2 success, 6 failed
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:Mass Mailing
                          </td>
                          <td>
                           Process mass mailing in 12 secs.  
                          </td>
                          <td>
                            Generated:0 success ,0 failed
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:Campaign
                          </td>
                          <td>
                           Process Campaign in 3 min, 42 secs.  
                          </td>
                          <td>
                           Generated:0 success ,0 failed
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:Alerts
                          </td>
                          <td>
                           Process alerts in 48 secs.  
                          </td>
                          <td>
                          Alerts Sent:0
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:All Mailboxes
                          </td>
                          <td>
                           Pop all mailboxes in 21 secs.  
                          </td>
                          <td>
                            0 message retrived
                          </td>
                         
                        </tr>
                      </tbody>
                    </table>
                   
                  </div>
                </div>
              </div>
              <div class="col-md-4">
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="box box-info" id="inbox-list">
                  <div class="box-header with-border">
                    <h3 class="box-title head-title-padding">iService Mailpopper Monitor - qa80clean</h3>
                  </div>
                  <!-- /.box-header -->
                  <!-- form start -->
                  
                  <div class="box-body table-responsive">
                    
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr id="row1">
                         
                          <th class="text-capitalize">
                            Thred Name
                          </th>
                          <th class="text-capitalize">
                            Status
                          </th>
                          <th class="text-capitalize">
                            History
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr id="row2">
                        
                          <td>
                            qa80.qadesign:Processor
                          </td>
                          <td>
                            Process new messages in 15 secs.
                          </td>
                          <td>
                            Processed:0, success, 0 failed
                          </td>
                        </tr>

                        <tr>
                          <td>
                          qa80.qadesign:smtpout
                          </td>
                          <td>
                           
                           Send outgoing messages 0 secs.  
                          </td>
                          <td>
                            Sent:2 success, 6 failed
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:Mass Mailing
                          </td>
                          <td>
                           Process mass mailing in 12 secs.  
                          </td>
                          <td>
                            Generated:0 success ,0 failed
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:Campaign
                          </td>
                          <td>
                           Process Campaign in 3 min, 42 secs.  
                          </td>
                          <td>
                           Generated:0 success ,0 failed
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:Alerts
                          </td>
                          <td>
                           Process alerts in 48 secs.  
                          </td>
                          <td>
                          Alerts Sent:0
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:All Mailboxes
                          </td>
                          <td>
                           Pop all mailboxes in 21 secs.  
                          </td>
                          <td>
                            0 message retrived
                          </td>
                         
                        </tr>
                      </tbody>
                    </table>
                   
                  </div>
                </div>
              </div>
              <div class="col-md-4">
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="box box-info" id="inbox-list">
                  <div class="box-header with-border">
                    <h3 class="box-title head-title-padding">iService Mailpopper Monitor - qa80test</h3>
                  </div>
                  <!-- /.box-header -->
                  <!-- form start -->
                  
                  <div class="box-body table-responsive">
                    
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr id="row1">
                         
                          <th class="text-capitalize">
                            Thred Name
                          </th>
                          <th class="text-capitalize">
                            Status
                          </th>
                          <th class="text-capitalize">
                            History
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr id="row2">
                        
                          <td>
                            qa80.qadesign:Processor
                          </td>
                          <td>
                            Process new messages in 15 secs.
                          </td>
                          <td>
                            Processed:0, success, 0 failed
                          </td>
                        </tr>

                        <tr>
                          <td>
                          qa80.qadesign:smtpout
                          </td>
                          <td>
                           
                           Send outgoing messages 0 secs.  
                          </td>
                          <td>
                            Sent:2 success, 6 failed
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:Mass Mailing
                          </td>
                          <td>
                           Process mass mailing in 12 secs.  
                          </td>
                          <td>
                            Generated:0 success ,0 failed
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:Campaign
                          </td>
                          <td>
                           Process Campaign in 3 min, 42 secs.  
                          </td>
                          <td>
                           Generated:0 success ,0 failed
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:Alerts
                          </td>
                          <td>
                           Process alerts in 48 secs.  
                          </td>
                          <td>
                          Alerts Sent:0
                          </td>
                         
                        </tr>
                        <tr>
                          <td>
                          qa80.qadesign:All Mailboxes
                          </td>
                          <td>
                           Pop all mailboxes in 21 secs.  
                          </td>
                          <td>
                            0 message retrived
                          </td>
                         
                        </tr>
                      </tbody>
                    </table>
                   
                  </div>
                </div>
              </div>
              <div class="col-md-4">
              </div>
            </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Breadcrumb from '../components/Breadcrumb.vue';
import NewAccountInformation from '../components/NewAccountInformation.vue';

export default {
  name: 'Mail Popper',
  components: {
    Breadcrumb,
    NewAccountInformation
  },
  ready () {
    $(document).ready(function ($) {
    })
  }
}
</script>
