<template>
<table class="table table-striped table-hover">
   <thead>
      <tr role="row">
        <th></th>
        <th class="sorting">
            Subject
        </th>
        <th class="sorting sorting_asc">
            Topic
        </th>
        <th class="sorting sorting_desc">
            Status
        </th>
        <th class="sorting">
            Type
        </th>
        <th class="sorting">
            Contact Name
        </th>
        <th class="sorting">
            Contact Email
        </th>
        <th class="sorting">
           Properties
        </th>
        
        <th class="sorting">
            Date
        </th>
        <th class="sorting">
            Assigned To
        </th>
      </tr>
   </thead>
   <tbody>
    <tr role="row">
        <td name="name" class="sorting_1">
            <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded4 = !showexpanded4">
                <i class="fa fa-plus-circle" v-if="!showexpanded4" v-show="!showexpanded4"></i>
                <i class="fa fa-minus-circle" v-if="showexpanded4" v-show="showexpanded4"></i>
            </button>
        </td>
        <td>
             <span class="text-with-label">
                [ref#: 71-713] Payment Failed
            </span>
        </td>
        <td>
            All Topics
        </td>
        <td>
           <small class="bg-green-disabled label">PrivateNote</small>
        </td>
        <td>
            <small class="bg-teal-active label"> 
                <span class="fa-stack stack-icon-label">
                <i class="fa fa-file-text-o"></i>
                <i class="fa fa-lock"></i> 
                </span>  Private Note
            </small>
        </td>
        <td>
            <a href="">Jacob Smith</a>
        </td>
        <td>
            <a href="">	john.doe@example.com</a>
        </td>
        <td>
            <b>Multi Value Properties: </b>Property1; Property2; Property3
        </td>
        <td>
           2 Days ago
        </td>
        <td>
           --
        </td>
      </tr>
        <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded4">
            <td class="white-bg">&nbsp;</td>
            <td colspan="9">
                <ViewModeContent v-if="viewmodecontent" showGetThis = "true" showPaymentStatus = "true"></ViewModeContent>

                <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                <GetThisContent v-if="getthiscontent"></GetThisContent> 

                <PaymentContentFailed v-if="paymentcontent"></PaymentContentFailed>
            </td> 
        </tr>
     <tr role="row">
        <td name="name" class="sorting_1">
            <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded1 = !showexpanded1">
                <i class="fa fa-plus-circle" v-if="!showexpanded1" v-show="!showexpanded1"></i>
                <i class="fa fa-minus-circle" v-if="showexpanded1" v-show="showexpanded1"></i>
            </button>
        </td>
        <td>
             <span class="text-with-label">
                <small class="label bg-yellow" uib-tooltip="There are 5 questions in the case." tooltip-placement="bottom">5</small>
                [ref#: 71-71] Called with question about TVs
            </span>
        </td>
        <td>
            All Topics
        </td>
        <td>
           <small class="bg-green-disabled label">PrivateNote</small>
        </td>
        <td>
            <small class="bg-teal-active label"> 
                <span class="fa-stack stack-icon-label">
                <i class="fa fa-file-text-o"></i>
                <i class="fa fa-lock"></i> 
                </span>  Private Note
            </small>
        </td>
        <td>
            <a href="">Jacob Smith</a>
        </td>
        <td>
            <a href="">	john.doe@example.com</a>
        </td>
        <td>
            <b>Multi Value Properties: </b>Property1; Property2; Property3
        </td>
        <td>
           2 Days ago
        </td>
        <td>
           --
        </td>
      </tr>
        <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded1">
            <td class="white-bg">&nbsp;</td>
            <td colspan="9">
                <ViewModeContent v-if="viewmodecontent" showGetThis = "true"></ViewModeContent>

                <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                <GetThisContent v-if="getthiscontent"></GetThisContent> 
                <PaymentContent v-if="paymentcontent"></PaymentContent>
            </td> 
        </tr>
        <tr role="row">
                <td name="name" class="sorting_1">
                    <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded2 = !showexpanded2">
                        <i class="fa fa-plus-circle" v-if="!showexpanded2" v-show="!showexpanded2"></i>
                        <i class="fa fa-minus-circle" v-if="showexpanded2" v-show="showexpanded2"></i>
                    </button>
                </td>
                <td>
                    <span class="text-with-label">
                       [ref#: 140-140] Need Digital Converter
                    </span>
                </td>
                <td>
                    All Topics
                </td>
                <td>
                  <small class="bg-green-active label">Resolved</small>
                </td>
                <td>
                    <small class="label bg-blue label-with-icon"> 
                        <i class="fa icon-c icon-customer-ticket"></i>
                        Customer Ticket
                    </small>
                </td>
                <td>
                    <a href="">Jacob Smith</a>
                </td>
                <td>
                    <a href="">	john.doe@example.com</a>
                </td>
                <td>
                    <b>Multi Value Properties: </b>Property1; Property2; Property3
                </td>
                <td>
                2 Days ago
                </td>
                <td>
                --
                </td>
        </tr>
        <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded2">
            <td class="white-bg">&nbsp;</td>
            <td colspan="9">
                <ViewModeContent v-if="viewmodecontent" showGetThis = "true"></ViewModeContent>

                <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                <GetThisContent v-if="getthiscontent"></GetThisContent> 
                <PaymentContent v-if="paymentcontent"></PaymentContent>
            </td> 
        </tr>
        <tr role="row">
                <td name="name" class="sorting_1">
                    <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded3 = !showexpanded3">
                        <i class="fa fa-plus-circle" v-if="!showexpanded3" v-show="!showexpanded3"></i>
                        <i class="fa fa-minus-circle" v-if="showexpanded3" v-show="showexpanded3"></i>
                    </button>
                </td>
                <td>
                    <span class="text-with-label">
                      [ref#: 1006-1006] Looking for low cost TV
                    </span>
                </td>
                <td>
                    All Topics
                </td>
                <td>
                  <small class="bg-green-active label">Resolved</small>
                </td>
                <td>
                    <small class="label bg-blue label-with-icon"> 
                        <i class="fa icon-c icon-customer-ticket"></i>
                        Customer Ticket
                    </small>
                </td>
                <td>
                    <a href="">Jacob Smith</a>
                </td>
                <td>
                    <a href="">	john.doe@example.com</a>
                </td>
                <td>
                    <b>Multi Value Properties: </b>Property1; Property2; Property3
                </td>
                <td>
                2 Days ago
                </td>
                <td>
                --
                </td>
        </tr>
        <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded3">
            <td class="white-bg">&nbsp;</td>
            <td colspan="9">
                <ViewModeContent v-if="viewmodecontent" showGetThis = "true"></ViewModeContent>

                <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                <GetThisContent v-if="getthiscontent"></GetThisContent>
                <PaymentContent v-if="paymentcontent"></PaymentContent> 
            </td> 
        </tr>
   </tbody>
</table>
</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';
import PaymentContent from '../components/PaymentContent.vue';
import PaymentContentFailed from '../components/PaymentContentFailed.vue';
import ViewModeContent from '../components/ViewModeContent.vue';
import CreateNoteContent from '../components/CreateNoteContent.vue';
import AgentEmailContent from '../components/AgentEmailContent.vue';
import CreateTicketContent from '../components/CreateTicketContent.vue';
import ForwardContent from '../components/ForwardContent.vue';
import GetThisContent from '../components/GetThisContent.vue';

import CaseProperty from '../components/CaseProperty.vue';
import StatusButton from '../components/StatusButton.vue';


import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Search Result',
      props: {replyType:String,showGetThis: Boolean, showPaymentStatus: Boolean, statusBtnText:String, statusBtnClass:String, showstatusBtnIcon:Boolean,statusBtnIconClass:String, typeBtnText:String, typeBtnClass:String, showtypeBtnIcon:Boolean, typeBtnIconClass:String,typeBtnIconClassGroup:String,typeGroupIcon:Boolean},
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent,
        ViewModeContent,
        CreateNoteContent,
        AgentEmailContent,
        CreateTicketContent,
        ForwardContent,
        GetThisContent,
        CaseProperty,
        StatusButton,
        PaymentContent,
        PaymentContentFailed
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            showexpanded1: false,
            showexpanded4: false,
            viewmodecontent : true,
            createnotecontent : false,
            agentemailcontent : false,
            createticketcontent : false,
            forwardcontent  : false,
            getthiscontent : false,
            replyType : this.replyType,
            showGetThis : this.showGetThis,
            statusbtntext:this.statusBtnText, 
            statusbtnclass:this.statusBtnClass, 
            showstatusbtnicon:this.showstatusBtnIcon,
            statusbtniconclass:this.statusBtnIconClass, 
            typebtntext:this.typeBtnText, 
            typebtnclass:this.typeBtnClass,
            showtypebtnicon:this.showtypeBtnIcon, 
            typebtniconclass:this.typeBtnIconClass,
            typebtniconclassgroup: this.typeBtnIconClassGroup,
            typegroupicon : this.typeGroupIcon,
            showexpanded2 : false,
            showPaymentStatus : this.showGetThis
          }
      },
      mounted(){
           
           this.emitter.on("show-view-details", Data => {
              this[Data.displaySegment] = true;
              this.viewmodecontent = false;
            });
            this.emitter.on("hide-view-details", Data => {
              this[Data.displaySegment] = false;
              this.viewmodecontent = true;
            });
      }
    }
</script>