<template>
 <Breadcrumb page-title="Manage KB Article" page-section="Manage Work Queue"> </Breadcrumb>
  <section class="content"> 
    <div class="row">
        <div class="col-lg-4 col-md-12" v-bind:class = "menuCollapse ?'treeview-expand':'treeview-collapse'">
          <div class="box box-primary">
            <div class="box-header with-border heading-with-toggle" >
                <i class="fa fa-list-ul"></i>
                <h3 class="box-title text-right">Topic List</h3>
                <span aria-hidden="true" class="pull-right  mleft-15 menu-collapse" v-on:click="menuCollapse = !menuCollapse;"><i class="fa fa-reorder treeview-collapse-icon"></i></span>
                <span aria-hidden="true" class="fa pull-right collapse-icon treeview-expand-icon fa-angle-down" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'"  v-on:click="toggleSegmentCollapsedState('1')"></span>
                <span class="pull-right my-topic-switch" uib-collapse="isCollapsedTreeView">
                    <Toggle v-model="showmytopic" /> Show My Topics
                </span>
            </div>
            <div class="box-body search-criteria topic-list-container" v-bind:class = "segment1?'in collapse':''">
                <div class="row">
                    <div class="col-md-12 message-head">
                        <label class="pull-left select-all-topic">
                        <input type="checkbox" class="form-check-input mright-5">Select All</label> 
                        
                        <div class="pull-right count-block" v-tooltip="{text: 'Number of Articles',theme: {placement: 'bottom', }}">
                            #
                        </div>
                    </div> 
                </div>
                <div class="treeview topic-list  active-chat" v-if="!showmytopic">
                    <div class="treeview-segment-name">   
                        E-shop
                    </div>		
                  <ul id="tree1" class="topictree tree">
											<li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i>
												
												<input type="checkbox" class="form-check-input mright-5">
												<div class="treeviewitem">
													<div class="treeviewrow">
														<div class="div1"> All Topic</div> 
													</div>	
												</div>
												<div class="count-columns">
													<div class="pull-right count-block text-aqua">1</div>
												</div>
												
												<ul>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">  _Feedback</div> <div class="div2">&nbsp;</div></div></div> <div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
													<li><input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Newsletter Subscription</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Spam</div><div class="div2">&nbsp;</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"> <div class="treeviewrow"><div class="div1 text-red">_Undeliverable</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
													<li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Account Questions</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div>
														<ul>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Orders </div> </div> </div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Refunds </div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Returns </div> </div> </div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
														</ul>
													</li>
													<li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Products</div><div class="div2">&nbsp;</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div>
														<ul>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Audio-Video</div></div> </div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Cameras </div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Cell Phones</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Computers</div> </div> </div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Televisions</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
														</ul>
													</li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Shipping</div></div></div> <div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Technical Support</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
												
												</ul>
											</li>
										</ul>
                </div>

                 <div class="treeview topic-list  active-chat" v-if="showmytopic">
                    <div class="treeview-segment-name">   
                       Financial Services
                    </div>		
                  <ul id="tree1" class="topictree tree">
                    <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i>
                        
                        <input type="checkbox" class="form-check-input mright-5">
                        <div class="treeviewitem">
                            <div class="treeviewrow">
                                <div class="div1"> All Topic</div> 
                            </div>	
                        </div>
                        <div class="count-columns">
                            <div class="pull-right count-block text-aqua">1</div>
                        </div>
                        
                        <ul>
                            <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">  _Feedback</div> <div class="div2">&nbsp;</div></div></div> <div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
                            <li><input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Newsletter Subscription</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
                            <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Spam</div><div class="div2">&nbsp;</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
                            <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"> <div class="treeviewrow"><div class="div1 text-red">_Undeliverable</div> </div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div></li>
                            <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Account Questions</div></div></div><div class="count-columns"><div class="pull-right count-block text-aqua">1</div></div>
                               
                            </li>
                          
                        </ul>
                    </li>
                </ul>
                </div>
            </div>
          </div>
        </div>
        <div class="p-l-0"  v-bind:class= "menuCollapse ?'col-lg-8 col-md-12':'col-lg-11 col-md-11'">
            <div class="row mbottom-15">
                <div class="col-12 col-md-9 mb-2">
                    <div class="input-group">
                        <input type="text" class="form-control ">
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-primary btn-flat" v-tooltip="{text: 'Search Selected Topic',theme: {placement: 'bottom', }}">Search Selected Topic</button>
                        </span>
                        <span class="input-group-btn"><button type="button" class="btn btn-link btn-sm">Reset</button></span>
                    </div> 
                </div>
                <div class="col-12 col-md-3"><div class="pull-right"><a class="btn btn-primary" href=""> Add Article </a></div></div>
               
            </div>
           <div class="row">
           
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <i class="fa fa-list"></i>
                        <h3 class="box-title">Article List</h3>
                    </div>
                    <div class="box-body">
                        <AgentsKbArticleTableList></AgentsKbArticleTableList>
                    </div>
                </div>
            </div>
          </div>
        </div>			
    </div>
  </section>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
// @ is an alias to /src
import Breadcrumb from '../components/Breadcrumb.vue';
import ChatButtons from '../components/ChatButtons.vue';
import MassUpdateBody from '../components/MassUpdateBody.vue';
import AgentsKbArticleTableList from '../components/AgentsKbArticleTableList.vue';
import Toggle from '@vueform/toggle'
export default {
  name: 'Manage KB Articles',
  data() {
      return{
          showmore : false,
          segment1 : true,
          menuCollapse : true,
          showmytopic : false
      }
  },
  components: {
    Breadcrumb,
    ChatButtons,
    MassUpdateBody,
    AgentsKbArticleTableList,
    Toggle
  },
  ready () {
    $(document).ready(function ($) {
    })
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    }
  }
}
</script>
