<template>
    <Breadcrumb page-title="SMS Conversations" page-section="Manage Work Queue"> </Breadcrumb>
     <section class="content">
       <div class="row">
        <div class="col-md-4" v-bind:class = "menuCollapse ?'treeview-expand':'treeview-collapse'">
          <div class="box box-primary">
            <div class="box-header with-border heading-with-toggle" >
                <i class="fa fa-list-ul"></i>
                <h3 class="box-title text-right">Topic List</h3>
                <span aria-hidden="true" class="pull-right  mleft-15 menu-collapse" v-on:click="menuCollapse = !menuCollapse;"><i class="fa fa-reorder treeview-collapse-icon"></i></span>
                <span v-on:click="treeView()" aria-hidden="true" class="menu-collapse glyphicon  pull-right glyphicon-chevron-right mleft-15 treeview-expand-icon" ng-class="{'glyphicon-chevron-right__expanded': !isCollapsedTreeView}" ng-init="isCollapsedTreeView = false;"></span>
                <span class="pull-right my-topic-switch" uib-collapse="isCollapsedTreeView">
                    <Toggle v-model="showmytopic" /> Show My Topics
                </span>
            </div>
            <div class="box-body search-criteria topic-list-container">
                <div class="row">
                    <div class="col-md-12 message-head">
                        <label class="pull-left select-all-topic">
                        <input type="checkbox" class="form-check-input mright-5">Select All</label> 
                       
                       <div class="pull-right count-block">
                            <span class="fa-stack stack-icon-label" v-tooltip="{text: 'Assigned Conversation',theme: {placement: 'bottom', }}">
                                <i class="fa fa-envelope"></i>
                                <i class="fa fa-check text-green"></i> 
                            </span>
                        </div>
                        <div class="pull-right count-block">
                            <span class="fa-stack stack-icon-label" v-tooltip="{text: 'Unassigned Conversation',theme: {placement: 'bottom', }}">
                                <i class="fa fa-envelope"></i>
                                <i class="fa fa-close"></i> 
                            </span>
                        </div>
                       
                        <div class="pull-right count-block" v-tooltip="{text: 'Agents skilled for this topic',theme: {placement: 'bottom', }}">
                            <i class="fa fa-user"></i>
                        </div>
                    </div> 
                </div>
                <div class="treeview topic-list  active-chat" v-if="!showmytopic">
                    <div class="treeview-segment-name">   
                        E-shop
                    </div>		
                  <ul id="tree1" class="topictree tree">
											<li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i>
												
												<input type="checkbox" class="form-check-input mright-5">
												<div class="treeviewitem">
													<div class="treeviewrow">
														<div class="div1"> All Topic</div> 
													</div>	
												</div>
												<div class="count-columns">
													<div class="pull-right count-block">10</div>
													<div class="pull-right count-block" >2</div>
													<div class="pull-right count-block text-aqua" uib-tooltip="Alek Packard" tooltip-placement="bottom">1</div>
												
												</div>
												
												<ul>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">  _Feedback</div> <div class="div2">&nbsp;</div></div></div> 
                                                     <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                    </li>
													<li><input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Newsletter Subscription</div></div></div>
                                                    <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                    </li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Spam</div><div class="div2">&nbsp;</div> </div></div>
                                                    <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                    </li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"> <div class="treeviewrow"><div class="div1 text-red">_Undeliverable</div> </div></div>
                                                    <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                    </li>
													<li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Account Questions</div></div></div>
<div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>

														<ul>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Orders </div> </div> </div>
                                                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                            </li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Refunds </div></div></div>
                                                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                            </li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Returns </div> </div> </div>
                                                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                            </li>
														</ul>
													</li>
													<li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Products</div><div class="div2">&nbsp;</div> </div></div>
<div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>

														<ul>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Audio-Video</div></div> </div>
                                                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                            </li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Cameras </div> </div></div>
                                                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                            </li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Cell Phones</div></div></div>
                                                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                            </li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Computers</div> </div> </div>
                                                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                            </li>
															<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Televisions</div> </div></div>
                                                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                            </li>
														</ul>
													</li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Shipping</div></div></div> 
                                                    <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                    </li>
													<li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Technical Support</div></div></div>
                                                    <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                                                    </li>
												
												</ul>
											</li>
										</ul>
                </div>

                 <div class="treeview topic-list  active-chat" v-if="showmytopic">
                    <div class="treeview-segment-name">   
                       Financial Services
                    </div>		
                  <ul id="tree1" class="topictree tree">
                    <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i>
                        
                        <input type="checkbox" class="form-check-input mright-5">
                        <div class="treeviewitem">
                            <div class="treeviewrow">
                                <div class="div1"> All Topic</div> 
                            </div>	
                        </div>
                        <div class="count-columns">
                           
                            <div class="pull-right count-block" >10</div>
                            <div class="pull-right count-block text-red">2</div>
                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                        </div>
                        
                        <ul>
                            <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">  _Feedback</div> <div class="div2">&nbsp;</div></div></div> 
                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                            </li>
                            <li><input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Newsletter Subscription</div></div></div>
                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                            </li>
                            <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Spam</div><div class="div2">&nbsp;</div> </div></div>
                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                            </li>
                            <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"> <div class="treeviewrow"><div class="div1 text-red">_Undeliverable</div> </div></div>
                            <div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>
                            </li>
                            <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Account Questions</div></div></div>
<div class="count-columns">
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block">--</div>
                                                            <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div>
                                                     </div>

                               
                            </li>
                          
                        </ul>
                    </li>
                </ul>
                </div>
            </div>
          </div>
        </div>
        <div class="p-l-0"  v-bind:class= "menuCollapse ?'col-md-8':'col-md-11'">
             <div class="row">
              
               <div class="col-md-12">
                   <div class="box box-info">
                       <div class="box-header with-border">
                           <i class="fa  fa-envelope"></i>
                           <h3 class="box-title">SMS List</h3>
                           <div class="pull-right">
                             <ChatButtons>  </ChatButtons>
                           </div>
                            <div class="pull-right item-count mtop-5 mright-15 ">
                               <span class="text-primary font-weight-bold"><small class="label bg-yellow">2</small> Items Selected</span>
                           </div> 
                       </div>
                       <div class="box-body">
                           <ManageSMSTableList></ManageSMSTableList>
                       </div>
                   </div>
                  
               </div>
             </div>
           </div>			
       </div>
     </section>
   </template>
   <style src="@vueform/toggle/themes/default.css"></style>
   <script>
   // @ is an alias to /src
   import Breadcrumb from '../components/Breadcrumb.vue';
   import ChatButtons from '../components/ChatButtons.vue';
   import MassUpdateBody from '../components/MassUpdateBody.vue';
   import ManageSMSTableList from '../components/ManageSMSTableList.vue';
   import Toggle from '@vueform/toggle'
   export default {
     name: 'Manage SMS',
     data() {
         return{
             showmore : false,
             segment1 : true,
             menuCollapse : true,
             showmytopic : false
         }
     },
     components: {
       Breadcrumb,
       ChatButtons,
       MassUpdateBody,
       ManageSMSTableList,
       Toggle
     },
     ready () {
       $(document).ready(function ($) {
       })
     },
      methods: {
       toggleSegmentCollapsedState(i) {
         this['segment'+i] = !this['segment'+i] 
       }
     }
   }
   </script>
   