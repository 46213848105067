<template>
    <div class="col-md-12 form-action" v-if="paymentSuccess == false">
       <div class="overlay-wrapper">
           <!--v-if-->
          
           <div class="row" id="Main_ManageMessages_Details56675_Payment">
               <div class="col-md-8 col-lg-6 form-horizontal">
                <div class="form-group">
                       <label class="col-3 control-label text-left">Transaction Type</label>
                       <div class="col-9"><button class="btn btn-link">Reverse/Refund Payment</button></div>
                   </div>
                   <div class="form-group">
                       <label class="col-3 control-label text-left">Refund Amount</label>
                       <div class="col-9"><input class="form-control" type="number"></div>
                   </div>
                   
                   <div class="form-group">
                       <label class="col-3 control-label text-left">Refund Date</label>
                       <div class="col-9"><input class="form-control" type="number"></div>
                   </div>
                   <div class="form-group">
                       <label class="col-3 control-label text-left">Refund Note</label>
                       <div class="col-9"><textarea class="form-control" type="number"></textarea></div>
                   </div>
                   <div class="form-group">
                      <div class="col-md-6">
                        <button class="btn btn-primary" type="button"> Proceed </button>
                        <button class="btn btn-link" type="button" v-on:click="cancel('paymentcontent')"> Cancel </button>
                      </div>  
                    </div>
               </div>
               <div class="col-md-4 form-horizontal" style="display: none;">
                   <div id="credit-card"></div>
               </div>
               <!--v-if-->
           </div>
           <div class="overlay" style="display: none;"><i class="fa fa-refresh fa-spin"></i></div>
       </div>
       <div class="row">
       
       </div>
   </div>

   <div class="col-md-12" v-if="paymentSuccess == true">
       <div class="box">
         <div class="box-body print-receipt">
           <div class="row">
               
               <div class="col-md-12 mbottom-15 form-alert-message">
                   <button class="btn btn-default mbottom-15" v-on:click="cancel('paymentcontent')">Close</button>
                   
                   <div class="alert alert-success alert-dismissible">
                       <button type="button" class="close">×</button>
                       <div class="message-container">
                       <div class="icon">
                           <i class="icon fa fa-ban"></i>
                       </div>
                       <div class="message">
                           <strong>Thank You!</strong> Your payment has been made.
                       </div>
                       </div>
                   </div>
               </div>
           </div>
           
           <div class="row">
             <div class="col-md-8">
               <table class="table">
                    <tr> 
                       <td><strong>Reference Number</strong></td>
                       <td>#9898989</td>
                   </tr>
                   <tr> 
                       <td><strong>Date of Payment</strong></td>
                       <td>7/14/23</td>
                   </tr>
                   <tr> 
                       <td><strong>Amount Paid</strong></td>
                       <td>$200.45</td>
                   </tr>
                   <tr> 
                       <td><strong>Payment Method</strong></td>
                       <td>Visa (####-####-####-8909)</td>
                   </tr>
                   <tr> 
                       <td><strong>Confirmation Number</strong></td>
                       <td>09809809</td>
                   </tr>
                  
                </table>
             </div>
             <div class="col-md-4">
               <a href="" class="pull-right"><i class="fa fa-print"></i> Print Receipt</a>
             </div>
             
           </div>
         </div> 
       </div> 
     </div>  
</template>
   
   <script>
   import ActionModeButtons from '../components/ActionModeButtons.vue';
   import EmailAddressSubjectResponse from '../components/EmailAddressSubjectResponse.vue';
   import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
   import DraftBodyTabs from '../components/DraftBodyTabs.vue';
   
   export default {
     name: 'Payment',
      components: {
       
     },
     data: function(){
         return{
           paymentSuccess : false
        
         }
     },
     methods: {
         submitPayment(){
            this.paymentSuccess = true;
         },
         cancel(segment){
             this.emitter.emit("hide-view-details",{displaySegment:segment});
           }
     }
   
    
   }
   </script>