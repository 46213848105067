  <template>
     <section class="content">
        <div class="row">
            <div class="col-md-12">
              <div class="cf-form">
               <ConverForm></ConverForm>
               <div id="myForm" style="min-height:500px">

                </div>
              </div>
            </div>
        </div>
      </section>
  </template>
  <script>
  // Import necessary components and classes
  import { FlowForm, QuestionModel, QuestionType, ChoiceOption, LanguageModel } from '@ditdot-dev/vue-flow-form'
  import ConverForm  from '../components/ConversationalForms';
  import ConversationalForm  from 'conversational-form';

  export default {
    name: 'example',
    components: {
      FlowForm,
      ConversationalForm,
      ConverForm
    },
    data() {
      return {
        language: new LanguageModel({
          // Your language definitions here (optional).
          // You can leave out this prop if you want to use the default definitions.
        }),
        questions: [
          // QuestionModel array
          new QuestionModel({
            title: 'Question',
            type: QuestionType.MultipleChoice,
            options: [
              new ChoiceOption({
                label: 'Answer',  
              }),
              
            ]
          })
        ]
      }
    }
  }
</script>

<style>
  /* Import Vue Flow Form base CSS */
  @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
  /* Import one of the Vue Flow Form CSS themes (optional) */
  @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css';
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-green.css'; */
  /* @import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-purple.css'; */
</style>