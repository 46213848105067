<template>
    <div class="mtop-15">
            <div class="col-md-12 mbottom-15 form-alert-message ">
                <div class="alert alert-danger alert-dismissible">
                    <button type="button" class="close">×</button>
                     <div class="message-container">
                       <div class="icon">
                          <i class="icon fa fa-ban"></i>
                       </div>
                        <div class="message">
                            <div>
                               Please enter a subject for your note.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div class="col-md-12 mbottom-15 form-alert-message">
                <div class="alert alert-warning alert-dismissible">
                    <button type="button" class="close">×</button>
                    <div class="message-container">
                       <div class="icon">
                          <i class="icon fa fa-warning"></i> 
                       </div>
                        <div class="message">
                            <strong>Contact does not have valid email address (Configuration Notifications)</strong>
                            <div>
                                This contact does not have a valid email address
                                You tried to create an email to this contact, but they
                                don't have a valid address. Update the contact's details with 
                                valid email address and try again.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mbottom-15 form-alert-message">
                <div class="alert alert-info alert-dismissible">
                    <button type="button" class="close">×</button>
                    <div class="message-container">
                       <div class="icon">
                          <i class="icon fa fa-info-circle"></i> 
                       </div>
                        <div class="message">
                            <strong>Contact does not have valid email address</strong>
                            <div>
                                
                                This contact does not have a valid email address
                                You tried to create an email to this contact, but they
                                don't have a valid address. Update the contact's details with 
                                valid email address and try again.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="col-md-12 mbottom-15 form-alert-message">
                <div class="alert alert-success alert-dismissible">
                    <button type="button" class="close">×</button>
                    <div class="message-container">
                       <div class="icon">
                          <i class="icon fa fa-check-circle"></i> 
                       </div>
                        <div class="message"> 
                            <div>
                              Agent Email is sent successfully.
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
     <div class="mtop-15">
           <div class="col-md-12 mbottom-15 form-alert-message ">
                <div class="alert alert-danger alert-dismissible">
                    <button type="button" class="close">×</button>
                    <div class="message-container">
                       <div class="icon">
                          <i class="icon fa fa-ban"></i>
                       </div>
                        <div class="message">
                            <strong>Errors</strong>
                            <ul>
                              <li> Please enter a subject for your note.</li>
                              <li> Please enter a for your note.</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
            </div>
    </div>
    <div class="col-md-12">
        <div class="card col-md-6">
            <div class="card-body">
                <Form  :validation-schema="schema" v-slot="{ errors }">                  
                    <div class="form-group">
                       <button type="button" class="btn btn-primary mr-1"  v-on:click="myFunction()">Show Submit Success Alert</button>
                    </div>
                </Form>
            </div>
        </div>   
    </div> 
    <!--toast Message-->
       <div id="snackbar" class="alert alert-success" :class="[ showSnakeBarMessage == true ? 'showtoast' : '']">
        <div class="message-container"><div class="icon"><i class="icon fa fa-check-circle"></i></div><div class="message"><div> Agent Email is sent successfully. </div></div></div>
       </div>
    <!-- toast message-->
</template>

<script>
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';

export default {
    components: {
        Form,
        Field,
    },
    data() {
        return {
          showSnakeBarMessage:false
        };
    },
    setup() {
        const schema = Yup.object().shape({
            title: Yup.string()
                .required('<span>Title is required</span>'),
            firstName: Yup.string()
                .required('<span>First Name is required</span>'),
            lastName: Yup.string()
                .required('<span>Last name is required</span>'),
            dob: Yup.string()
                .required('<span>Date of Birth is required</span>')
                .matches(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/, 'Date of Birth must be a valid date in the format YYYY-MM-DD'),
            email: Yup.string()
                .required('<span>Email is required</span>')
                .email('<span>Email is invalid</span>'),
            password: Yup.string()
                .min(6, '<span>Password must be at least 6 characters</span>')
                .required('<span>Password is required</span>'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match</span>')
                .required('<span>Confirm Password is required</span>'),
            acceptTerms: Yup.string()
                .required('<span>Accept Ts & Cs is required</span>')
        });

        const onSubmit = values => {
           
        }

        return {
            schema,
            onSubmit
        };
    },
    methods:{
        closeToastMessage: function(){
            this.showSnakeBarMessage  = false;
        },
        myFunction: function(){
            this.showSnakeBarMessage =  true;
            setTimeout(function(){ this.closeToastMessage();}.bind(this), 3000);  
        },
    }  
}
</script>