<template>
<div class="login-page login-bg">
  <div class="login-box">
    <div class="login-logo">
      <a href="/">
        <svg>
          <use xlink:href="/img/logo.svg#iservice-logo" />
        </svg>
        <b class="text-logo-gray">Service 	<span class="register-trade-mark">&reg;</span></b>
      </a>
    </div>
    <!-- /.login-logo -->   <div class="login-box-body">
        <p class="login-box-msg">Agent Login</p>
         <div class="form-alert-message mbottom-15">
            <div class="alert alert-success alert-dismissible" id="">
                <button type="button" class="close">×</button>
                <div class="message-container">
                    <div class="icon"><i class="icon fa fa-ban"></i></div>
                    <div class="message">
                        <div>Enter your iService login credential to connect your Google account to your iService agent login.</div>
                    </div>
                </div>
            </div>
          </div>
        <form action="/pages/dashboard" method="post">
          <div class="form-group has-feedback">
            <input class="form-control" placeholder="Login Name">
            <span class="glyphicon glyphicon-user form-control-feedback"></span>
          </div>
          <div class="form-group has-feedback">
            <input type="password" class="form-control" placeholder="Password">
            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
          </div>
          <div class="row">
            <div class="col-8">
              <a href="/">
                <button type="button" class="btn btn-link">I forget my password</button>
              </a>  
            </div>
           
            <!-- /.col -->
            <div class="col-4">
              <a href="/not-an-agent-error">
                <button type="button" class="btn btn-primary btn-block btn-flat">Sign In</button>
              </a>
            </div>
            <!-- /.col -->
             <div class="col-8">
              <a href="/">
                <button type="button" class="btn btn-link">I am not an agent</button>
              </a>  
            </div>
          </div>
        </form>
        <div class="clearfix"></div>
        <!-- /.social-auth-links -->
      </div>
    <!-- /.login-box-body -->
    <div class="copy-text">
       &copy; 2008-2021 One-to-One Service.com. All rights reserved.
       <span>Powered by iService Version: 8.0 - </span>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Google Connect',
  components: {
    
  },
  ready () {
    $(document).ready(function ($) {
    
    })
  }
}
</script>
