<template>
  <Breadcrumb page-title="Alerts List" page-section=""> </Breadcrumb>
  <section class="content">
    <div class="row">
        <div class="col-md-12">
        <!-- Custom Tabs -->
            <div class="row">
                <div class="col-md-12">
                    <div class="box box-info">
                        <div class="box-header with-border">
                        <i class="fa  fa-bell-o"></i>
                        <h3 class="box-title head-title-padding">Alerts</h3>
                        </div>
                        <!-- /.box-header -->
                        <!-- form start -->
                        
                        <div class="box-body table-responsive">
                        <div class="notdatatable form-inline mbottom-15">
                            <div class="table-controls table-columns">
                                <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                            </div>
                            <div class="table-controls table-pagesize table-columns">
                                <label>
                                    Show 
                                    <select class="form-control input-xs">
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="250">250</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                    </select>
                                    entries 
                                </label>
                            </div>
                            
                            <div class="table-controls table-filter">Filter Alerts <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                        </div>
                        <table class="table table-striped table-hover">
                            <thead>
                            <tr id="row1">
                                <!--<th class="no-sort"  name="name" width="30"></th>-->
                                <th class="text-capitalize sorting">
                                    Name
                                </th>
                                <th class="text-capitalize sorting">
                                     Frequency
                                </th>
                                <th class="text-capitalize sorting">
                                    Question Age
                                </th>
                                <th class="text-capitalize sorting">
                                    Topics
                                </th>
                                <th class="text-capitalize sorting">
                                 Actions
                                </th>
                               
                            </tr>
                            </thead>
                            <tbody>
                            <tr id="row2" @click="showMessageDetails('','row2')" v-bind:class = "showDetails.active_el == 'row2' ?'row-active':''">
                               
                                <td>
                                	Set billable and resolve
                                </td>
                                <td>
                                   1 minutes
                                </td>
                                <td>
                                 10 minutes
                                </td>

                                <td>
                                 All Topic
                               </td>
                                <td>
                                <iServiceSwitch></iServiceSwitch>
                                </td>
                              
                            </tr>

                            <tr id="row3" v-on:click="showMessageDetails('','row3')" v-bind:class = " showDetails.active_el == 'row3' ? 'row-active' : 'hell'">
                              
                                <td>
                                   Support issue
                                </td>
                                <td >
                                   1 minutes
                                </td>
                                <td>
                                 5 minutes
                                </td>

                                <td>
                                 All Topic
                               </td>
                                <td>
                                <iServiceSwitch></iServiceSwitch>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div id="Main_MyDashboard_Recent_TableInfo">
                            <div class="notdatatable table-info">Showing 1 to 2 of 2 entries</div>
                            <div class="dataTables_paginate notdatatable table-pagination text-end">
                                <ul class="pagination">
                                    <li><a href="">Previous</a></li>
                                    <li class="active"><a href="">1</a></li>
                                    <li><a href="">Next</a></li>
                                </ul>
                            </div>
                            </div>
                        <!-- Data loader start
                        <div class="data-loader">
                            <i class="fa fa-spinner fa-spin fa-fw"></i>
                        </div>  -->
                        <!-- Data loader end-->
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                </div>
            </div>
        </div>
        <!-- /.col -->
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <button  class="btn btn-primary" v-on:click="showNewAlert()">New Alert</button>
          <button  class="btn btn-danger btn-delete" v-on:click="showDeleteAlert()">Delete Alert...</button>
        </div>
      </div>
    </div>

    <div class="expandedrowcontent box-bg-color" v-if="showDetails.showNew">
        <div class="box box-info with-border">
            <div class="box-body table-responsive">
                <div class="box-header with-border">
                    <i class="fa fa-bell-o"></i>
                    <h3 class="box-title text-right">New Alert</h3>
                </div>
                <div class="box-body">
                  <AlertForm form-title="New Alerts"></AlertForm>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="showDetails.deleteAlert">
        <div class="col-md-12" iservice-id-prefix="'Delete'">
            <div class="box box-danger" iservice-id="'Delete'">
                <div class="box-header with-border">
                    <i class="fa fa-warning"></i>
                    <h3 class="box-title text-right">Delete Alert</h3>
                </div>
                <div class="box-body">
                    <section class="box-content box-bg-color">
                    <div class="row">
                        <div class="form-horizontal">
                            <div class="col-md-12">
                                <p class="text-danger">WARNING: Deletion is immediate and irrevocable.</p>
                            </div>
                        </div>
                        <div class="form-horizontal">
                            <div class="col-md-12"><button iservice-id="Confirm" class="btn btn-danger btn-sm">Confirm Deletion</button><button iservice-id="Cancel" class="btn btn-link" v-on:click="cancel()">Cancel</button></div>
                        </div>
                    </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
  
    <div  v-if="showDetails.showAlertDetails">
        <div class="box box-info with-border">
            <div class="box-body table-responsive">
                <div class="box-header with-border">
                    <i class="fa fa-bell-o"></i>
                    <h3 class="box-title text-right">Alerts - Support issue</h3>
                </div>
                <div class="box-body">
                  <AlertForm form-title="Alerts - Support issue"></AlertForm>
                </div>
            </div>
        </div>
    </div>

  </section>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import MyInboxMessages from '../components/MyInboxMessages.vue';
import InboxMessageDetail from '../components/InboxMessageDetail.vue';
import iServiceSwitch from '../components/iServiceSwitch.vue';
import AlertForm from '../components/AlertForm.vue';

import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'MyMessageInboxComponents',
   components: {
    Breadcrumb,
    MyInboxMessages,
    InboxMessageDetail,
    iServiceSwitch,
    Multiselect,
    AlertForm
  },
  setup(){      
       
       var showDetails = reactive({showNew:false,deleteAlert:false, showAlertDetails:false,currentRow:''});
       var showNew =   reactive(false);
       var deleteAlert =  reactive(false);
       var show = reactive(false);
       var currentRow = '';

       function showNewAlert(){
         showDetails.showNew = true; 
         showDetails.deleteAlert = false;  
         showDetails.showAlertDetails = false;
         showDetails.active_el = '';
       }
       function showDeleteAlert(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = true;  
       }
       function showMessageDetails(val,row){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showAlertDetails=true;
         showDetails.active_el = row;
       }
       function cancel(){
         showDetails.showNew = false; 
         showDetails.deleteAlert = false;  
         showDetails.showAlertDetails=false;
       }

       return{cancel,showNew,deleteAlert,showDetails,showNewAlert,showDeleteAlert,showMessageDetails};
  }

}
</script>
