<template>
 <Breadcrumb page-title="Manage Messages" page-section="Manage Messages"> </Breadcrumb>
  <section class="content">
    <div class="row"> 
        <div class="col-md-12">
            <div class="alert alert-warning alert-dismissible alert-inline pull-left mbottom-15" v-if="alertmessage">
                <button type="button" class="close" v-on:click="alertmessage = false;">&times;</button>    
                <h4><i class="icon fa fa-warning"></i>Alert! <span class="text">You have reached the maximum number of interactions that can be displayed on this page (2500).<span class="more" v-on:click="showmore = !showmore" v-show="!showmore">...more</span><span class="more"  v-on:click="showmore = !showmore" v-show="showmore" >...less</span></span></h4>
                <div class="mtop-15" v-show =" showmore">
                    This page displays a maximum of 2500 unresolved questions to avoid performance issues caused by loading details of too many interactions. Since you have more than 2500 unresolved questions, some of your questions are not displayed. Answer or resolve enough questions to keep your total unresolved questions below 2500.
                </div>	
            </div>	
        </div>
    </div>
    <div class="row">
        <div class="col-md-4" v-bind:class = "menuCollapse ?'treeview-expand':'treeview-collapse'">
          <div class="box box-primary">
            <div class="box-header with-border heading-with-toggle" >
                <i class="fa fa-list-ul"></i>
                <h3 class="box-title text-right">Topic List</h3>
                <span aria-hidden="true" class="pull-right  mleft-15 menu-collapse" v-on:click="menuCollapse = !menuCollapse;"><i class="fa fa-reorder treeview-collapse-icon"></i></span>
                <span v-on:click="treeView()" aria-hidden="true" class="menu-collapse glyphicon  pull-right glyphicon-chevron-right mleft-15 treeview-expand-icon" ng-class="{'glyphicon-chevron-right__expanded': !isCollapsedTreeView}" ng-init="isCollapsedTreeView = false;"></span>
                <span class="pull-right my-topic-switch" uib-collapse="isCollapsedTreeView">
                    <Toggle v-model="showmytopic" /> Show My Topics
                </span>
            </div>
            <div class="box-body search-criteria topic-list-container">
                <div class="box-placeholder" v-if="isHidden">
                    <div class="mbottom-15">
                        <span class="category text link"></span>
                        <h4 class="text line"></h4>
                        <h4 class="text"></h4>
                    </div>
                    <div class="excerpt mbottom-15">
                        <div class="text line"></div>
                        <div class="text line"></div>
                        <div class="text"></div>
                    </div>
                    <div class="excerpt mbottom-15">
                        <div class="text title line"></div>
                        <div class="text title line"></div>
                        <div class="text"></div>
                    </div>
                    <div class="excerpt mbottom-15">
                        <div class="text line"></div>
                        <div class="text line"></div>
                        <div class="text title"></div>
                    </div>
                    <p class="loading-text">
                        Gathering Topics..
                    </p>	
                </div>
                <div v-if="!isHidden">
                    <div class="row">
                        <div class="col-md-12 message-head">
                            <label class="pull-left select-all-topic">
                            <input type="checkbox" class="form-check-input mright-5">Select All</label> 
                            <div class="pull-right count-block" v-tooltip="{text: 'Proposed Answers',theme: {placement: 'bottom', }}">
                                <i class="fa fa-mail-reply"></i>
                            </div>
                            <div class="pull-right count-block">
                                <span class="fa-stack stack-icon-label" v-tooltip="{text: 'Assigned questions',theme: {placement: 'bottom', }}" >
                                    <i class="fa fa-envelope"></i>
                                    <i class="fa fa-arrow-circle-o-right text-green"></i> 
                                </span>
                            </div>
                            <div class="pull-right count-block">
                                <span class="fa-stack stack-icon-label" v-tooltip="{text: 'Unassigned questions',theme: {placement: 'bottom', }}">
                                    <i class="fa fa-envelope"></i>
                                    <i class="fa fa-clock-o"></i> 
                                </span>
                            </div>
                            <div class="pull-right count-block" v-tooltip="{text: 'Agents skilled for this topic',theme: {placement: 'bottom', }}">
                                <i class="fa fa-user"></i>
                            </div>
                        </div> 
                    </div>
                    <div class="treeview topic-list  active-chat" v-if="!showmytopic">
                        <div class="treeview-segment-name">   
                            E-shop
                        </div>		
                        <ul id="tree1" class="topictree tree">
                                                <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i>
                                                    
                                                    <input type="checkbox" class="form-check-input mright-5">
                                                    <div class="treeviewitem">
                                                        <div class="treeviewrow">
                                                            <div class="div1"> All Topic</div> 
                                                        </div>	
                                                    </div>
                                                    <div class="count-columns">
                                                        <div class="pull-right count-block">10</div>
                                                        <div class="pull-right count-block" v-tooltip="{text: '1043',theme: {placement: 'bottom', }}">1K+</div>
                                                        <div class="pull-right count-block text-red">500</div>
                                                        <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">10</div>
                                                    </div>
                                                    
                                                    <ul>
                                                        <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">  _Feedback</div> <div class="div2">&nbsp;</div></div></div> <div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                        <li><input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Newsletter Subscription</div></div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                        <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Spam</div><div class="div2">&nbsp;</div> </div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                        <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"> <div class="treeviewrow"><div class="div1 text-red">_Undeliverable</div> </div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                        <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Account Questions</div></div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div>
                                                            <ul>
                                                                <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Orders </div> </div> </div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                                <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Refunds </div></div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                                <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Returns </div> </div> </div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                            </ul>
                                                        </li>
                                                        <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Products</div><div class="div2">&nbsp;</div> </div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div>
                                                            <ul>
                                                                <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Audio-Video</div></div> </div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                                <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Cameras </div> </div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                                <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Cell Phones</div></div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                                <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Computers</div> </div> </div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                                <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Televisions</div> </div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                            </ul>
                                                        </li>
                                                        <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Shipping</div></div></div> <div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                        <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Technical Support</div></div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                                    
                                                    </ul>
                                                </li>
                                            </ul>
                    </div>

                    <div class="treeview topic-list  active-chat" v-if="showmytopic">
                        <div class="treeview-segment-name">   
                        Financial Services
                        </div>		
                        <ul id="tree1" class="topictree tree">
                            <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i>
                                
                                <input type="checkbox" class="form-check-input mright-5">
                                <div class="treeviewitem">
                                    <div class="treeviewrow">
                                        <div class="div1"> All Topic</div> 
                                    </div>	
                                </div>
                                <div class="count-columns">
                                    <div class="pull-right count-block">10</div>
                                    <div class="pull-right count-block" v-tooltip="{text: '1043',theme: {placement: 'bottom', }}">1K+</div>
                                    <div class="pull-right count-block text-red">500</div>
                                    <div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">10</div>
                                </div>
                                
                                <ul>
                                    <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">  _Feedback</div> <div class="div2">&nbsp;</div></div></div> <div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                    <li><input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Newsletter Subscription</div></div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                    <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1 text-red">_Spam</div><div class="div2">&nbsp;</div> </div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                    <li> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"> <div class="treeviewrow"><div class="div1 text-red">_Undeliverable</div> </div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div></li>
                                    <li class="branch"><i class="indicator fa fa-minus-circle mright-5"></i> <input type="checkbox" class="form-check-input mright-5"><div class="treeviewitem"><div class="treeviewrow"><div class="div1">Account Questions</div></div></div><div class="count-columns"><div class="pull-right count-block">--</div><div class="pull-right count-block">--</div><div class="pull-right count-block text-red">--</div><div class="pull-right count-block text-aqua" v-tooltip="{text: 'Alek Packard',theme: {placement: 'bottom', }}">1</div></div>
                                    
                                    </li>
                                
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <div class="p-l-0"  v-bind:class= "menuCollapse ?'col-md-8':'col-md-11'">
          <div class="row">
            <div class="col-md-12">
				<div class="box box-primary">
                   <div class="box-header with-border">
                        <h3 class="box-title head-title-padding"> <i class="fa fa-fw fa-edit"></i> Mass Update</h3>
                        <span aria-hidden="true" class="fa fa-angle-right pull-right mleft-15 chevron-icon expanded" v-bind:class = "segment1?'':'chevron-expand-indicator_expanded'"  v-on:click="toggleSegmentCollapsedState('1')"></span>
                        <span class="pull-right"> 
                          <MassUpdateButtons></MassUpdateButtons>
                        </span>
                        <div class="pull-right item-count mtop-5 mright-15 ">
                            <span class="text-primary font-weight-bold"><small class="label bg-yellow">2</small> Items Selected</span>
                        </div>
                    </div> 
                    <div class="box-body" v-bind:class = "segment1?'in collapse':''">
                       <MassUpdateBody></MassUpdateBody>
                    </div>
                </div>

                
                <div class="box box-info" v-if="showmerge != 0">
                    <div class="box-header with-border">
                        <i class="fa fa-code-fork"></i>
                        <h3 class="box-title">Merge Interactions</h3>   
                    </div>
                    <div class="box-body">
                      <div class="row form-vertical" v-if="mergestep == '1'">  
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label class="col-md-12 control-label text-left required">
                                    Select the contact for the new interaction</label>
                                <div class="col-12">
                                    <Multiselect
                                        v-model="value"
                                        placeholder="Select or search for an agent in the list"
                                        label="name"
                                        :searchable="true"
                                        :options="[
                                        { value: 'alek packard', name: 'Alek Packard',email:'alek.packard@example.com',status:'',disabled: false  },
                                        { value: 'jacob smith', name: 'Jacob Smith',email:'jacob.smith@example.com',status:'notavailable',disabled: true  },
                                        { value: 'jonnie hewlett', name: 'Jonnie Hewlett',email:'jonnie.hewlett@example.com',status:'logout',disabled: false  },
                                        ]"
                                    >
                                        <template v-slot:singlelabel="{ value }">
                                        <div class="multiselect-single-label">
                                            {{ value.name }}
                                        </div>
                                        </template>
                                        </Multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button class="btn margin-r-5 btn-primary" type="button" v-on:click=" mergestep = 2;">
                              Continue  
                            </button> 
                            <button class="btn margin-r-5 btn-link" type="button" v-on:click=" showmerge = 0;">
                                Cancel
                            </button> 
                        </div>
                      </div>  
                      
                      <div class="row form-vertical" v-if="mergestep == '2'">  
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label class="col-md-12 control-label text-left required">
                                    New Subject line <span class="required">(Required)</span></label>
                                <div class="col-12"><input type="text" class="form-control"></div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="form-group">
                                <label class="col-md-12 control-label text-left required">
                                    Select New Topic <span class="required">(Required)</span></label>
                                <div class="col-12">
                                    <Multiselect
                            v-model="value"
                            placeholder="-Select Topic-"
                            label="name"
                            :groups="true"
                            :searchable="true"
                            :options="[
                                {
                                label: '_iService',
                                options:  [
                                    { value: 'All Topic', name: 'All Topic',level:'1'},
                                    { value: 'Feedback', name: 'Feedback',level:'2'},
                                    
                                ],
                                },
                                {
                                label: 'Sales',
                                options:  [
                                    { value: 'All Topic', name: 'All Topic',level:'1'},
                                    { value: 'Feedback', name: 'Feedback',level:'2'},
                                    
                                ],
                                }
                            ]"
                            >
                            <template v-slot:option="{ option }">
                            <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                            </template>
                        </Multiselect>

                                </div>
                            </div>
                          
                        </div>
                        <div class="col-md-12">
                            <button class="btn margin-r-5 btn-primary" type="button">
                              Confirm  
                            </button> 
                            <button class="btn margin-r-5 btn-link" type="button" v-on:click=" showmerge = 0;">
                                Cancel
                            </button> 
                        </div>
                      </div>  
                    </div>
                </div>
            

            </div>
         
             
        

            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <i class="fa  fa-list-alt"></i>
                        <h3 class="box-title">Message List</h3>
                        <div class="pull-right">
                            <label class="incoming-ques"><input type="checkbox" class="form-check-input mright-5">Incoming Questions</label>
                            <label class="agent-prop-ans"><input type="checkbox" class="form-check-input mright-5"> Agent Proposed Answers</label>
                        </div>    
                    </div>
                    <div class="box-body">
                        <ManageMessageTableList></ManageMessageTableList>
                    </div>
                </div>
            </div>
          </div>

         
        </div>			
    </div>
  </section>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
// @ is an alias to /src
import Breadcrumb from '../components/Breadcrumb.vue';
import MassUpdateButtons from '../components/MassUpdateButtons.vue';
import MassUpdateBody from '../components/MassUpdateBody.vue';
import ManageMessageTableList from '../components/ManageMessageTableList.vue';
import Multiselect from '@vueform/multiselect'
import Toggle from '@vueform/toggle'
export default {
  name: 'Manage Messages',
  data() {
      return{
          showmore : false,
          segment1 : true,
          menuCollapse : true,
          showmytopic : false,
          alertmessage : true,
          isHidden : false,
          mergestep : 1,
          showmerge : 0
      }
  },
  components: {
    Breadcrumb,
    MassUpdateButtons,
    MassUpdateBody,
    ManageMessageTableList,
    Toggle,
    Multiselect
  },
  ready () {
    $(document).ready(function ($) {
    })
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    },
    showMessage() {
        this.isHidden = true;
        setTimeout(function() {
           this.isHidden = false;
        }.bind(this), 3000);
     }
    },
    beforeMount(){
        this.showMessage();
    },
    mounted(){
        this.emitter.on("showmerge", Data => {
            this.showmerge = 1;
        });
    }
}
</script>
