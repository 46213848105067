<template>
    <div class="notdatatable form-inline mbottom-15">
       <div class="table-controls table-columns">
          <div class="btn-group"><button class="btn btn-default" type="button"><span>Excel</span></button><button class="btn btn-default button-columns" type="button"><span>Column visibility</span></button></div>
       </div>
       <div class="table-controls table-pagesize table-columns">
          <label>
             Show 
             <select class="form-control input-xs">
                <option>5</option>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>250</option>
                <option>500</option>
                <option>1000</option>
             </select>
             entries 
          </label>
       </div>
       <div class="table-controls table-filter">Filter SMS <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
    </div>
    <table class="table table-striped">
       <thead>
          <tr role="row">
            <th><input type="checkbox" class="form-check-input"></th>
            <th class="sorting sorting_asc">
               Message
            </th>
            <th class="sorting">
                Contact Name
            </th>
            <th class="sorting">
                Phone Name
            </th>
             <th class="sorting">
                Time
            </th>
            <th class="sorting">
                Assigned To
            </th>
            <th class="sorting">
                Topic
            </th>
           
          </tr>
       </thead>
        
         <tbody>
         <tr role="row">
            <td name="name" class="col-icon-check sorting_1">
                <input type="checkbox" class="form-check-input">
                <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded1 = !showexpanded1">
                    <i class="fa fa-plus-circle" v-if="!showexpanded1" v-show="!showexpanded1"></i>
                    <i class="fa fa-minus-circle" v-if="showexpanded1" v-show="showexpanded1"></i>
                </button>
            </td>
            <td>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard...  
            </td>
             <td>
                <span>
                    <a href="">John Doe</a>
                </span>
            </td>
            <td>
              0000000000
            </td>
            <td>
                5 min ago     
            </td>
            <td>
                Jhon Doe
            </td>
            <td>
                All Topic
            </td>
           
          </tr>
            <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded1">
                <td class="white-bg">&nbsp;</td>
                <td colspan="6">
                    <SmsWindow></SmsWindow>
                </td>
            </tr>
            <tr role="row">
                    <td name="name" class="col-icon-check sorting_1">
                     <input type="checkbox" class="form-check-input">
                        <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded2 = !showexpanded2">
                            <i class="fa fa-plus-circle" v-if="!showexpanded2" v-show="!showexpanded2"></i>
                            <i class="fa fa-minus-circle" v-if="showexpanded2" v-show="showexpanded2"></i>
                        </button>
                    </td>
                    <td>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard...
                        
                    </td>
                    <td>
                        <a href="">Julie Persuavia</a>
                    </td>
            
                    <td>
                       0000000000
                    </td>
                    <td>
                        10 min ago 
                    </td>
                    <td>
                      Jhon Doe
                    </td>
                    <td>
                      All Topic
                    </td>
            </tr>
            <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded2">
                <td class="white-bg">&nbsp;</td>
                 <td colspan="6">
                    <SmsWindow></SmsWindow>
                </td>
            </tr>
            
       </tbody>
    </table>
    <div id="Main_MyDashboard_Recent_TableInfo">
       <div class="notdatatable table-info">Showing 1 to 3 of 3 entries</div>
       <div class="dataTables_paginate notdatatable table-pagination text-end">
          <ul class="pagination">
             <li><a href="">Previous</a></li>
             <li class="active"><a href="">1</a></li>
    
             <li><a href="">Next</a></li>
          </ul>
       </div>
    </div>
    </template>
    
    <script>
    
    import SmsWindow from '../components/SmsWindow.vue';
    
    
    import Multiselect from '@vueform/multiselect'
    
        export default {
          name: 'Chat List',
          props: {replyType:String,showGetThis: Boolean, statusBtnText:String, statusBtnClass:String, showstatusBtnIcon:Boolean,statusBtnIconClass:String, typeBtnText:String, typeBtnClass:String, showtypeBtnIcon:Boolean, typeBtnIconClass:String,typeBtnIconClassGroup:String,typeGroupIcon:Boolean},
          components: {
            Multiselect,
            SmsWindow,
          },
          data: function(){
              return{
                showBCC : true,
                date: new Date(),
                timezone: '',
                showexpanded1: false,
                viewmodecontent : true,
                createnotecontent : false,
                agentemailcontent : false,
                createticketcontent : false,
                forwardcontent  : false,
                getthiscontent : false,
                replyType : this.replyType,
                showGetThis : this.showGetThis,
                statusbtntext:this.statusBtnText, 
                statusbtnclass:this.statusBtnClass, 
                showstatusbtnicon:this.showstatusBtnIcon,
                statusbtniconclass:this.statusBtnIconClass, 
                typebtntext:this.typeBtnText, 
                typebtnclass:this.typeBtnClass,
                showtypebtnicon:this.showtypeBtnIcon, 
                typebtniconclass:this.typeBtnIconClass,
                typebtniconclassgroup: this.typeBtnIconClassGroup,
                typegroupicon : this.typeGroupIcon,
                showexpanded2 : false,
                showexpanded3 : false
              }
          },
          mounted(){
               
               this.emitter.on("show-view-details", Data => {
                  this[Data.displaySegment] = true;
                  this.viewmodecontent = false;
                });
                this.emitter.on("hide-view-details", Data => {
                  this[Data.displaySegment] = false;
                  this.viewmodecontent = true;
                });
          },
            methods: {
                toggleSegmentCollapsedState(i) {
                this['segment'+i] = !this['segment'+i] 
                }
            }
        }
    </script>