<template>
 <BreadcrumbContact page-title="Contact" name="Albert Metricman" page-section="Contact"> </BreadcrumbContact>
  <section class="content">
      <ContactSummaryTabs></ContactSummaryTabs>
  </section>
</template>

<script>
// @ is an alias to /src
import BreadcrumbContact from '../components/BreadcrumbContact.vue';
import ContactSummaryTabs from '../components/ContactSummaryTabs.vue';

export default {
  name: 'New Account',
  components: {
    BreadcrumbContact,
    ContactSummaryTabs
  },
  ready () {
    $(document).ready(function ($) {
    })
  }
}
</script>
