<template>
<div class="row" v-if="showOption == '' && quick == false">
   <div class="col-md-5">
      <div class="box box-info">
         <div class="box-header with-border">
            <i class="fa fa-user"></i>
            <h3 class="box-title">Contact Information</h3>
         </div>
         <div class="box-body">
            <div class="form-horizontal user-detail">
               <div class="row">
                  <div class="col-md-4">
                     <div class="user-initials round no-select">AM</div>
                  </div>
                  <div class="col-md-8 user-name">Albert Metricman</div>
               </div>
               <div class="datarow row form-group">
                  <label class="col-md-4 col-12 control-label">Job Title</label>
                  <div class="col-md-8">VP Operations</div>
               </div>
               <div class="datarow row form-group">
                  <label class="col-md-4 control-label">Company</label>
                  <div class="col-md-8">Example Company, Inc.</div>
               </div>
               <div class="datarow row form-group">
                  <label class="col-md-4 control-label">Email
                  </label>
                  <div class="col-md-8">  <span uib-tooltip="This email address is marked as bounced" tooltip-placement="bottom">
                     <i class="fa fa-ban text-red"></i>
                     </span> albert.metricman@example.com
                  </div>
               </div>
               <div class="datarow row form-group">
                  <label class="col-md-4 control-label">Phone</label>
                  <div class="col-md-8">312-555-5555</div>
               </div>
            </div>
         </div>
      </div>
      <div class="box box-warning">
         <div class="box-header with-border">
            <i class="fa fa-flash"></i>
            <h3 class="box-title">Quick Actions <i class="fa fa-question-circle text-info help-icon" popover-placement="auto bottom-left" uib-popover-template="'quickActionPopover.html'" type="button" popover-trigger="outsideClick"></i></h3>
         </div>
         <div class="box-body">
            <nav>
               <div class="row">
                  <div class="col-3 text-center">
                     <a href="javascript:void(0)" v-on:click="showQuickAgentEmail('agentemail')">
                     <i class="fa fa-envelope bg-blue"></i>
                      Agent Email
                     </a>
                  </div>
                  <div class="col-3 text-center">
                     <a>
                     <i class="fa fa-file-text-o bg-success"></i>
                     Note
                     </a>
                  </div>
                  <div class="col-3 text-center">
                     <a v-on:click="showEmailAlert = true">
                     <i class="fa fa-ticket bg-orange"></i>
                     Ticket
                     </a>
                  </div>
                  <div class="col-3 text-center">
                     <a href="javascript:void(0)" v-on:click="showQuickActions('payments');">
                     <i class="fa fa-dollar bg-teal"></i>
                      Payment
                     </a>
                  </div>
               </div>
            </nav>
         </div>
      </div>
      <div class="form-alert-message" v-show="showEmailAlert">
         <div class="alert alert-info alert-dismissible">
            <button type="button" class="close" v-on:click="showEmailAlert = false">×</button>
            <div class="message-container">
               <div class="icon"><i class="icon fa fa-info-circle"></i></div>
               <div class="message">
                  <strong>Contact does not have valid email address</strong>
                  <div> This contact does not have a valid email address You tried to create an email to this contact, but they don't have a valid address. Update the contact's details with valid email address and try again. </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="col-md-7">
      <div class="box box-success">
         <div class="box-header with-border">
            <i class="fa fa-info"></i>
            <h3 class="box-title">Status Information 
              <Popper arrow>
                <i class="fa fa-question-circle text-info help-icon"></i>
                  <template #content>
                    <div class="tooltiptext-container">
                       This section provides a summary of your interactions with this contact. Click the Last Agent link to see the last comments made to the customer. Click an unresolved question or the View all unresolved questions button to see details.
                    </div>
                  </template>
               </Popper>
            </h3>
         </div>
         <div class="box-body right-container">
            <div class="equal-container">
               <div class="row mbottom-15">
                  <div class="col-4 text-center report-counts">
                     <h3 class="text-green"><a href="" class="text-orange">6</a></h3>
                     <span><a href="" class="text-primary">Questions Past Week </a></span>
                  </div>
                  <div class="col-4 text-center report-counts">
                     <h3 class="text-red"><a href="" class="text-red">1</a></h3>
                     <span><a href="" class="text-primary">Unassigned Questions</a></span>
                  </div>
                  <div class="col-4 text-center report-counts">
                     <h3 class="text-green"><a href="" class="text-green">3</a></h3>
                     <span><a href="" class="text-primary">Assigned Questions </a></span>
                  </div>
               </div>
               <div class="row">
                  <div class="col-12 text-left report-counts">
                     <h3 class="text-blue"><span>Last Agent </span><a href="" class="text-primary">Jacob.smith@1to1service.com</a></h3>
                  </div>
               </div>
               <div class="clearfix"></div>
               <h3>Unresolved Questions <span class="text-muted">(5)</span></h3>
               <ul class="empty-list">
                  <li>
                     <a href="" target="_blank">
                     Need a shipment quote for a full truckload
                     </a>
                     <div class="message-time">2 days ago</div>
                  </li>
                  <li>
                     <a href="" target=" _blank">
                     Trying to track the status of a shipment
                     </a>
                     <div class="message-time">2 days ago</div>
                  </li>
               </ul>
               <div class="view-more">
                  <a class="btn btn-primary btn-xs pull-right">View all unresolved questions</a>
               </div>
            </div>
         </div>
      </div>
      <div class="box box-warning">
         <div class="box-header with-border">
            <i class="fa fa-history"></i>
            <h3 class="box-title"> Recent History </h3>
         </div>
         <div class="box-body">
            <div class="equal-container">
               <div class="qa-message-list">
                  <i class="fa fa-envelope bg-blue"></i>
                  <div class="message-item">
                     <div class="message-inner">
                        <span class="time"><i class="fa fa-clock-o"></i> Just Now</span>
                        <span class="time">
                        <i class="fa fa-user-o"></i>
                        Jacob Smith
                        </span>
                        <div class="message-header">Chat</div>
                        I need help picking a camera
                     </div>
                  </div>
               </div>
               <div class="qa-message-list">
                  <i class="fa fa-ticket bg-orange"></i>
                  <div class="message-item">
                     <div class="message-inner">
                        <span class="time"><i class="fa fa-clock-o"></i> 2 days ago</span>
                        <span class="time">
                        <i class="fa fa-user-o"></i>
                        Alek Packard
                        </span>
                        <div class="message-header">Agent Ticket</div>
                        Best price on LG Plasma
                     </div>
                  </div>
               </div>
               <div class="view-more">
                  <a href="#tab_4" class="btn btn-primary btn-xs pull-right" data-toggle="tab" aria-expanded="true">View all history</a>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<div v-if="showOption == 'agentemail'">
   <AgentEmailContent></AgentEmailContent>
</div>


<div v-if="createnote">

</div>

<div v-if="createticket">

</div>

<div v-if="showOption == 'payments'">
   <div class="row">
      <div class="col-md-12 form-action" v-if="paymentSuccess == false">
         <div class="overlay-wrapper">
               <div class="row" id="Main_ManageMessages_Details56675_Payment">
                  <div class="col-md-6 form-horizontal">
                     <div class="form-group">
                        <label class="col-4 control-label text-left"> 
                                <Toggle v-model="showemailreceive"/> Send Receipt
                         </label>
                         <div class="col-6" v-if="showemailreceive">
                               <Multiselect
                                v-model="value"
                                :closeOnSelect="false"
                                placeholder="--Select Email--"
                                :options="{
                                    email1: 'Jhon (Jhon@example.com)',
                                }"
                                />
                            </div>
                     </div>
                     <!--<div class="form-group">
                        <label class="col-2 control-label text-left">Select Topic</label>
                        <div class="col-8">
                           <Multiselect
                           v-model="value"
                           placeholder="-Select Topic-"
                           label="name"
                           :groups="true"
                           :searchable="true"
                           :options="[
                              {
                              label: '_iService',
                              options:  [
                                    { value: 'All Topic', name: 'All Topic',level:'1'},
                                    { value: 'Feedback', name: 'Feedback',level:'2'},
                                    
                              ],
                              }
                           ]"
                           >
                           <template v-slot:option="{ option }">
                           <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                           </template>
                        </Multiselect>
                        </div>
                     </div>-->
                     <div class="form-group">
                           <label class="col-2 control-label text-left">Reference Number</label>
                           <div class="col-8"><input class="form-control" type="number"></div>
                     </div>
                     <div class="form-group">
                           <label class="col-2 control-label text-left">Amount</label>
                           <div class="col-8"><input class="form-control" type="number"></div>
                     </div>
                     <div class="form-group">
                           <label class="col-2 control-label text-left">Address</label>
                           <div class="col-8"><input class="form-control" type="text"></div>
                     </div>
                     <div class="form-group">
                           <label class="col-2 control-label text-left">Postal Code</label>
                           <div class="col-8"><input class="form-control" type="number"></div>
                     </div>
                  </div>
                  <div class="col-md-4 form-horizontal" style="display: none;">
                     <div id="credit-card"></div>
                  </div>
                  <!--v-if-->
               </div>
               <div class="overlay" style="display: none;"><i class="fa fa-refresh fa-spin"></i></div>
         </div>
         <div class="row">
         <div class="col-md-6 col-lg-5 form-horizontal" style="">
               
               <div id="credit-card" class=" secure-payment-form">
                  <div class="sandbox-warning" style="display: block; width: 100%; margin-bottom: 5px; color: rgb(255, 255, 255); background-color: rgb(119, 0, 0); padding: 8px 5px; font-family: Verdana; font-weight: 100; font-size: 12px; text-align: center; box-sizing: border-box;">This page is currently in sandbox/test mode. Do not use real/active card numbers.</div>
                  <div class="credit-card-card-number">
                     <label>Card Number</label>
                     <div class="credit-card-card-number-target">
                           <div role="region" id="secure-payment-field-wrapper">
                              <img class="card-number-icon" aria-disabled="false" alt="Generic Card" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" onerror="this.onerror=null; this.src=&quot;https://js.globalpay.com/v1/images/gp-cc-generic.svg&quot;">
                              <input type="tel" id="secure-payment-field" class="card-number" data-id="ODJlNGUyY2EtMDk0NS1mMTBkLTJkMTctMGE0ZmVhOWNlODdl" autocomplete="cc-number" data-prev="0" name="cardNumber" aria-label="Card Number"> 
                           </div>
                     </div>
                  </div>
                  <div class="credit-card-card-expiration">
                     <label>Card Expiration</label>
                     <div class="credit-card-card-expiration-target">
                           <input type="text" id="secure-payment-field" class="card-holder-name" data-id="NmI1ZDhiMTYtNzg1MS04YTY0LWVkNjEtZjAzZWZiNzQ0Yzcz" autocomplete="cc-name" aria-label="Card Holder Name">
                     </div>
                  </div>
                  <div class="credit-card-card-cvv">
                     <label>Card CVV</label>
                     <div class="credit-card-card-cvv-target">
                           <input type="text" id="secure-payment-field" class="card-holder-name" data-id="NmI1ZDhiMTYtNzg1MS04YTY0LWVkNjEtZjAzZWZiNzQ0Yzcz" autocomplete="cc-name" aria-label="Card Holder Name">
                     </div>
                  </div>
                  <div class="credit-card-card-holder-name">
                     <label>Card Holder Name</label>
                     <div class="credit-card-card-cvv-target">
                           <input type="text" id="secure-payment-field" class="card-holder-name" data-id="NmI1ZDhiMTYtNzg1MS04YTY0LWVkNjEtZjAzZWZiNzQ0Yzcz" autocomplete="cc-name" aria-label="Card Holder Name">
                     </div>
                  </div>
                  <div class="credit-card-submit mt-3">
                     <div class="credit-card-submit-target">

                           <button v-on:click="submitPayment()"  type="button" id="secure-payment-field" data-id="ZDQyY2E2MzItMGVkNy0xZWVkLWVlMmItYmYxYmUyYzY2OTA0" class="btn btn-success submit">Submit</button>
                     </div>
                  </div>
                  <div class="credit-card-shield">
                     <div class="ssl-text-logo"><img src="https://js.globalpay.com/v1/images/ssl_logo_ico.svg" alt="256-bit SSL encrypted logo" class="ssl-logo_ico"><span class="ssl-msg">256-bit SSL<br>encrypted</span></div>
                  </div>
                  <div class="credit-card-logo"><span class="security-msg">Securely processed by<br>Global Payments</span><img src="https://js.globalpay.com/v1/images/realex-grey.png" alt="Secured by Global Payments"></div>
               </div>
               <div class="row mb-3">
                  <div class="col-md-12">
                     <button class="btn btn-default ng-scope" v-on:click="cancelQuickActions('')">Cancel Payment</button>   
                  </div>
               </div>
               <div class="form-alert-message"><div class="alert alert-danger alert-dismissible" id=""><button type="button" class="close">×</button><div class="message-container"><div class="icon"><i class="icon fa fa-ban"></i></div><div class="message"><div>Incorrect card details.</div></div></div></div></div>
         </div>
         </div>
      </div>

      <div class="col-md-12" v-if="paymentSuccess == true">
        
        <div class="box">
          <div class="box-body print-receipt">
            <div class="row">
               <div class="col-md-12 mb-2">
                  <button class="btn btn-default ng-scope" v-on:click="cancelQuickActions('')">Close</button>   
               </div>

                <div class="col-md-12 mbottom-15 form-alert-message">
                    <div class="alert alert-success alert-dismissible">
                        <button type="button" class="close">×</button>
                        <div class="message-container">
                        <div class="icon">
                            <i class="icon fa fa-ban"></i>
                        </div>
                        <div class="message">
                            <strong>Thank You!</strong> Your payment has been made.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
              <div class="col-md-6">
                 <table class="table">
                     <tr> 
                        <td><strong>Reference Number</strong></td>
                        <td>#9898989</td>
                    </tr>
                    <tr> 
                        <td><strong>Date of Payment</strong></td>
                        <td>7/14/23</td>
                    </tr>
                    <tr> 
                        <td><strong>Amount Paid</strong></td>
                        <td>$200.45</td>
                    </tr>
                    <tr> 
                        <td><strong>Payment Method</strong></td>
                        <td>Visa (####-####-####-8909)</td>
                    </tr>
                    <tr> 
                        <td><strong>Confirmation Number</strong></td>
                        <td>09809809</td>
                    </tr>
                   
                 </table>
              </div>
              <div class="col-md-6">
                <a href="" class="pull-right"><i class="fa fa-print"></i> Print Confirmation Page</a>
              </div>
              
             
            </div>
          </div> 
        </div> 
      </div>
   </div>
</div>


</template>

<script>
import Popper from "vue3-popper";
import AgentEmailContent from '../components/AgentEmailContent.vue';
import Multiselect from '@vueform/multiselect'
import Toggle from '@vueform/toggle'
export default {
  name: 'Contact Summary Info',
  components: {
     Toggle,
     Popper,
     AgentEmailContent,
     Multiselect
  },
 
  data: function(){
      return{
      showEmailAlert : false,
      quick : false,
      showOption : '',
      paymentSuccess : false,
      showemailreceive : true,
      value : 'email1'
          }
      },
  mounted(){ 
      
        this.emitter.on("hide-view-details", Data => {
         this.quick = false;
         this.showOption = '';
         
        });
  },
  methods:{
     cancelAlert(){
        showEmailAlert = false;
     },
     showQuickAgentEmail(quickActionOption){
         this.quick = false;
         this.showOption = quickActionOption;
     },
     showQuickActions(quickActionOption){
         this.quick = true;
         this.showOption = quickActionOption;    
     },
     cancelQuickActions(){
      this.quick = false;
      this.showOption = '';
      this.paymentSuccess = false;
     }, 
     submitPayment(){
             this.paymentSuccess = true;
          }
  }

}
</script>
