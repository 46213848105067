<script>
import { ConversationalForm } from 'conversational-form';

export default  {
  template: '<div class="myForm" id="myForm"></div>',
  styles: [`
    .myForm {
      position: relative;
      height: 100%;
      width: 100%;
    }
  `],
  methods: {
    setupForm: function () {
      const formFields = [
        {
          'tag': 'input',
          'type': 'radio',
          'name': 'option',
          'cf-label':'Technical Support',
          'cf-questions': 'Hi, This is our virtual assitance! Tell us how we can help.'
        },
        {
          'tag': 'input',
          'type': 'radio',
          'name': 'option',
          'cf-label':'Accounting'
        }
        ,
        {
          'tag': 'input',
          'type': 'radio',
          'name': 'option',
          'cf-label':'Accounting'
        },
        {
          'tag': 'select',
          'name': 'topic',
          "cf-questions": "Select Topic / Department",
          "cf-input-placeholder": "Select Topic / Department",
          'isMultiChoice':false,
          "children":[
            {
                "tag": "option",
                "cf-label": "All Topic",
                "value": "All Topic"
            },
            {
                "tag": "option",
                "cf-label": "Sales",
                "value": "Sales"
            },
            {
                "tag": "option",
                "cf-label": "Request a Quoute",
                "value": "Request a Quoute"
            }
          ]
        },
        {
          'tag': 'input',
          'type': 'text',
          'name': 'firstname',
          'cf-questions': 'What is your firstname?'
        },
        {
          'tag': 'input',
          'type': 'text',
          'name': 'lastname',
          'cf-questions': 'What is your lastname?'
        },
        {
          'tag': 'input',
          'type': 'email',
          'name': 'email',
          'cf-questions': 'Please enter your email'
        },
        {
          'tag': 'input',
          'type': 'text',
          'name': 'subject',
          'cf-questions': 'Please provide subject to your issue.'
        },
        {
          'tag': 'input',
          'type': 'text',
          'name': 'subject',
          'cf-questions': 'Provide short description of your question.'
        }
      ];

      this.cf = ConversationalForm.startTheConversation({
        options: {
          submitCallback: this.submitCallback,
          preventAutoFocus: false,
          theme:'light',
          preventAutoAppend:false,
          position:'relative',
          context:document.getElementById('myForm')
        },
        tags: formFields
      });
     
    },
    submitCallback: function () {
      var formDataSerialized = this.cf.getFormData(true);
      console.log("Formdata, obj:", formDataSerialized);
      this.cf.addRobotChatResponse("You are done. Your ticket is generated.")
    }
  },
  mounted: function () {
    this.setupForm()
  },
}
</script>
