<template>
  <Breadcrumb page-title="My Draft Messages " page-section="My Work Queues"> </Breadcrumb>
  <section class="content">
      <div class="row">
          <div class="col-md-12">
             <div class="box box-info">
                <div class="box-header with-border">
                    <i class="fa fa-file-text-o"></i>
                    <h3 class="box-title">Topic : Home Audio Quotes</h3>
                    <span aria-hidden="true" class="glyphicon pull-right glyphicon-chevron-right glyphicon-chevron-right__expanded" ng-class="{'glyphicon-chevron-right__expanded': !isCollapsedForm}" ng-init="isCollapsedForm = false;" style=""></span>
                </div>
                <div class="box-body">
                  <OpenQuouteList></OpenQuouteList>
                </div>
             </div>
          </div>
      </div>
  </section>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import OpenQuouteList from '../components/OpenQuouteList.vue';
export default {
  name: 'MyDraftMessagesComponenets',
   components: {
    Breadcrumb,
    OpenQuouteList
  }
}
</script>