<template>
     <div class="col-md-12 form-action" v-if="paymentSuccess == false">
        <div class="overlay-wrapper">
            <!--v-if-->
           
            <div class="row" id="Main_ManageMessages_Details56675_Payment">
                <div class="col-md-8 col-lg-6 form-horizontal">
                    <div class="form-group">
                        <label class="col-3 control-label text-left">Amount</label>
                        <div class="col-9"><input class="form-control" type="number"></div>
                    </div>
                    <div class="form-group">
                        <label class="col-3 control-label text-left">Address</label>
                        <div class="col-9"><input class="form-control" type="text"></div>
                    </div>
                    <div class="form-group">
                        <label class="col-3 control-label text-left">Postal Code</label>
                        <div class="col-9"><input class="form-control" type="number"></div>
                    </div>
                </div>
                <div class="col-md-4 form-horizontal" style="display: none;">
                    <div id="credit-card"></div>
                </div>
                <!--v-if-->
            </div>
            <div class="overlay" style="display: none;"><i class="fa fa-refresh fa-spin"></i></div>
        </div>
        <div class="row">
        <div class="col-md-8 col-lg-6 form-horizontal" style="">
            
            <div id="credit-card" class=" secure-payment-form">
                <div class="sandbox-warning" style="display: block; width: 100%; margin-bottom: 5px; color: rgb(255, 255, 255); background-color: rgb(119, 0, 0); padding: 8px 5px; font-family: Verdana; font-weight: 100; font-size: 12px; text-align: center; box-sizing: border-box;">This page is currently in sandbox/test mode. Do not use real/active card numbers.</div>
                <div class="credit-card-card-number">
                    <label>Card Number</label>
                    <div class="credit-card-card-number-target">
                        <div role="region" id="secure-payment-field-wrapper">
                            <img class="card-number-icon" aria-disabled="false" alt="Generic Card" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" onerror="this.onerror=null; this.src=&quot;https://js.globalpay.com/v1/images/gp-cc-generic.svg&quot;">
                            <input type="tel" id="secure-payment-field" class="card-number" data-id="ODJlNGUyY2EtMDk0NS1mMTBkLTJkMTctMGE0ZmVhOWNlODdl" autocomplete="cc-number" data-prev="0" name="cardNumber" aria-label="Card Number"> 
                        </div>
                    </div>
                </div>
                <div class="credit-card-card-expiration">
                    <label>Card Expiration</label>
                    <div class="credit-card-card-expiration-target">
                        <input type="text" id="secure-payment-field" class="card-holder-name" data-id="NmI1ZDhiMTYtNzg1MS04YTY0LWVkNjEtZjAzZWZiNzQ0Yzcz" autocomplete="cc-name" aria-label="Card Holder Name">
                    </div>
                </div>
                <div class="credit-card-card-cvv">
                    <label>Card CVV</label>
                    <div class="credit-card-card-cvv-target">
                        <input type="text" id="secure-payment-field" class="card-holder-name" data-id="NmI1ZDhiMTYtNzg1MS04YTY0LWVkNjEtZjAzZWZiNzQ0Yzcz" autocomplete="cc-name" aria-label="Card Holder Name">
                    </div>
                </div>
                <div class="credit-card-card-holder-name">
                    <label>Card Holder Name</label>
                    <div class="credit-card-card-cvv-target">
                        <input type="text" id="secure-payment-field" class="card-holder-name" data-id="NmI1ZDhiMTYtNzg1MS04YTY0LWVkNjEtZjAzZWZiNzQ0Yzcz" autocomplete="cc-name" aria-label="Card Holder Name">
                    </div>
                </div>
                <div class="credit-card-submit mt-3">
                    <div class="credit-card-submit-target">

                        <button v-on:click="submitPayment()" type="button" id="secure-payment-field" data-id="ZDQyY2E2MzItMGVkNy0xZWVkLWVlMmItYmYxYmUyYzY2OTA0" class="btn btn-success submit">Submit</button>
                    </div>
                </div>
                <div class="credit-card-shield">
                    <div class="ssl-text-logo"><img src="https://js.globalpay.com/v1/images/ssl_logo_ico.svg" alt="256-bit SSL encrypted logo" class="ssl-logo_ico"><span class="ssl-msg">256-bit SSL<br>encrypted</span></div>
                </div>
                <div class="credit-card-logo"><span class="security-msg">Securely processed by<br>Global Payments</span><img src="https://js.globalpay.com/v1/images/realex-grey.png" alt="Secured by Global Payments"></div>
            </div>
            <div class="row mb-3">
                <div class="col-md-12">
                    <button class="btn btn-default ng-scope" v-on:click="cancel('paymentcontent')">Cancel Payment</button>   
                </div>
            </div>
            <div class="form-alert-message"><div class="alert alert-danger alert-dismissible" id=""><button type="button" class="close">×</button><div class="message-container"><div class="icon"><i class="icon fa fa-ban"></i></div><div class="message"><div>Incorrect card details.</div></div></div></div></div>
        </div>
        </div>
    </div>

    <div class="col-md-12" v-if="paymentSuccess == true">
        <div class="box">
          <div class="box-body print-receipt">
            <div class="row">
                
                <div class="col-md-12 mbottom-15 form-alert-message">
                    <button class="btn btn-default mbottom-15" v-on:click="cancel('paymentcontent')">Close</button>
                    
                    <div class="alert alert-success alert-dismissible">
                        <button type="button" class="close">×</button>
                        <div class="message-container">
                        <div class="icon">
                            <i class="icon fa fa-ban"></i>
                        </div>
                        <div class="message">
                            <strong>Thank You!</strong> Your payment has been made.
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
              <div class="col-md-8">
                <table class="table">
                     <tr> 
                        <td><strong>Reference Number</strong></td>
                        <td>#9898989</td>
                    </tr>
                    <tr> 
                        <td><strong>Date of Payment</strong></td>
                        <td>7/14/23</td>
                    </tr>
                    <tr> 
                        <td><strong>Amount Paid</strong></td>
                        <td>$200.45</td>
                    </tr>
                    <tr> 
                        <td><strong>Payment Method</strong></td>
                        <td>Visa (####-####-####-8909)</td>
                    </tr>
                    <tr> 
                        <td><strong>Confirmation Number</strong></td>
                        <td>09809809</td>
                    </tr>
                   
                 </table>
              </div>
              <div class="col-md-4">
                <a href="" class="pull-right"><i class="fa fa-print"></i> Print Receipt</a>
              </div>
              
            </div>
          </div> 
        </div> 
      </div>  
</template>
    
    <script>
    import ActionModeButtons from '../components/ActionModeButtons.vue';
    import EmailAddressSubjectResponse from '../components/EmailAddressSubjectResponse.vue';
    import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
    import DraftBodyTabs from '../components/DraftBodyTabs.vue';
    
    export default {
      name: 'Payment',
       components: {
        
      },
      data: function(){
          return{
            paymentSuccess : false
         
          }
      },
      methods: {
          submitPayment(){
             this.paymentSuccess = true;
          },
          cancel(segment){
              this.emitter.emit("hide-view-details",{displaySegment:segment});
            }
      }
    
     
    }
    </script>