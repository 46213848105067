<template>
  <div class="expandedrowcontent box-bg-color" v-if="showDetails">
    <div class="box box-info with-border">
      <div class="box-header  with-border">
        <h3 class="box-title head-title-padding">Create Ticket</h3>
      </div>
      <div class="box-body table-responsive">
         <div class="form-group">
            <button class="btn btn-primary" title="Create Ticket">
            <i class="fa fa-ticket"></i>
            Create Ticket
            </button>
            <button class="btn btn-primary" title="Save Draft">
                <i class="fa fa-save"></i>
                Save Draft
            </button> 
            <button class="btn btn-link ng-scope" ng-click="cancel('createticket')">Cancel</button>
        </div>
         <div class="nav-tabs-custom color-tab-icon">
           <div class="row" v-if="message">
                        <div class="col-md-12 form-action">
                           <div class="row">
                              <div class="col-md-12">
                                  <TicketDetails tabOption="3"></TicketDetails>
                              </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-12" > 
                                   <DraftBodyTabs></DraftBodyTabs>
                                </div>
                          </div>
                          <div class="row">
                               <div class="col-md-12">
                                 <div class="panel panel-default form-group">
                                      <div class="panel-heading">
                                      <i class="fa fa-fw fa-pencil-square-o"></i>
                                          Private Comments
                                      </div>
                                      <div class="panel-body">
                                          <textarea class="full-width full-height"></textarea>
                                      </div>
                                  </div>	
                               </div>
                          </div>



                        </div>
                      </div>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionModeButtons from '../components/ActionModeButtons.vue';
import TicketDetails from '../components/TicketDetails.vue';
import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';

import QuoteButtons from '../components/QuoteButtons.vue';
import QuoteResponseBody from '../components/QuoteResponseBody.vue';
import QuoteQuestionDetailsTab from '../components/QuoteQuestionDetailsTab.vue';
import ContactInformation from '../components/ContactInformation.vue';

import DraftBodyTabs from '../components/DraftBodyTabs.vue';

import HistoryTabContent from '../components/HistoryTabContent.vue';



export default {
  name: 'DraftMessageDetailsComponents',
   components: {
      ActionModeButtons,
      TicketDetails,
      VariableAndStockResponses,
      QuoteButtons,
      QuoteResponseBody,
      QuoteQuestionDetailsTab,
      ContactInformation,
      DraftBodyTabs,
      HistoryTabContent
  },
  data: function(){
      return{
       quote:true,
       message:false,
       showDetails :false
      }
  },
  mounted() { 
    this.emitter.on("show-message-details", Data => {
      this.showDetails = Data.showDetails;
      if(Data.displayType != 'quote'){
          this.quote = false;
          this.message = true;
      }else{
          this.quote = true;
          this.message = false;
      }
    });
  }
 
}
</script>