<template>
 <Breadcrumb page-title="My Dashboard"> </Breadcrumb>
  <section class="content">
     <div class="row">
       <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border"><i class="fa fa-exchange"></i> <h3 class="box-title head-title-padding">My Recent Interactions</h3></div>
          <div class="box-body table-responsive">
            <div class="notdatatable form-inline mbottom-15">
              <div class="table-controls table-columns">
                <div class="btn-group">
                  <button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div></div><div class="table-controls table-pagesize table-columns"><label> Show <select class="form-control input-xs"><option>5</option><option>10</option><option>25</option><option>50</option><option>100</option><option>250</option><option>500</option><option>1000</option></select> entries </label></div>
                  <div class="table-controls table-pagesize"><label>Show past <input type="text" class="form-control input-xs" style="width: 50px;"> days</label></div>
                  <div class="table-controls table-filter">Filter Inbox <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div></div>
                <table class="table table-striped table-hover">
                  <thead>
                      <tr id="row1">
                        <th class="no-sort"  name="name" width="30"></th>
                        <th class="text-capitalize sorting"> Interaction Type </th>
                        <th class="text-capitalize sorting"> Subject </th>
                        <th class="text-capitalize sorting"> Email </th>
                        <th class="text-capitalize sorting"> Date </th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr role="row" class="color-normal">
                      <td name="name" class="col-icon-check sorting_1">
                          <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded1 = !showexpanded1">
                              <i class="fa fa-plus-circle" v-if="!showexpanded1" v-show="!showexpanded1"></i>
                              <i class="fa fa-minus-circle" v-if="showexpanded1" v-show="showexpanded1"></i>
                          </button>
                      </td>
                      <td>
                          <span>
                            <small class="label label-with-icon bg-green">
                              <i class="fa icon-c icon-agent-email-3"></i>
                              <span> Agent Email </span>
                              </small>
                          </span>
                      </td>
                      <td>Re: [ref#: 30569-​30574] Ticket Subject</td>
                      <td>
                          <a href="">landlord@​qavmmail02​.​1to1servic​e.​com</a>
                      </td>
                    <td>2022-01-21 05:07:37 AM</td>
                    </tr>
                    <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded1">
                        <td class="white-bg">&nbsp;</td>
                        <td colspan="5">
                            <ViewModeContent v-if="viewmodecontent" showGetThis = "0"></ViewModeContent>

                            <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                            <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                            <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                            <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                            <GetThisContent v-if="getthiscontent"></GetThisContent> 
                        </td> 
                    </tr>
                    <tr role="row" class="color-warning">
                      <td name="name" class="col-icon-check sorting_1">
                          <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded2 = !showexpanded2">
                              <i class="fa fa-plus-circle" v-if="!showexpanded2" v-show="!showexpanded2"></i>
                              <i class="fa fa-minus-circle" v-if="showexpanded2" v-show="showexpanded2"></i>
                          </button>
                      </td>    
                      <td>
                          <span>
                            <small class="label label-with-icon bg-green">
                              <i class="fa icon-c icon-agent-email-3"></i>
                              <span> Agent Email </span>
                              </small>
                          </span>
                      </td>
                      <td>Re: [ref#: 30569-​30574] Ticket Subject</td>
                      <td>
                          <a href="">landlord@​qavmmail02​.​1to1servic​e.​com</a>
                      </td>
                      <td>2022-01-21 05:07:37 AM</td>   
                    </tr>
                    <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded2">
                        <td class="white-bg">&nbsp;</td>
                        <td colspan="5">
                            <ViewModeContent v-if="viewmodecontent" showGetThis = "0"></ViewModeContent>

                            <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                            <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                            <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                            <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                            <GetThisContent v-if="getthiscontent"></GetThisContent> 
                        </td> 
                    </tr>
                    <tr role="row" class="color-urgent">
                            <td name="name" class="col-icon-check sorting_1">

                                <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded3 = !showexpanded3">
                                    <i class="fa fa-plus-circle" v-if="!showexpanded3" v-show="!showexpanded3"></i>
                                    <i class="fa fa-minus-circle" v-if="showexpanded3" v-show="showexpanded3"></i>
                                </button>
                            </td>
                            <td>
                        <span>
                          <small class="label label-with-icon bg-green">
                             <i class="fa icon-c icon-agent-email-3"></i>
                             <span> Agent Email </span>
                             </small>
                        </span>
                    </td>
                     <td>Re: [ref#: 30569-​30574] Ticket Subject</td>
                    <td>
                        <a href="">landlord@​qavmmail02​.​1to1servic​e.​com</a>
                    </td>
                   <td>2022-01-21 05:07:37 AM</td>
                          
                    </tr>
                    <tr class="expandedrowcontent  bg-agent-reply" v-bind:class="replyType" v-if="showexpanded3">
                        <td class="white-bg">&nbsp;</td>
                        <td colspan="5">
                            <ViewModeContent v-if="viewmodecontent" showGetThis = "0" showAnswer = '0'></ViewModeContent>

                            <CreateNoteContent v-if="createnotecontent"></CreateNoteContent>

                            <AgentEmailContent v-if="agentemailcontent"></AgentEmailContent>

                            <CreateTicketContent v-if="createticketcontent"></CreateTicketContent>

                            <ForwardContent v-if="forwardccopyontent"></ForwardContent>

                            <GetThisContent v-if="getthiscontent"></GetThisContent> 
                        </td> 
                    </tr>
  
                    
                  </tbody>
                </table>
          </div>
        </div>
       </div>
     </div>
     <div class="row">
        <div class="col-lg-8 col-md-12">

          <div class="container-place-holder">
              <div class="box box-info">
                <div class="box-header with-border">
                    <i class="fa fa-search"></i>
                    <h3 class="box-title head-title-padding">Quick Searches</h3>
                </div>
                <div class="box-body table-responsive">
                  <table class="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="text-capitalize sorting">Search Name </th>
                        <th class="text-capitalize sorting"> Description </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-capitalize sorting">Recent interactions </td>
                        <td class="text-capitalize sorting"> Search list of recent interactions </td>
                      </tr>
                      <tr>
                        <td class="text-capitalize sorting">Treding topics </td>
                        <td class="text-capitalize sorting"> Search tredning topic list </td>
                      </tr>
                    </tbody>
                  </table>  
                </div>
              </div>
          </div>
          <div class="container-place-holder">
              <div class="box">
                <div class="box-header with-border">
                    <i class="fa fa-reply"></i>
                    <h3 class="box-title head-title-padding">My Answers This Week</h3>
                </div>
                <div class="box-body table-responsive">
                 <vue3-chart-js v-bind="{ ...barChart }" />
                  <div class="clearfix"></div>
                  <table id="example1" class="table table-striped table-hover dataTable no-footer" dt-options="dtineractionOptions">
                  <thead>
                      <tr role="row">
                        <th name="name" rowspan="1" colspan="1"></th>
                        <th name="name" rowspan="1" colspan="1" aria-label="">
                            06/13/2020
                        </th>
                        <th name="name" rowspan="1" colspan="1" aria-label="">
                            06/14/2020
                        </th>
                        <th name="name" rowspan="1" colspan="1" aria-label="">
                            06/15/2020
                        </th>
                        <th name="name" rowspan="1" colspan="1" aria-label="">
                            06/16/2020
                        </th>
                        <th name="name" rowspan="1" colspan="1" aria-label="">
                            06/17/2020
                        </th>
                        <th name="name" rowspan="1" colspan="1" aria-label="">
                            06/18/2020
                        </th>
                        <th name="name" rowspan="1" colspan="1" aria-label="">
                            06/19/2020
                        </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td width="150"><span class="legend-value"><span class="legend" style="background-color:#2672ec"></span> Private Note</span></td>
                        <td>10</td>
                        <td>15</td>
                        <td>10</td>
                        <td>90</td>
                        <td>10</td>
                        <td>5</td>
                        <td>10</td>
                      </tr>
                      <tr>
                        <td width="150"> <span class="legend-value"><span class="legend" style="background-color:#4bacc6"></span> Public Note</span></td>
                        <td>10</td>
                        <td>15</td>
                        <td>10</td>
                        <td>90</td>
                        <td>10</td>
                        <td>5</td>
                        <td>10</td>
                      </tr>
                      <tr>
                        <td width="150"><span class="legend-value"><span class="legend" style="background-color:#f79646"></span> Agent Email</span></td>
                        <td>10</td>
                        <td>15</td>
                        <td>10</td>
                        <td>90</td>
                        <td>10</td>
                        <td>5</td>
                        <td>10</td>
                      </tr>
                      <tr>
                        <td width="150">
                            <span class="legend-value"><span class="legend" style="background-color:#9bbb59"></span>Resolved</span>
                        </td>
                        <td>10</td>
                        <td>15</td>
                        <td>10</td>
                        <td>90</td>
                        <td>10</td>
                        <td>5</td>
                        <td>10</td>
                      </tr>
                      <tr>
                        <td width="150">
                            <span class="legend-value">
                            <span class="legend" style="background-color:#c8a6f2"></span>Agent Response
                            </span>
                        </td>
                        <td>10</td>
                        <td>15</td>
                        <td>10</td>
                        <td>90</td>
                        <td>10</td>
                        <td>5</td>
                        <td>10</td>
                      </tr>
                  </tbody>
                </table>
                </div>
              </div>
          </div>          
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="container-place-holder">
              <div class="box">
                <div class="box-header with-border">
                    <i class="fa fa-support"></i>
                    <h3 class="box-title head-title-padding">Get Support</h3>
                </div>
                <div class="box-body">
                    <div class="support-container">
                      <div class="supportimg-container"><a href="https://www.iservice.info/" target="_blank"><img src="../assets/images/user-guide.png"></a></div>
                      <div class="support-text"><a href="https://www.iservice.info/" target="_blank">Read The User Guides</a></div>
                    </div>
                    <div class="support-container">
                      <div class="supportimg-container"><a href="https://www.iservice.info/training.html" target="_blank"><img src="../assets/images/training.png"></a></div>
                      <div class="support-text"><a href="https://www.iservice.info/training.html" target="_blank">Watch Training Videos</a></div>
                    </div>
                    <div class="support-container">
                      <div class="supportimg-container"><a href="/f/submit-bug"><img src="../assets/images/bug-report.png"></a></div>
                      <div class="support-text"><a href="/f/submit-bug">Submit A Bug Report </a></div>
                    </div>
                    <div class="support-container">
                      <div class="supportimg-container"><a href="/f/submit-enhancement"><img src="../assets/images/enhancement-request.png"></a></div>
                      <div class="support-text"><a href="/f/submit-enhancement">Submit An Enhancement Request</a></div>
                    </div>
                </div>
              </div>
          </div>
        </div>
     </div>
  </section>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>
<script>
import Multiselect from '@vueform/multiselect'
import Breadcrumb from '../components/Breadcrumb.vue';
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';

import ViewModeContent from '../components/ViewModeContent.vue';
import CreateNoteContent from '../components/CreateNoteContent.vue';
import AgentEmailContent from '../components/AgentEmailContent.vue';
import CreateTicketContent from '../components/CreateTicketContent.vue';
import ForwardContent from '../components/ForwardContent.vue';
import GetThisContent from '../components/GetThisContent.vue';

import CaseProperty from '../components/CaseProperty.vue';
import StatusButton from '../components/StatusButton.vue';

import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'


var dataPack1 = [40, 47, 44, 38];
var dataPack2 = [10, 12, 7, 5, 4, 6, 8];
var dataPack3 = [17, 11, 22, 18, 12, 7, 5];
var dataPack4 = [40, 47, 44, 38];
var dataPack5 = [10, 12, 7, 5, 4, 6, 8];


var dates = ["06/13/21", "06/14/21", "06/15/21", "06/16/21", "06/17/21", "06/18/21", "06/19/21"];


export default {
  name: "Dashboard",
  data() {
    return {
        value: null,
        options: [
          'Batman',
          'Robin',
          'Joker',
        ],
        showBCC : true,
        date: new Date(),
        timezone: '',
        showexpanded1: false,
        viewmodecontent : true,
        createnotecontent : false,
        agentemailcontent : false,
        createticketcontent : false,
        forwardcontent  : false,
        getthiscontent : false,
        replyType : this.replyType,
        showGetThis : this.showGetThis,
        statusbtntext:this.statusBtnText, 
        statusbtnclass:this.statusBtnClass, 
        showstatusbtnicon:this.showstatusBtnIcon,
        statusbtniconclass:this.statusBtnIconClass, 
        typebtntext:this.typeBtnText, 
        typebtnclass:this.typeBtnClass,
        showtypebtnicon:this.showtypeBtnIcon, 
        typebtniconclass:this.typeBtnIconClass,
        typebtniconclassgroup: this.typeBtnIconClassGroup,
        typegroupicon : this.typeGroupIcon,
        showexpanded2 : false,
        showexpanded3 : false
      }
    },
 components: {
    Breadcrumb,
    Multiselect,
    InteractionPropertyTab,
    DetailsandattachmentsTabContent,
    ViewModeContent,
    CreateNoteContent,
    AgentEmailContent,
    CreateTicketContent,
    ForwardContent,
    GetThisContent,
    CaseProperty,
    StatusButton,
    Vue3ChartJs
  },
  mounted(){ 
        this.emitter.on("show-view-details", Data => {
          this[Data.displaySegment] = true;
          this.viewmodecontent = false;
        });
        this.emitter.on("hide-view-details", Data => {
          this[Data.displaySegment] = false;
          this.viewmodecontent = true;
        });
  },
    methods: {
        toggleSegmentCollapsedState(i) {
        this['segment'+i] = !this['segment'+i] 
        }
    },
  setup() {
    const barChart = {
      type: "bar",
      options: {
        min: 0,
        max: 100,
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
        },
        scales: {
         x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        },
      },
      data: {
        labels: ["06/13/2020", "06/14/2020", "06/15/2020", "06/16/2020","06/17/2020", "06/18/2020", "06/19/2020"],
        datasets: [
          {
            label: "Private Note",
            data: [4, 10, 5, 6,4, 10, 5],
            backgroundColor: "#2672EC",
            hoverBackgroundColor: "#2672EC",
            hoverBorderWidth: 0,
          },
          {
            label: "Public Note",
            data: [8, 15, 9, 10,10, 5, 6],
            backgroundColor: "#4BACC6",
            hoverBackgroundColor: "#4BACC6",
            hoverBorderWidth: 0,
          },
          {
            label: "Agent Email",
            data: [6, 8, 4, 12,15, 9, 10],
            backgroundColor: "#F79646",
            hoverBackgroundColor: "#F79646",
            hoverBorderWidth: 0,
          },
           {
            label: "Resolved",
            data: [6, 11, 7, 9,6, 8, 4],
            backgroundColor: "#9BBB59",
            hoverBackgroundColor: "#9BBB59",
            hoverBorderWidth: 0,
          },
           {
            label: "Agent Response",
            data: [9, 4, 6, 8, 11, 7, 9],
            backgroundColor: "#C8A6F2",
            hoverBackgroundColor: "#C8A6F2",
            hoverBorderWidth: 0,
          },
        ],
      },
    };
    return {
      barChart,
    };
  },

  }

</script>

