<template>
  <div class="expandedrowcontent box-bg-color" v-if="showDetails">
    <div class="box box-info with-border">
      <div class="box-body table-responsive">
         <div class="nav-tabs-custom color-tab-icon">
          <ul class="nav nav-tabs">
              <li class="nav-item">
                <a href="#tab_messagedetail" class="nav-link active" id="home" data-bs-toggle="tab" data-bs-target="#home-tab" type="button" role="tab" aria-controls="home" aria-selected="true">
                  <i class="fa fa-navicon orange"></i>
                  <span>Message Details</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="#tab_history" class="nav-link" id="history" data-bs-toggle="tab" data-bs-target="#history-tab" type="button" role="tab" aria-controls="history" aria-selected="false" data-toggle="tab">
                  <i class="fa fa-history green"></i>
                  <span>History</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="#tab_contactdetail" class="nav-link" id="contact" data-bs-toggle="tab" data-bs-target="#contact-tab" type="button" role="tab" aria-controls="home" aria-selected="false" data-toggle="tab">
                  <i class="fa fa-ellipsis-h blue"></i>
                  <span>Contact Details</span>
                </a>
              </li>
            </ul>
            <!--Tab content start-->
              <div class="tab-content box-bg-color" >
                <!-- Details tab content -->
                  <div class="tab-pane active" id="home-tab">
                    <!--simple messages end-->
                      <div class="row" v-if="message">
                        <div class="col-md-12 form-action">

                          <ActionModeButtons></ActionModeButtons>

                           <div class="row">
                              <div class="col-md-12">
                                  <EmailAddressSubjectResponse tabOption="3"></EmailAddressSubjectResponse>
                              </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-12" > 
                                   <DraftBodyTabs></DraftBodyTabs>
                                </div>
                          </div>
                          <div class="row">
                               <div class="col-md-12">
                                 <div class="panel panel-default form-group">
                                      <div class="panel-heading">
                                      <i class="fa fa-fw fa-pencil-square-o"></i>
                                          Private Comments
                                      </div>
                                      <div class="panel-body">
                                          <textarea class="full-width full-height"></textarea>
                                      </div>
                                  </div>	
                               </div>
                          </div>



                        </div>
                      </div>  
                    <!--simple message end-->
                    <!--quote messages start-->
                      <div class="row" v-if="quote">
                         <div class="col-md-12 form-horizontal">
                          <div class="col-md-12">
                           <QuoteButtons></QuoteButtons>
                          </div>
                           <QuoteResponseBody></QuoteResponseBody>
                           <QuoteQuestionDetailsTab></QuoteQuestionDetailsTab>
                         </div>
                      </div>
                    <!--quote message end-->
                  </div>
                <!--Details tab content-->

                <!--History tab content-->
                  <div class="tab-pane" id="history-tab">
                    <HistoryTabContent></HistoryTabContent>
                  </div>
                <!--History tab content-->

                <!--Contact information-->
                  <div class="tab-pane" id="contact-tab">
                     <ContactInformation></ContactInformation>
                  </div>
                <!--Contact information-->
              </div>
            <!--Tab content end-->
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionModeButtons from '../components/ActionModeButtons.vue';
import EmailAddressSubjectResponse from '../components/EmailAddressSubjectResponse.vue';
import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';

import QuoteButtons from '../components/QuoteButtons.vue';
import QuoteResponseBody from '../components/QuoteResponseBody.vue';
import QuoteQuestionDetailsTab from '../components/QuoteQuestionDetailsTab.vue';
import ContactInformation from '../components/ContactInformation.vue';

import DraftBodyTabs from '../components/DraftBodyTabs.vue';

import HistoryTabContent from '../components/HistoryTabContent.vue';



export default {
  name: 'InboxMessageDetailComponents',
   components: {
      ActionModeButtons,
      EmailAddressSubjectResponse,
      VariableAndStockResponses,
      QuoteButtons,
      QuoteResponseBody,
      QuoteQuestionDetailsTab,
      ContactInformation,
      DraftBodyTabs,
      HistoryTabContent
  },
  data: function(){
      return{
       quote:true,
       message:false,
       showDetails :false
      }
  },
  mounted() { 
    this.emitter.on("show-message-details", Data => {
      this.showDetails = Data.showDetails;
      if(Data.displayType != 'quote'){
          this.quote = false;
          this.message = true;
      }else{
          this.quote = true;
          this.message = false;
      }
    });
  }
 
}
</script>