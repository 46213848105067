<template>
    <label class="control-label text-left f16 mbottom-5">Question</label>
    <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active" id="draftbody-tab" data-bs-toggle="tab" data-bs-target="#draftbody" type="button" role="tab" aria-controls="draftbody" aria-selected="true">Draft Body</a></li>
            <li class="nav-item"><a class="nav-link" id="preview-tab" data-bs-toggle="tab" data-bs-target="#plainhtml" type="button" role="tab" aria-controls="preview" aria-selected="true">Preview<br></a></li>
        
            <div class="btn-group pull-right variable-btn" >
                <VariableAndStockResponses></VariableAndStockResponses>
                
            </div>
        
        </ul>
        <div class="tab-content">
            <div class="tab-pane active" id="draftbody">
                <div class="form-group text-editor-container">
                    <textarea class="form-control" rows="10" placeholder="" id="editorgetthis" ckeditor ><p>Hello,</p>

                        <p>I&#39;m looking for s small TV for our bedroom, but don&#39;t want to spend more than $100. Do you have any option for me to consider?</p>
                        
                        <p>Regards,</p>
                        
                        <p>John Doe</p>
                    </textarea>
                </div>

            </div>
            <div class="tab-pane" id="plainhtml">
                <p>Hello,</p>

                <p>I&#39;m looking for s small TV for our bedroom, but don&#39;t want to spend more than $100. Do you have any option for me to consider?</p>
                
                <p>Regards,</p>
                
                <p>John Doe</p>
            </div>										   
        </div>
    </div>
    <label class="control-label text-left f16 mbottom-5">Answer</label>
    <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
            <li class="nav-item"><a class="nav-link active" id="draftbody-tab" data-bs-toggle="tab" data-bs-target="#draftbody" type="button" role="tab" aria-controls="draftbody" aria-selected="true">Draft Body</a></li>
            <li class="nav-item"><a class="nav-link" id="preview-tab" data-bs-toggle="tab" data-bs-target="#plainhtml" type="button" role="tab" aria-controls="preview" aria-selected="true">Preview<br></a></li>
        
            <div class="btn-group pull-right variable-btn" >
                <VariableAndStockResponses></VariableAndStockResponses>
              
            </div>
        
        </ul>
        <div class="tab-content">
            <div class="tab-pane active" id="draftbody">
                <div class="form-group text-editor-container">
                    <textarea class="form-control" rows="10" placeholder="" id="editorgetthis" ckeditor ><p>Hello,</p>

                        <p>To use Customer Portal, you'll first need to register using Sign Up link.</p>
                    </textarea>
                </div>

            </div>
            <div class="tab-pane" id="plainhtml">
                <p>To use Customer Portal, you'll first need to register using Sign Up link.</p>
            </div>										   
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <button type="submit" class="btn btn-primary">Create</button>
            <!--this will be button while development <button class="btn btn-link" type="button">Cancel </button>-->
            <a class="btn btn-link" href="/pages/manage-kb-article.html">Cancel </a>
        </div>
    </div>
</template>

<script>
import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';

export default {
  name: 'New Kb Article Question Answer',
   components: {
      VariableAndStockResponses,
  },
  data: function(){
      return{
       quote:true,
       message:false,
       showDetails :false
      }
  },
  mounted() { 
   
  }
 
}
</script>