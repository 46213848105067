<template>
<div class="notdatatable form-inline mbottom-15">
   <div class="table-controls table-columns">
      <div class="btn-group"><button class="btn btn-default" type="button"><span>Excel</span></button><button class="btn btn-default button-columns" type="button"><span>Column visibility</span></button></div>
   </div>
   <div class="table-controls table-pagesize table-columns">
      <label>
         Show 
         <select class="form-control input-xs">
            <option>5</option>
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>250</option>
            <option>500</option>
            <option>1000</option>
         </select>
         entries 
      </label>
   </div>
   <div class="table-controls table-filter">Filter Articles <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
</div>
<table class="table table-striped">
   <thead>
      <tr role="row">
        <th class="no-sort sorting_asc" name="name" tabindex="0" aria-controls="historyTabCaseView" rowspan="1" colspan="1"></th>
        <th class="sorting">
            Subject
        </th>
        <th class="sorting">
            Access Level
        </th>
         <th class="sorting">
            Ratings
        </th>
         <th class="sorting">
           Views
        </th>
        <th class="sorting">
           Topic
        </th>
       
        <th>
            &nbsp;
        </th>
      
      </tr>
   </thead>
    
     <tbody>
     <tr role="row">
        <td name="name" class="col-icon-check sorting_1">
           
            <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded1 = !showexpanded1">
                <i class="fa fa-plus-circle" v-if="!showexpanded1" v-show="!showexpanded1"></i>
                <i class="fa fa-minus-circle" v-if="showexpanded1" v-show="showexpanded1"></i>
            </button>
            <button class="btn btn-xs btn-link text-default" v-on:click="editrow1 = !editrow1" v-show="!editrow1">
                <i class="fa fa-pencil"></i>
            </button>
        </td>
        <td>
             <span v-show="!editrow1">
              _Getting started with the customer portal
            </span>
            <input type="text" class="form-control inline-form-control" value="_Getting started with the customer portal" v-show="editrow1">
        </td>
         <td>
            <a href="" v-show="!editrow1">Public</a>
            <select class="form-control" v-show="editrow1">
              <option>Public</option>
            </select>
        </td>
        <td class="column-date">
           <p>4.0</p>
        </td>
        <td class="column-date">
           <p>11</p>
        </td>
        <td>
          <span v-show="!editrow1"> All Topic</span>
           <select class="form-control" v-show="editrow1">
              <option>All Topic</option>
            </select>
        </td>
        <td>
            <button class="btn button-delete" v-show="!editrow1">
                    <i class="fa fa-times-circle"></i>
            </button>
            <button class="btn btn-xs btn-primary" v-show="editrow1">
                    Save
            </button>
            <button class="btn btn-xs btn-link" v-show="editrow1" v-on:click="editrow1 = !editrow1">
                    Cancel
            </button>
        </td>
        
      </tr>
        <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded1">
            <td class="white-bg">&nbsp;</td>
            <td colspan="6">
                <KbArticleDetail></KbArticleDetail>
            </td>
        </tr>
        <tr role="row">
                <td name="name" class="col-icon-check sorting_1">
                    <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded2 = !showexpanded2">
                        <i class="fa fa-plus-circle" v-if="!showexpanded2" v-show="!showexpanded2"></i>
                        <i class="fa fa-minus-circle" v-if="showexpanded2" v-show="showexpanded2"></i>
                    </button>
                    <button class="btn btn-xs btn-link text-default" v-on:click="editrow2 = !editrow2" v-show="!editrow2">
                        <i class="fa fa-pencil"></i>
                    </button>
                </td>
        <td>
             <span v-show="!editrow2">
               Can't find it
            </span>
            <input type="text" class="form-control inline-form-control" value=" Can't find it" v-show="editrow2">
       
        </td>
         <td>
             <a href="" v-show="!editrow2">Public</a>
            <select class="form-control" v-show="editrow2">
              <option>Public</option>
            </select>
        </td>
        <td class="column-date">
           <p>00.22</p>
        </td>
        <td class="column-date">
           <p>09</p>
        </td>
      
        <td>
            <span v-show="!editrow2"> All Topic</span>
           <select class="form-control" v-show="editrow2">
              <option>All Topic</option>
            </select>
        </td>
        <td>
            <button class="btn button-delete" v-show="!editrow2">
                    <i class="fa fa-times-circle"></i>
            </button>
            <button class="btn btn-xs btn-primary" v-show="editrow2">
                    Save
            </button>
            <button class="btn btn-xs btn-link" v-show="editrow2" v-on:click="editrow2 = !editrow2">
                    Cancel
            </button>
        </td>
               
        </tr>
        <tr class="expandedrowcontent  bg-customer-reply" v-bind:class="replyType" v-if="showexpanded2">
            <td class="white-bg">&nbsp;</td>
             <td colspan="6">
                <KbArticleDetail></KbArticleDetail>
            </td>
        </tr>
        <tr role="row">
                <td name="name" class="col-icon-check sorting_1">
                   
                    <button class="btn btn-xs btn-link btn-expand" v-on:click = " showexpanded3 = !showexpanded3">
                        <i class="fa fa-plus-circle" v-if="!showexpanded3" v-show="!showexpanded3"></i>
                        <i class="fa fa-minus-circle" v-if="showexpanded3" v-show="showexpanded3"></i>
                    </button>
                    <button class="btn btn-xs btn-link text-default" v-on:click="editrow3 = !editrow3" v-show="!editrow3">
                        <i class="fa fa-pencil"></i>
                    </button>
                </td>
                <td>
                <span v-show="!editrow3">
                   I need a good small camera
                </span>
                <input type="text" class="form-control inline-form-control" value=" I need a good small camera" v-show="editrow3">
       
                </td>
                <td>
                    <a href="" v-show="!editrow3">Public</a>
                    <select class="form-control" v-show="editrow3">
                    <option>Public</option>
                    </select>
                </td>
                 <td class="column-date">
                    <p>00.22</p>
                    </td>
                    <td class="column-date">
                    <p>05</p>
                </td>
            
                <td>
                     <span v-show="!editrow3"> All Topic</span>
                     <select class="form-control" v-show="editrow3">
                        <option>All Topic</option>
                    </select>
                </td>
              <td>
                <button class="btn button-delete" v-show="!editrow3">
                        <i class="fa fa-times-circle"></i>
                </button>
                <button class="btn btn-xs btn-primary" v-show="editrow3">
                        Save
                </button>
                <button class="btn btn-xs btn-link" v-show="editrow3" v-on:click="editrow3 = !editrow3">
                        Cancel
                </button>
            </td>
        
               
        </tr>
        <tr class="expandedrowcontent  bg-agent-reply" v-bind:class="replyType" v-if="showexpanded3">
            <td class="white-bg">&nbsp;</td>
            <td colspan="6">
                <KbArticleDetail></KbArticleDetail>
            </td> 
        </tr>
   </tbody>
</table>
<div id="Main_MyDashboard_Recent_TableInfo">
   <div class="notdatatable table-info">Showing 1 to 3 of 3 entries</div>
   <div class="dataTables_paginate notdatatable table-pagination text-end">
      <ul class="pagination">
         <li><a href="">Previous</a></li>
         <li class="active"><a href="">1</a></li>

         <li><a href="">Next</a></li>
      </ul>
   </div>
</div>
</template>

<script>

import KbArticleDetail from '../components/KbArticleDetail.vue';


import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Chat List',
      props: {replyType:String,showGetThis: Boolean, statusBtnText:String, statusBtnClass:String, showstatusBtnIcon:Boolean,statusBtnIconClass:String, typeBtnText:String, typeBtnClass:String, showtypeBtnIcon:Boolean, typeBtnIconClass:String,typeBtnIconClassGroup:String,typeGroupIcon:Boolean},
      components: {
        Multiselect,
        KbArticleDetail,
        
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            showexpanded1: false,
            viewmodecontent : true,
            createnotecontent : false,
            agentemailcontent : false,
            createticketcontent : false,
            forwardcontent  : false,
            getthiscontent : false,
            replyType : this.replyType,
            showGetThis : this.showGetThis,
            statusbtntext:this.statusBtnText, 
            statusbtnclass:this.statusBtnClass, 
            showstatusbtnicon:this.showstatusBtnIcon,
            statusbtniconclass:this.statusBtnIconClass, 
            typebtntext:this.typeBtnText, 
            typebtnclass:this.typeBtnClass,
            showtypebtnicon:this.showtypeBtnIcon, 
            typebtniconclass:this.typeBtnIconClass,
            typebtniconclassgroup: this.typeBtnIconClassGroup,
            typegroupicon : this.typeGroupIcon,
            showexpanded2 : false,
            showexpanded3 : false,
            editrow1 : false,
            editrow2 : false,
            editrow3 : false
          }
      },
      mounted(){
           
           this.emitter.on("show-view-details", Data => {
              this[Data.displaySegment] = true;
              this.viewmodecontent = false;
            });
            this.emitter.on("hide-view-details", Data => {
              this[Data.displaySegment] = false;
              this.viewmodecontent = true;
            });
      },
        methods: {
            toggleSegmentCollapsedState(i) {
            this['segment'+i] = !this['segment'+i] 
            }
        }
    }
</script>