<template>
        <!-- START CUSTOM TABS -->
        <div class="row">
          <div class="col-md-12">
            <!-- Custom Tabs -->
            <div class="row">
              <div class="col-md-12">
                <div class="box box-info" id="inbox-list">
                  <div class="box-header with-border">
                    <i class="fa  fa-inbox"></i>
                    <h3 class="box-title head-title-padding">Message List</h3>
                  </div>
                  <!-- /.box-header -->
                  <!-- form start -->
                  
                  <div class="box-body table-responsive">
                    <div class="notdatatable form-inline mbottom-15">
                        <div class="table-controls table-columns">
                          <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                        </div>
                        <div class="table-controls table-pagesize table-columns">
                          <label>
                              Show 
                              <select class="form-control input-xs">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                              </select>
                              entries 
                          </label>
                        </div>
                        
                        <div class="table-controls table-filter">Filter Inbox <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                    </div>
                    <table class="table table-striped table-hover">
                      <thead>
                        <tr id="row1">
                          <!--<th class="no-sort"  name="name" width="30"></th>-->
                          <th class="text-capitalize sorting">
                            From
                          </th>
                          <th class="text-capitalize sorting" v-show="colEmail">
                            Email
                          </th>
                          <th class="text-capitalize sorting">
                            Date
                          </th>
                          <th class="text-capitalize sorting">
                            Subject
                          </th>
                          <th class="text-capitalize sorting">
                            Segment
                          </th>
                          <th class="text-capitalize sorting">
                            Topic
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr id="row2" @click="showMessageDetails('others','row2')" v-bind:class = "active_el == 'row2' ?'row-active':''">
                          <!--	<td name="name" width="30">
                              <button class="btn btn-xs btn-link text-default btn-expand" title="Operations">
                                <i class="fa fa-plus-circle"></i>
                              </button>
                            </td>-->
                          <td>
                            John Doe
                          </td>
                          <td v-show="colEmail">
                            john.doe@example.com
                          </td>
                          <td>
                            1 day ago
                          </td>

                          <td>
                            MSSQLSERVER:Server:17063 by iService Incoming Undeliverable Emails in Tenant Critical Alert
                          </td>
                          <td>
                            QA
                          </td>
                          <td>
                            Feedback
                          </td>
                        </tr>

                        <tr id="row3" v-on:click="showMessageDetails('quote','row3')" v-bind:class = " active_el == 'row3' ? 'row-active' : 'hell'">
                          <!--	<td name="name" width="30">
                              <button class="btn btn-xs btn-link text-default btn-expand" title="Operations">
                                <i class="fa fa-plus-circle"></i>
                              </button>
                            </td>-->
                          <td>
                            Albert Metricman
                          </td>
                          <td  v-show="colEmail">
                            <span class="text-with-label">
                               albert.metricman@example.com
                            </span>
                          </td>
                          <td>
                            2 days ago
                          </td>
                          <td>
                            I need to purchase an LG TV
                          </td>
                          <td>
                            QA
                          </td>
                          <td>
                            Quote
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div id="Main_MyDashboard_Recent_TableInfo">
                        <div class="notdatatable table-info">Showing 1 to 5 of 29 entries</div>
                        <div class="dataTables_paginate notdatatable table-pagination text-end">
                            <ul class="pagination">
                              <li><a href="">Previous</a></li>
                              <li class="active"><a href="">1</a></li>
                              <li class=""><a href="">2</a></li>
                              <li class=""><a href="">3</a></li>
                              <li class="" ><a href="">...</a></li>
                              <li class=""><a href="">6</a></li>
                              <li><a href="">Next</a></li>
                            </ul>
                        </div>
                      </div>
                    <!-- Data loader start
                    <div class="data-loader">
                      <i class="fa fa-spinner fa-spin fa-fw"></i>
                    </div>  -->
                    <!-- Data loader end-->
                  </div>
                </div>
              </div>
              <div class="col-md-4">
              </div>
            </div>
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->
        <!-- END ACCORDION & CAROUSEL-->
</template>

<script>
var previousrowVal;
export default {
  name: 'PendingMessagesComponents',
  data() {
    return {
      showDetails : true,
      value: true,
      active_el : '',
      colEmail : false
    };
  },
  methods: {
    showMessageDetails(type,row) {
      if(previousrowVal == row){
          this.showDetails = !this.showDetails;
          if(!this.showDetails){
            this.active_el = '';
          }else{
            this.active_el = row;  
          }
         
      }else{
         this.showDetails = this.showDetails;
            this.active_el = row;     
      }
      previousrowVal = row;
      this.emitter.emit("show-message-details", {displayType:type, showDetails : this.showDetails});
      
    }
  }
}
</script>