<template>
    <div class="col-md-12 col-12">
       <div class="box box-warning">
          <div class="box-header with-border actions">
            <h3 class="box-title">
                <i class="fa fa-fw fa-reply"></i>
                Chat Dialog
            </h3>
            <div class="pull-right  form-group">
                <ChatButtonsSmall></ChatButtonsSmall>
            </div>
           
          </div>
         <div class="box-body">
            <div class="direct-chat-messages chat-box chat">
                <dl>
                    <dt>Subject</dt>
                    <dd>[ref#: 26197-26197] Need to return a camera</dd>
                    <dt>Question</dt>
                    <dd>Need to return a camera</dd>
                </dl>
                <!-- ngRepeat: line in chat.chatData.chatLines -->
                <!-- Message to the right -->
                <div class="direct-chat-msg right">
                    <div class="direct-chat-info clearfix">
                        <span class="direct-chat-name pull-right">Jonie Hewlett</span>
                        <span class="direct-chat-timestamp pull-left">23 Jan 2:05 pm</span>
                    </div>
                    <!-- /.direct-chat-info -->
                    <div class="user-initials round no-select pull-right" style="background-color: rgb(73, 60, 96);">JH</div>
                    <!-- /.direct-chat-img -->
                    <div class="direct-chat-text">
                        Hello James, how may I help you ?
                    </div>
                    <!-- /.direct-chat-text -->
                </div>
                <!-- /.direct-chat-msg -->
            <!-- Message. Default to the left -->
                <div class="direct-chat-msg">
                    <div class="direct-chat-info clearfix">
                        <span class="direct-chat-name pull-left">James Williams</span>
                        <span class="direct-chat-timestamp pull-right">23 Jan 2:47 pm</span>
                    </div>
                    <!-- /.direct-chat-info -->
                    <div class="user-initials round no-select pull-left" style="background-color: rgb(61, 185, 117);">JW</div>
                    <!-- /.direct-chat-img -->
                    <div class="direct-chat-text">
                        The camera i just received has a cracked lence and i need to return it for replacement.
                    </div>
                    <!-- /.direct-chat-text -->
                </div>
                <!-- /.direct-chat-msg -->
                <!-- Message to the right -->
                <div class="direct-chat-msg right">
                    <div class="direct-chat-info clearfix">
                        <span class="direct-chat-name pull-right">Jonie Hewlett</span>
                        <span class="direct-chat-timestamp pull-left">23 Jan 2:48 pm</span>
                    </div>
                        <!-- /.direct-chat-info -->
                    <div class="user-initials round no-select pull-right" style="background-color: rgb(73, 60, 96);">JH</div>
                        <!-- /.direct-chat-img -->
                    <div class="direct-chat-text">
                        We will provide the pickup for same.
                    </div>
                    <!-- /.direct-chat-text -->
                </div>
                <!-- /.direct-chat-msg -->
            </div>
        </div>
       </div>
    </div>
</template>

<script>

import ChatButtonsSmall from '../components/ChatButtonsSmall.vue';



import Multiselect from '@vueform/multiselect'

    export default {
      name: 'Chat Window',
      components: {
        Multiselect,
        ChatButtonsSmall,
     
      },
      mounted(){
          
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
            showexpanded1: false
          }
      },
      methods: {
          cancel(segment){
              this.emitter.emit("hide-view-details",{displaySegment:segment});
            }
      }
    }
</script>