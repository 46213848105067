<template>
   <Breadcrumb page-title="Server Monitor" page-section="Server Monitor"> </Breadcrumb>
   <section class="content">
     <div class="nav-tabs-custom">
         <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
               <a class="nav-link active" v-bind:id="'tab11'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_11_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa  fa-tasks"></i> <span>Agent Inactivity</span></a>
            </li>
            <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab21'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_21_'+tabOption" type="button" role="tab"><i class="fa fa-trash-o"></i> <span>Interaction Purge</span></a>
            </li>
            <li class="nav-item" role="presentation">
               <a class="nav-link" v-bind:id="'tab21'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_31_'+tabOption" type="button" role="tab"><i class="fa fa-clock-o"></i> <span>SMTP Out Monitor</span></a>
            </li>
         </ul>
         <div class="tab-content" id="myTabContent">
            <div class="tab-pane show active" v-bind:id="'tab_11_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
                <div class="row">
                    <div class="col-md-12">
                          <table class="table table-striped">    
                            <thead>
                            <tr>
                                <th class="col-md-1">
                                    <label class="control-label text-left">
                                        Status
                                    </label>
                                </th>
                                <th class="col-md-4">
                                    <label class="control-label text-left">
                                        Tenant
                                    </label>
                                </th>
                                <th class="col-md-3">
                                    <label class="control-label text-left">
                                        Last Process Time
                                    </label>
                                </th>
                                <th class="col-md-4">
                                        Message
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr id="editrow1">
                               <td>
                                 <Toggle v-model="tenantactivity1" />
                               </td>
                                <td class="text-capitalize">
                                  <strong>  qamain.qamainclean</strong> 
                                </td>
                                <td class="text-capitalize">               
                                    2022-03-29 04:51:41 PM (1 hour, 16 minutes, 47 seconds ago)
                                </td>
                                <td class="no-sort row-edit" name="name">
                                   Completed: Logged Out 0, Pinged 0
                                </td>
                                </tr>
                                <tr id="editrow2">
                                    <td>
                                        <Toggle v-model="tenantactivity2" />
                                    </td>
                                    <td class="text-capitalize">
                                      <strong>   qamain.qamaintest</strong> 
                                    </td>
                                    <td class="text-capitalize">
                                       2022-03-29 04:51:41 PM (1 hour, 16 minutes, 47 seconds ago)
                                    </td>
                                    <td class="no-sort row-edit" name="name">
                                        Completed: Logged Out 0, Pinged 0	
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>    
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" v-bind:id="'tab_21_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
               <div>
                    <table class="table table-striped">    
                        <thead>
                        <tr>
                            <th class="col-md-1">
                                <label class="control-label text-left">
                                    Status
                                </label>
                            </th>
                            <th class="col-md-4">
                                <label class="control-label text-left">
                                    Tenant
                                </label>
                            </th>
                            <th class="col-md-1">
                                <label class="control-label text-left">
                                    Purge > days
                                </label>
                            </th>
                            <th class="col-md-2">
                                <label class="control-label text-left">
                                    Delete Per Minute
                                </label>
                            </th>
                            <th class="col-md-1">
                                <label class="control-label text-left">
                                    Total Deleted Since Start
                                </label>
                            </th>
                            <th class="col-md-2">
                                <label class="control-label text-left">
                                    Last Deleted Time
                                </label>
                            </th>
                                <th class="col-md-1">
                                <label class="control-label text-left">
                                    Configure
                                </label>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr id="editrow1">
                                <td class="text-capitalize">               
                                    <i class="fa fa-circle text-gray"></i> Off
                                </td>
                                <td class="text-capitalize">
                                    <strong>qamain.qamainclean</strong>
                                </td>
                                <td class="text-capitalize" v-show="!editrow1">               
                                    180
                                </td>
                                <td class="text-capitalize" v-show="!editrow1">               
                                    12
                                </td>
                                <td class="text-capitalize" v-show="!editrow1">                
                                    3452
                                </td>
                                <td class="text-capitalize" v-show="!editrow1"> 
                                    <span v-tooltip="{text: '2022-03-29 04:51:41 PM ',theme: {placement: 'bottom', }}"> 
                                       an hour ago
                                    </span>             
                                    

                                </td>
                                <td class="no-sort row-edit" name="name" v-show="!editrow1">
                                    <i class="fa fa-gear" v-on:click="editrow2 = !editrow2" v-show="!editrow2"></i>
                                </td>
                                 <td colspan="5" v-show="editrow1">

                                    <div class="row" v-if="confirm">
                                        <div class="col-md-12">
                                            <p class="text-red">Your settings will delete 143787 total interactions deleted 14 per minutes</p>
                                        </div>
                                    </div> 

                                    <div v-show="editrow1">
                                        Enter # of Days <input type="number"> (180 minimum)
                                        <button class="btn btn-xs btn-primary" v-if="!confirm" v-on:click="confirm = true"> Save </button>
                                        <button class="btn btn-xs btn-danger" v-if="confirm" v-on:click="editrow1 = !editrow1"> Confirm </button>
                                        
                                        <button class="btn btn-xs btn-link"  v-on:click="editrow1 = !editrow1"> Cancel </button>
                                    </div>
                                    
                                </td>
                            </tr>
                            <tr id="editrow2">
                                <td class="text-capitalize">               
                                     <i class="fa fa-circle text-primary"></i> On
                                </td>
                                <td class="text-capitalize">
                                    <strong>qamain.qamainclean</strong>
                                </td>
                                <td class="text-capitalize" v-show="!editrow2">               
                                    180
                                </td>
                                <td class="text-capitalize" v-show="!editrow2">               
                                    12
                                </td>
                                <td class="text-capitalize" v-show="!editrow2">               
                                    3452
                                </td>
                                <td class="text-capitalize" v-show="!editrow2">               
                                     <span v-tooltip="{text: '2022-03-29 04:51:41 PM ',theme: {placement: 'bottom', }}"> 
                                       an hour ago
                                    </span>  
                                </td>
                                
                                <td class="no-sort row-edit" name="name" v-show="!editrow2">
                                    <i class="fa fa-gear" v-on:click="editrow2 = !editrow2" v-show="!editrow2"></i>
                                </td>

                                <td colspan="5" v-show="editrow2">

                                    <div class="row" v-if="confirm">
                                        <div class="col-md-12">
                                            <p class="text-red">Your settings will delete 143787 total interactions deleted 14 per minutes</p>
                                        </div>
                                    </div> 

                                    <div v-show="editrow2">
                                        Enter # of Days <input type="number"> (180 minimum)
                                        <button class="btn btn-xs btn-primary" v-if="!confirm" v-on:click="confirm = true"> Save </button>
                                        <button class="btn btn-xs btn-danger" v-if="confirm" v-on:click="editrow2 = !editrow2"> Confirm </button>
                                        
                                        <button class="btn btn-xs btn-link"  v-on:click="editrow2 = !editrow2"> Cancel </button>
                                    </div>
                                    
                                </td>

                            </tr>
                        </tbody>
                    </table>      
                </div>
            </div>

             <div class="tab-pane fade" v-bind:id="'tab_31_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
                <div class="row" v-if="confirmDelete">
                    <div class="col-md-12" >
                        <div class="box box-danger">
                            <div class="box-header with-border">
                                <i class="fa fa-warning"></i>
                                <h3 class="box-title text-right">Delete Alert</h3>
                            </div>
                            <div class="box-body">
                                <div class="row">
                                    <div class="form-horizontal">
                                        <div class="col-md-12">
                                            <p class="text-danger">WARNING: Deletion is immediate and irrevocable.</p>
                                        </div>
                                    </div>
                                    <div class="form-horizontal">
                                        <div class="col-md-12"><button iservice-id="Confirm" class="btn btn-danger btn-sm">Confirm Deletion</button><button iservice-id="Cancel" class="btn btn-link" v-on:click="confirmDelete=false">Cancel</button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
              <div class="row">
                
                <div class="col-md-12">
                    <!-- Custom Tabs -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="box box-info" id="inbox-list">
                            <div class="box-header with-border">
                                <i class="fa fa-clock-o"></i>
                                <h3 class="box-title head-title-padding">Messages Waiting To Be Sent</h3>
                            </div>
                            <!-- /.box-header --><!-- form start -->
                            <div class="box-body table-responsive">
                                <h4 class="smtp-out-message text-orange"><i class="fa fa-hourglass-1"></i>  SMTP Out processing last done: 7 seconds ago.</h4>
                                <div class="form-group">
                                    <button class="btn btn-sm btn-primary" type="button">
                                    <i class="fa fa-clock-o"></i>
                                    Delay 1 Hour
                                    </button>
                                    <button class="btn btn-sm btn-primary" type="button">
                                    <i class="fa fa-clock-o"></i>
                                    Delay 1 Day
                                    </button>
                                    <button class="btn btn-sm btn-danger btn-delete" type="button" v-on:click="confirmDelete = true">
                                    <i class="fa fa-trash-o"></i>
                                    Remove Permanently 
                                    </button>
                                </div>
                                <div class="notdatatable form-inline mbottom-15">
                                    <div class="table-controls table-columns">
                                        <div class="btn-group"><button ng-if="viewer.CanExport()" class="btn btn-default" type="button" ng-click="viewer.ExportExcel()"><span>Excel</span></button><button class="btn btn-default button-columns" type="button" id="Main_MyDashboard_Recent_TableControls_Visibility"><span>Column visibility</span></button></div>
                                    </div>
                                    <div class="table-controls table-pagesize table-columns">
                                        <label>
                                        Show 
                                        <select class="form-control input-xs">
                                            <option>5</option>
                                            <option>10</option>
                                            <option>25</option>
                                            <option>50</option>
                                            <option>100</option>
                                            <option>250</option>
                                            <option>500</option>
                                            <option>1000</option>
                                        </select>
                                        entries 
                                        </label>
                                    </div>
                                    <div class="table-controls table-filter">Filter Messages <input type="search" class="form-control" id="Main_MyDashboard_Recent_TableControls_Filter"></div>
                                </div>
                                <table class="table table-striped table-hover">
                                    <thead>
                                        <tr id="row1">
                                        <th class=""  name="name" width="30"><input type="checkbox"></th>
                                        <th class="text-capitalize sorting"> First Attempted Mailing </th>
                                        <th class="text-capitalize sorting"> Next Attempt </th>
                                        <th class="text-capitalize sorting"> Mailbox ID </th>
                                        <th class="text-capitalize sorting"> Message ID </th>
                                        <th class="text-capitalize sorting"> Interaction ID </th>
                                        <th class="text-capitalize sorting"> To Address </th>
                                        <th class="text-capitalize sorting"> Subject </th>
                                        <th class="text-capitalize sorting"> #Errors </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr id="row2" class="">
                                           <td><input type="checkbox"></td>
                                           <td name="name" width="30">
                                               3/14/2019 9:38:28 AM
                                            </td>
                                            <td> 3/14/2019 9:39:28 AM</td>
                                            <td > 11111</td>
                                            <td>1030 </td>
                                            <td>	111122</td>
                                            <td> Customer1@1to1service.com </td>
                                            <td> [ref#:4546-4545]This is not sending </td>
                                            <td> 1</td>
                                        </tr>
                                      
                                    </tbody>
                                </table>
                                <div id="Main_MyDashboard_Recent_TableInfo">
                                    <div class="notdatatable table-info">Showing 1 to 5 of 29 entries</div>
                                    <div class="dataTables_paginate notdatatable table-pagination text-end">
                                        <ul class="pagination">
                                        <li><a href="">Previous</a></li>
                                        <li class="active"><a href="">1</a></li>
                                       
                                        <li><a href="">Next</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- Data loader start
                                    <div class="data-loader">
                                    <i class="fa fa-spinner fa-spin fa-fw"></i>
                                    </div>  --><!-- Data loader end-->
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                </div>
                <!-- /.col -->
                </div>
             </div>
         </div>
    </div>

   
   </section>
</template>
<style src="@vueform/toggle/themes/default.css"></style>
<script>
// @ is an alias to /src
import Breadcrumb from '../components/Breadcrumb.vue';
import Toggle from '@vueform/toggle'
export default {
  name: 'Server Monitor',
  components: {
    Breadcrumb,
    Toggle
  },
  data: function(){
      return{
            editrow1 : false,
            editrow2 : false,
            editrow3 : false,
            confirm: false,
            showmore : false,
            tenantpurge1:false,
            tenantpurge2:false,
            tenantactivity1:false,
            tenantactivity2:false,
            confirmDelete:false
         
          
      }
         
  },
   methods: {
    toggleSegmentCollapsedState(i) {
      this['segment'+i] = !this['segment'+i] 
    },
   
  }
}
</script>


