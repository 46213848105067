<template>
<section class="content-header">
    <h1>
        {{pageTitle}} - <span class="text-primary"> {{ name }} </span> 
    </h1>
    <ol class="breadcrumb">
        <li><a href="#"><i class="fa fa-dashboard"></i> Home</a></li>
        <li class="active">{{pageSection}}</li>
        <li class="active"> {{pageTitle}}</li>
    </ol>
</section>
</template>
<script>

export default {
  name: 'BreadcrumbContactComponents',
  props: ['pageTitle','pageSection','name'],

}
</script>
