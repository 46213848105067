<template>
  <Breadcrumb page-title="My Message Inbox" page-section="My Work Queues"> </Breadcrumb>
  <section class="content">
    <MyInboxMessages></MyInboxMessages>
    <InboxMessageDetail></InboxMessageDetail>
  </section>
</template>

<script>
import Breadcrumb from '../components/Breadcrumb.vue';
import MyInboxMessages from '../components/MyInboxMessages.vue';
import InboxMessageDetail from '../components/InboxMessageDetail.vue';
export default {
  name: 'MyMessageInboxComponents',
   components: {
    Breadcrumb,
    MyInboxMessages,
    InboxMessageDetail
  }
}
</script>
