<template>
    <Breadcrumb page-title="Configure Customer Portal" page-section="Customize Portals"> </Breadcrumb>
    <section class="content">
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" v-bind:id="'tab11'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_11_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"> <span>Treeview</span></a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab21'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_21_'+tabOption" type="button" role="tab"><span>Tabs</span></a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab21'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_31_'+tabOption" type="button" role="tab"><span>Message Portal</span></a>
          </li>
      </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane show active" v-bind:id="'tab_11_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
             <CustomerPortalSettingsTv></CustomerPortalSettingsTv>
          </div>
          <div class="tab-pane" v-bind:id="'tab_21_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
            <CustomerPortalSettings></CustomerPortalSettings>
          </div>
           <div class="tab-pane" v-bind:id="'tab_31_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">  
            <MessagePortalSettings></MessagePortalSettings>
          </div>
        
        </div>
      </div>
    </section>
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
 // @ is an alias to /src
 import Breadcrumb from '../components/Breadcrumb.vue';
 import NewAccountInformation from '../components/NewAccountInformation.vue';
 import CustomerPortalSettings from '../components/CustomerPortalSettings.vue';
 import CustomerPortalSettingsTv from '../components/CustomerPortalSettingsTv.vue';
 import SegmentLevelSettings from '../components/SegmentLevelSettings.vue';
 import MessagePortalSettings from '../components/MessagePortalSettings.vue';
 import ChatPanelSettings from '../components/ChatPanelSettings.vue';
 

 
 import DashbordConfig from '../components/DashbordConfig.vue';
 import ColorCodesConfig from '../components/ColorCodesConfig.vue';
 
 import Toggle from '@vueform/toggle'
 export default {
   name: 'New Account',
   components: {
     Breadcrumb,
     NewAccountInformation,
     Toggle,
     CustomerPortalSettings,
     CustomerPortalSettingsTv,
     DashbordConfig,
     ColorCodesConfig,
     SegmentLevelSettings,
     MessagePortalSettings,
     ChatPanelSettings
   },
   data: function(){
       return{
         segment1 : true,
         segment2 : false,
         segment3 : false,
         segment4 : false,
         segment7: true,
         segment8:true,
         segment10:true,
         segment11:true,
         choosedWebsite : false
       }
          
   },
   ready () {
     $(document).ready(function ($) {
     })
   },
    methods: {
     toggleSegmentCollapsedState(i) {
       this['segment'+i] = !this['segment'+i] 
     }
   }
 }
 </script>
 