<template>
    
<div class="nav-tabs-custom">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" v-bind:id="'tab1'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_1_'+tabOption" type="button" role="tab" aria-controls="profile" aria-selected="false"><i class="fa fa-fw fa-ticket"></i> <span>Ticket Details</span></a>
        </li>
     
        <li class="nav-item" role="presentation">
            <a class="nav-link" v-bind:id="'tab3'" data-bs-toggle="tab" v-bind:data-bs-target="'#tab_3_'+tabOption" type="button" role="tab" aria-controls="contact" aria-selected="false" v-tooltip="{text: 'There are property values set for this interactions.',theme: {placement: 'bottom', }}"><i class="fa fa-fw fa-exchange"></i> <span>Interaction Properties</span><span class="new-inside"></span></a>
        </li>
    </ul>
    <div class="tab-content" id="myTabContent">
        <div class="tab-pane show active" v-bind:id="'tab_1_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
            <!--Standard Response start-->
            <div class="row">

                <div class="col-md-8 form-horizontal">
                    <div class="form-group">
                    <div class="col-2">
                    <label class="control-label text-left">Topic</label>
                    </div>
                    <div class="col-10"> 
                         <Multiselect
                                    v-model="value"
                                    placeholder="-Select Topic-"
                                    label="name"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: '_iService',
                                        options:  [
                                            { value: 'All Topic', name: 'All Topic',level:'1'},
                                            { value: 'Feedback', name: 'Feedback',level:'2'},
                                            
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                    </div>
                    </div>
                    <div class="form-group">
                    <label class="col-2 control-label text-left">Customer Email/Login</label>
                    <div class="col-10">
                       <input class="form-control" type="text">
                        
                    </div>
                    </div>
                
                    <div class="form-group gx-5">
                    <label class="col-2 control-label text-left">Subject</label>
                    <div class="col-10 ">
                        <input class="form-control" type="text">
                    </div>
                    
                    </div>
                
                    <div class="form-group">
                        <label class="col-2 control-label text-left">Assign to Agent</label>
                        <div class="col-10">
                             <Multiselect
                            v-model="value"
                            :closeOnSelect="false"
                            placeholder="--Select Agent--"
                            :options="{
                                agent: 'Jacob Smith (smith@example.com)',
                              
                            }"
                            />
                        </div>
                    </div>
                   
                </div>
                <div class="col-md-4">
                    <iservice-files-upload ng-init="uploads = []" files="uploads"></iservice-files-upload>
                    <div class="form-group">
                        <div class="col-md-12">
                            <label class="drop-box">
                            Drop files here or click to upload
                              <input type="file" class="ng-hide" style="display:none;" multiple="">
                            </label>
                            <div>(Maximum 10 MB upload at one time.)</div>
                            <div class="imageuploadify">
                            <!-- ngRepeat: file in files -->
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
                <!--Standard Response End-->
        </div>
    
        <div class="tab-pane fade" v-bind:id="'tab_3_'+tabOption" role="tabpanel" v-bind:aria-labelledby="tab">
           <InteractionPropertyTab></InteractionPropertyTab>
        </div>

    </div>
</div>


</template>

<script>
import InteractionPropertyTab from '../components/InteractionPropertyTab.vue';
import DetailsandattachmentsTabContent from '../components/DetailsandattachmentsTabContent.vue';

import Multiselect from '@vueform/multiselect'

    export default {
      name: 'EmailAddressSubjectResponseComponents',
      props: ['tabOption'],
      components: {
        Multiselect,
        InteractionPropertyTab,
        DetailsandattachmentsTabContent
      },
      mounted(){
           
      },
      data: function(){
          return{
            showBCC : true,
            date: new Date(),
            timezone: '',
          }
      }
    }
</script>