<template>
<div class="row">
   <div class="col-md-12">
      <div class="box box-info">
         <div class="box-header with-border">
            <h3 class="box-title">Mailing List Subscriptions
             <span  v-tooltip="{text: 'Mailing lists are used to send targeted individual mailings to their subscribers.',theme: {placement: 'bottom', }}">
               <i class="fa fa-question-circle text-info help-icon"></i>
            </span>
            </h3>
         </div>
         <div class="box-body">
            <div class="row">
               <div class="col-md-12">
                  <table class="table table-striped table-hover subscriptions">
                     <thead>
                        <tr>
                           <th class="sorting">Name</th>
                           <th class="sorting">Description</th>
                           <th class="text-center">Member Of 
                              <span  v-tooltip="{text: 'This column indicates whether the contact has a subscription. Unless opted out, the contact will receive mailings for the subscription.',theme: {placement: 'left', }}">
                                 <i class="fa fa-question-circle text-info help-icon"></i>
                              </span>
                           </th>
                           <th class="text-center">Opted Out 
                              <span  v-tooltip="{text: 'This opt-out setting can be updated by the contact using an unsubscribe link or an email preferences page. The contact will not receive any messages for opted-out subscriptions, even if they are still a member of the list.',theme: {placement: 'left', }}">
                                 <i class="fa fa-question-circle text-info help-icon"></i>
                              </span>  
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>25,000</td>
                           <td></td>
                           <td class="text-center">
                              <div class="icheckbox_square-blue checked" aria-checked="false" aria-disabled="false" style="position: relative;"><input type="checkbox" class="form-check-input" style="position: absolute; opacity: 0;"><ins class="iCheck-helper" style="position: absolute; top: 0%; left: 0%; display: block; width: 100%; height: 100%; margin: 0px; padding: 0px; background: rgb(255, 255, 255); border: 0px; opacity: 0;"></ins></div>
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                        <tr>
                           <td>Agent Only</td>
                           <td></td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                        <tr>
                           <td>All Customers</td>
                           <td>List of all customers</td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                        <tr>
                           <td>Customer Only</td>
                           <td>Contacts with name Customer</td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                        <tr>
                           <td>S1 and S2</td>
                           <td>All customers that are members of both S1 and S2 only.</td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                        <tr>
                           <td>S1 Exclusive</td>
                           <td>The only customer solely in S1</td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                        <tr>
                           <td>S1 Only</td>
                           <td>All original customers in S1.</td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                        <tr>
                           <td>S1 or S2</td>
                           <td>Customers that are a member of EITHER S1 or S2.</td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                        <tr>
                           <td>S2 Exclusive</td>
                           <td>The customer that is solely in S2.</td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                        <tr>
                           <td>S2 Only</td>
                           <td>All original customers in S2 only</td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                        <!-- end ngRepeat: item in data.lists -->
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
      <div class="box box-info">
         <div class="box-header with-border">
            <h3 class="box-title">Campaign Subscriptions 
              <span  v-tooltip="{text: 'Campaigns are a series of emails sent to its members on a predefined schedule. They usually contain a series of messages.',theme: {placement: 'bottom', }}">
               <i class="fa fa-question-circle text-info help-icon"></i>
               </span>
            </h3>
         </div>
         <div class="box-body">
            <div class="row">
               <div class="col-md-12">
                  <table class="table table-striped table-hover subscriptions">
                     <thead>
                        <tr>
                           <th class="sorting">Name</th>
                           <th class="sorting">Description</th>
                           <th class="text-center">Member Of</th>
                           <th class="text-center">Opted Out</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>Test Campaign</td>
                           <td>Test campaign with one welcome message.</td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                           <td class="text-center">
                              <input type="checkbox" class="form-check-input" >
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
      <div class="box box-info">
         <div class="box-header with-border">
            <h3 class="box-title">Knowledge Base Article Subscriptions
               <span  v-tooltip="{text: 'If the contact has subscribed to a knowledge base article to receive updates, those subscriptions will be shown here. When the contents of the article are update, its subscribers can be emailed with the details of the change.',theme: {placement: 'bottom', }}">
               <i class="fa fa-question-circle text-info help-icon"></i>
               </span>
            </h3>
         </div>
         <div class="box-body">
            <div class="row">
               <div class="col-md-12">
                  <table class="table table-striped table-hover subscriptions">
                     <thead>
                        <tr>
                           <th class="sorting">Topic</th>
                           <th class="sorting">Subject</th>
                           <th class="text-center">Subscribed</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td colspan="3">None Subscribed</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>

<script>
export default {
  name: 'Contact Summary Tab',
  components: {
     
  },
  ready () {
    $(document).ready(function ($) {
    })
  }
}
</script>