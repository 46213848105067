<template>
 <Breadcrumb page-title="New Contact" page-section="New Contact"> </Breadcrumb>
  <section class="content">
    <ContactInformation></ContactInformation>
  </section>
</template>

<script>
// @ is an alias to /src
import Breadcrumb from '../components/Breadcrumb.vue';
import ContactInformation from '../components/ContactInformation.vue';

export default {
  name: 'Contact',
  components: {
    Breadcrumb,
    ContactInformation
  },
  ready () {
    $(document).ready(function ($) {
    })
  }
}
</script>
